export function handlleRecursionInfo(infoList) {
  var min
  for (var i = 0; i < infoList.length; i++) {
    for (var j = i; j < infoList.length; j++) {
      if (infoList[i].kindId > infoList[j].kindId) {
        min = infoList[j]
        infoList[j] = infoList[i]
        infoList[i] = min
      }
    }
  }

  let menu = []
  let map = {}
  infoList.forEach((m) => {
    m.children = []
    map[m.kindId] = m // {1:菜单,2:菜单}

    if (m.parKindId == 0) {
      menu.push(m) // 如果是根 就直接push到menu中
    } else {
      map[m.parKindId] && map[m.parKindId].children.push(m)
      map[m.parKindId] && caSort(map[m.parKindId].children)
    }

  })
  return menu
}

export function caSort(arr) {
  var max //遍历数组，默认arr中的某一个元素为最大值，进行逐一比较
  for (var i = 0; i < arr.length; i++) {
    //外层循环一次，就拿arr[i] 和 内层循环arr.legend次的 arr[j] 做对比
    for (var j = i; j < arr.length; j++) {
      if (arr[i].kindId < arr[j].kindId) {
        //如果arr[j]大就把此时的值赋值给最大值变量max
        max = arr[j]
        arr[j] = arr[i]
        arr[i] = max
      }
    }
  }

  return arr
}
