import { createStore } from 'vuex'
import shop from './model/shop'
import index from './model/index'
import staff from './model/staff'
import rank from './model/rank'
import customer from './model/customer'
import card from './model/card'
import goods from './model/goods'
import order from './model/order'
import reserve from './model/reserve'
import commissionSettings from './model/commission-settings'
import serve from './model/serve'
import authority from './model/authority'
import login from './model/login'
import  commission  from './model/commission'
import target from './model/target'

export default createStore({
  state: {
    currentUserName: '王海龙'
  },
  mutations: {},
  actions: {},
  modules: {
    shop,
    index,
    staff,
    rank,
    customer,
    card,
    goods,
    order,
    reserve,
    commissionSettings,
    commission,
    serve,
    authority,
    login,
    target
  }
})
