import { request3 } from '@/service/request3'
import { request4 } from '@/service/request4'
import qs from 'qs'
import cache from "@/utils/cache"

export function getReserveList(obj) {
  return request4({
    url: '/appointment/web/getWebAppList',
    method: 'post',
    data: obj
  })
}

export function getShopRoomList(shopId) {
  return request3({
    url: `/appointment/get-rooms/${shopId}`,
    method: 'post'
  })
}

export function getTeacherList(companyId) {
  return request3({
    url: `/appointment/get-liliao-list/${companyId}`,
    method: 'post'
  })
}

export function setToShop(obj) {
  return request3({
    url: `/appointment/confirm-app`,
    method: 'post',
    data: obj
  })
}

export function setReserveRemark(obj) {
  return request3({
    url: `/appointment/new-order-memo`,
    method: 'post',
    data: obj
  })
}

export function getReserveRemarkList(orderNo) {
  return request3({
    url: `/appointment/order-memos/${orderNo}`,
    method: 'post'
  })
}

export function removeReserveRemark(id, orderNo) {
  return request3({
    url: `/appointment/del-order-memo/${id}/${orderNo}`,
    method: 'post'
  })
}

export function addCustomerInfo(obj) {
  return request3({
    url: `/appointment/new-customer-adds`,
    method: 'post',
    data: obj
  })
}

export function getCustomerInfoList(userId) {
  return request3({
    url: `/appointment/get-adds/${userId}`,
    method: 'post'
  })
}

export function removeCustomerInfo(id, orderNo) {
  return request3({
    url: `/appointment/del-adds/${id}/${orderNo}`,
    method: 'post'
  })
}

export function cancelReserve(obj) {
  return request3({
    url: `/appointment/cancel-app`,
    method: 'post',
    data: obj
  })
}

export function getStaffList(obj) {
  return request3({
    url: `/permission/admin-users`,
    method: 'post',
    data: obj
  })
}

export function getShopList(companyId) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/permission/shop-basic/${companyId}/${cashUid}`,
    method: 'post'
  })
}
export function getBingOtherRule(ruleId) {
  return request4({
    url: `/manager/permission/getBingOtherRule?ruleId=${ruleId}`,
    method: 'get'
  })
}

export function getRuleBindShop(id) {
  return request4({
    url: `/manager/admin/getRuleBindShop?ruleId=${id}`,
    method: 'get'
  })
}
//获取门店经营时间
export function getShopTime(id) {
  return request4({
    url: `/manager/admin/getShopTimeList?id=${id}`,
    method: 'get'
  })
}

export function getShopList2(companyId) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/order/shop-basic/${companyId}/${cashUid}`,
    method: 'post'
  })
}

export function getShopTeacherList(shopId) {
  return request4({
    url: `/manager/admin/get-simple-emp-list/${shopId}`,
    method: 'post'
  })
}

export function getTeacherServeList(userId) {
  return request4({
    url: `/manager/appointment/simple-emp-serve/${userId}`,
    method: 'post'
  })
}
export function getTeacherRankServeList(data) {
  return request4({
    url: `/serve/web/serveKindDropDown`,
    method: 'post',
    data
  })
}

export function getClientInfo(userMobile) {
  return request4({
    url: `/manager/appointment/is-user-exist/${userMobile}`,
    method: 'post'
  })
}

export function addReserve(obj) {
  return request4({
    url: `/manager/appointment/new-serve-app`,
    method: 'post',
    data: obj
  })
}

export function getTeacheriSAvaiable(obj) {
  return request4({
    url: `/manager/appointment/get-emp-time-avaiable`,
    method: 'post',
    data: obj
  })
}

export function getTeacherReserveList2(empId, arriveDate) {
  return request4({
    url: `/manager/appointment/get-emp-apps/${empId}/${arriveDate}`,
    method: 'post'
  })
}

export function getTeacherReserveList(empId, arriveDate) {
  return request4({
    url: `/manager/appointment/new-emp-apps/${empId}/${arriveDate}`,
    method: 'post'
  })
}

export function getRoomReserveList(shopId, roomId, arriveDate) {
  return request4({
    url: `/manager/appointment/get-room-apps/${shopId}/${roomId}/${arriveDate}`,
    method: 'post'
  })
}

export function getReservePaySourceList() {
  return request4({
    url: `/manager/admin/get-source`,
    method: 'post'
  })
}

export function setTeacherTimeOccupy(occupancyNo) {
  return request4({
    url: `/manager/appointment/cleanOccupation?occupancyNo=${occupancyNo}`,
    method: 'get',
  })
}

export function registerUser(obj) {
  return request4({
    url: `/manager/customer/new-back-customer`,
    method: 'post',
    data: obj
  })
}

export function addReserveRule(obj) {
  return request4({
    url: `/manager/appointment/new-app-rule`,
    method: 'post',
    data: obj
  })
}

export function getReserveRuleList(companyId) {
  return request4({
    url: `/manager/appointment/app-rule/${companyId}`,
    method: 'post'
  })
}

export function updateReserveRule(obj) {
  return request4({
    url: `/manager/appointment/update-app-rule`,
    method: 'post',
    data: obj
  })
}

export function removeReserveRule(companyId, id) {
  return request4({
    url: `/manager/appointment/del-app-rule/${companyId}/${id}`,
    method: 'post'
  })
}

export function searchKeywordCustomer(obj) {
  return request4({
    url: `/manager/appointment/search-customer`,
    method: 'post',
    data: obj
  })
}

export function getShopServeList(shopId) {
  return request4({
    url: `/manager/admin/serves-from-job/${shopId}`,
    method: 'post'
  })
}

// export function isSchedule(obj) {
//   return request4({
//     url: `/manager/appointment/has-planed`,
//     method: 'post',
//     data: obj
//   })
// }

export function getTeacherOccupyTimeList(empId, appDate) {
  return request4({
    url: `/manager/appointment/get-ocupy-list/${empId}/${appDate}`,
    method: 'post'
  })
}

export function getStaffPaiBanList(empId, appDate) {
  return request4({
    url: `/manager/appointment/get-emptime-list/${empId}/${appDate}`,
    method: 'post'
  })
}

export function getServeCategoryList(companyId) {
  return request4({
    url: `/staff/serve-cata/${companyId}`,
    method: 'post'
  })
}

export function setReserveSuccess(obj) {
  return request4({
    url: `/manager/order/finish-app-relate`,
    method: 'post',
    data: obj
  })
}

export function setReserveSiteDefault(ruleId) {
  return request4({
    url: `/manager/appointment/set-default-rule/${ruleId}`,
    method: 'post'
  })
}

export function setReserveSiteBindShop(ruleId, shopId) {
  return request4({
    url: `/manager/appointment/deal_shop_apprule/${ruleId}/${shopId}`,
    method: 'post'
  })
}

export function getReserveSiteBindShopList(ruleId) {
  return request4({
    url: `/manager/appointment/get-apprule-shops/${ruleId}`,
    method: 'post'
  })
}

export function globalSetResercvePrice(companyId, payflag) {
  return request4({
    url: `/manager/admin/update-serve-prepay-status/${companyId}/${payflag}`,
    method: 'post'
  })
}

export function getOrderPayType(orderNo) {
  return request4({
    url: `/manager/money/order-pay-way/${orderNo}`,
    method: 'post'
  })
}

export function setOrderPayType(obj) {
  return request4({
    url: `/manager/money/new-yeji-item`,
    method: 'post',
    data: obj
  })
}

export function updateReserveTeacher(oempId, empId, orderNo) {
  return request4({
    url: `/manager/appointment/change-emp/${oempId}/${empId}/${orderNo}`,
    method: 'post'
  })
}

export function updateReserveProject(obj) {
  return request4({
    url: `/manager/schedule/renew-app`,
    method: 'post',
    data: obj
  })
}

export function newReserve(obj) {
  return request4({
    url: `/manager/appointment/back-new-app`,
    method: 'post',
    data: obj
  })
}

export function getReserveShopList(shopId) {
  return request4({
    url: `/manager/admin/get-appboard-emp-list/${shopId}`,
    cache: true,
    method: 'post'
  })
}

// export function getReserveShopList(shopId) {
//   return request4({
//     url: `/manager/admin/get-app-emp-list/${shopId}`,
//     method: 'post'
//   })
// }

export function updateReserveRoomBed(obj) {
  return request4({
    url: `/manager/order/change-room-bed`,
    method: 'post',
    data: obj
  })
}

export function reserveLastSet(obj) {
  return request4({
    url: `/manager/appointment/extra-emp-time`,
    method: 'post',
    data: obj
  })
}

export function cancelReserveApp(obj) {
  return request4({
    url: `/manager/appointment/new-cancel-app`,
    method: 'post',
    data: obj
  })
}

export function refund(obj) {
  return request4({
    url: `/manager/pay/refund`,
    method: 'post',
    data: obj
  })
}

export function cancelOrder(obj) {
  return request4({
    url: `/manager/order/cancel-order`,
    method: 'post',
    data: obj
  })
}

export function updateReserveDate(obj) {
  return request4({
    url: `/manager/appointment/update-app-date-and-time`,
    method: 'post',
    data: obj
  })
}

// export function updateReserve(obj) {
//   return request4({
//     url: `/manager/appointment/app-board-renew-app`,
//     method: 'post',
//     data: obj
//   })
// }
export function updateReserve(obj) {
  return request4({
    url: `/appointment/web/editAppointment`,
    method: 'PUT',
    data: obj
  })
}

export function removeTeacherTime(empId, orderNo) {
  return request4({
    url: `/manager/appointment/reload-emp/${empId}/${orderNo}`,
    method: 'post'
  })
}

export function getHasTimeServeTeacherList(obj) {
  return request4({
    url: `/manager/appointment/get-emps-from-serves`,
    method: 'post',
    data: obj
  })
}

export function newSetOrderNo(obj) {
  return request4({
    url: `/manager/order/make-refund-order`,
    method: 'post',
    data: obj
  })
}

export function newRefund(obj) {
  return request4({
    url: `/manager/order/refund`,
    method: 'post',
    data: obj
  })
}

export function newCancleReserve(obj) {
  return request4({
    url: `/manager/order/cancel-order`,
    method: 'post',
    data: obj
  })
}

export function addTicheng(obj) {
  return request4({
    url: `/manager/money/brand-new-sale-ticheng`,
    method: 'post',
    data: obj
  })
}

export function reserveAddTicheng(obj) {
  return request4({
    url: `/manager/money/brand-new-sale-ticheng`,
    method: 'post',
    data: obj
  })
}

export function getBedStatus(obj) {
  return request4({
    url: `/manager/appointment/bed-status/`,
    method: 'post',
    data: obj
  })
}

export function getReserveTimeList(empId, arriveDate) {
  return request4({
    url: `/manager/admin/get-emp-app-times/${empId}/${arriveDate}`,
    method: 'post'
  })
}

export function isNewUser(userId) {
  return request4({
    url: `/manager/card/is-new-user/${userId}`,
    method: 'post'
  })
}
// 预约看板列表
export function reservationBoard(data) {
  return request4({
    url: `/appointment/web/getBulletinBoardList`,
    method: 'post',
    data
  })
}
// 预约看板列表预约数量
export function getBulletinBoardStatusCount(data) {
  return request4({
    url: `/appointment/web/getBulletinBoardStatusCount`,
    method: 'post',
    data
  })
}
// 获取用户七天内的预约看板信息
export function getUserWeekReservation(data) {
  return request4({
    url: `/manager/appointment/getUserWeekReservation`,
    method: 'post',
    data
  })
}
// 获取房间列表
export function getNewRooms(shopId) {
  return request4({
    url: `/manager/appointment/get-rooms/${shopId}`,
    method: 'get'
  })
}

// 确认到店
export function confirmApp(data) {
  return request4({
    url: `/appointment/web/confirmArrival`,
    method: 'PUT',
    data
  })
}


// 开单
export function makeABill(data) {
  return request4({
    url: `/manager/appointment/makeABill`,
    method: 'post',
    data
  })
}

// 开单
export function bedTime(data) {
  return request4({
    url: `/manager/appointment/bedTime`,
    method: 'post',
    data
  })
}
// // 新建预约
// export function newApp(data) {
//   return request4({
//     url: `/manager/appointment/newApp`,
//     method: 'post',
//     data
//   })
// }
// 新建预约
export function newApp(data) {
  return request4({
    url: `/appointment/web/createAppointment`,
    method: 'post',
    data
  })
}
// 取消预约
// export function newCancelorder(data) {
//   return request4({
//     url: `/manager/order/new-cancel-order`,
//     method: 'post',
//     data
//   })
// }
export function newCancelorder(id) {
  return request4({
    url: `/appointment/web/cancelAppointment?id=${id}`,
    method: 'get'
  })
}

// 校验时间
export function newGetTeacheriSAvaiable(data) {
  return request4({
    url: `/manager/appointment/checkTime`,
    method: 'post',
    data
  })
}

// 获取用户可预约时间

export function getUserTimeList(data) {
  return request4({
    url: `/appointment/web/getEmpAppTimeListByDate`,
    method: 'post',
    data
  })
}

export function getBedListByOrder(orderCode) {
  return request4({
    url: `/manager/appointment/getBedListByOrder?orderCode=${orderCode}`,
    method: 'get'
  })
}


export function getServePreStatus() {
  return request4({
    url: `/manager/admin/getServePreStatus`,
    method: 'get'
  })
}
//获取预约详情
export function getAppointment(id) {
  return request4({
    url: `/appointment/web/getAppointment?id=${id}`,
    method: 'get'
  })
}
//根据预约获取可选床位
export function getBedListByApp(id) {
  return request4({
    url: `/manager/appointment/getBedListByApp?id=${id}`,
    method: 'get'
  })
}
//获取预约的支付信息
export function getAppPayInfo(id) {
  return request4({
    url: `/appointment/web/getAppPayInfo?id=${id}`,
    method: 'get'
  })
}
export function getAppPayUserInfo(id,userId) {
  return request4({
    url: `/appointment/web/getAppPayInfo?id=${id}&userId=${userId}`,
    method: 'get'
  })
}
//新建占用
export function isSchedule(obj) {
  return request4({
    url: `/appointment/web/occupation`,
    method: 'post',
    data: obj
  })
}
//取消占用
export function cancelOccupancys(occupancyNo) {
  return request4({
    url: `/appointment/web/cancelOccupancy?occupancyNo=${occupancyNo}`,
    method: 'get',
  })
}
//占用详情
export function getOccupation(occupancyNo) {
  return request4({
    url: `/appointment/web/getOccupation?occupancyNo=${occupancyNo}`,
    method: 'get',
  })
}
//保存抵扣方案
export function saveDeductionMode(obj) {
  return request4({
    url: `/appointment/web/saveDeductionMode`,
    method: 'post',
    data: obj
  })
}
//拉起收银台
export function pullCashier(id) {
  return request4({
    url: `/order/web/pullCashier?id=${id}`,
    method: 'get'
  })
}
//获取目标客户列表
export function getTargetCustomersList(obj) {
  return request4({
    url: `/appointment/web/getTargetCustomersList`,
    method: 'post',
    data: obj
  })
}
//获取目标客户统计
export function getTargetCustomersStatistics(obj) {
  return request4({
    url: `/appointment/web/getTargetCustomersStatistics`,
    method: 'post',
    data: obj
  })
}
//设置销售计划
export function setAPlan(obj) {
  return request4({
    url: `/appointment/web/setAPlan`,
    method: 'post',
    data: obj
  })
}
//复盘
export function replayContent(obj) {
  return request4({
    url: `/appointment/web/replay`,
    method: 'post',
    data: obj
  })
}
//刷新目标客户信息
export function refreshTargetCustomer(obj) {
  return request4({
    url: `/appointment/web/refreshTargetCustomer`,
    method: 'post',
    data: obj
  })
}
//获取退款门店列表
export function getOrderReturnShopList(id) {
  return request4({
    url: `/shop/web/getOrderReturnShopList?orderId=${id}`,
    method: 'get'
  })
}