//面包屑导航
export function MapBreadcrumbs(userMenu, currentPath) {
  const breadcrumb = []
  function pathMapToMenu(userMenu, currentPath) {
    for (const menu of userMenu) {
      //找到相匹配的路径
      if (menu.type === 1) {
        if (menu.children) {
          const findMenu = pathMapToMenu(menu.children, currentPath)
          if (findMenu) {
            breadcrumb.push({ name: menu.name, path: menu.url })
            breadcrumb.push({ name: findMenu.name, path: findMenu.url })
            return findMenu
          }
        } else {
          const findMenu = pathMapToMenu([], currentPath)
          if (findMenu) {
            return findMenu
          }
        }
      } else if (menu.type === 2 && menu.url === currentPath) {
        return menu
      }
    }
  }
  return breadcrumb
}

//获取当前菜单路径
export function pathMapToMenu(userMenu, currentPath) {
  for (const menu of userMenu) {
    //找到相匹配的路径
    if (menu.type === 1) {
      if (menu.children) {
        const findMenu = pathMapToMenu(menu.children, currentPath)
        if (findMenu) {
          return findMenu
        }
      } else {
        const findMenu = pathMapToMenu([], currentPath)
        if (findMenu) {
          return findMenu
        }
      }
    } else if (menu.type === 2 && menu.url === currentPath) {
      return menu
    }
  }
}

let firstMenu = null

//菜单路由映射逻辑
export function mapMenusToRoutes(userMenus) {
  const routes = []

  // 1.先去加载默认所有的routes
  const allRoutes = []
  const routeFiles = require.context('../router/main', true, /\.js/)
  routeFiles.keys().forEach((key) => {
    const route = require('../router/main' + key.split('.')[1])
    allRoutes.push(route.default)
  })

  // 2.根据菜单获取需要添加的routes
  // userMenus:
  // type === 1 -> children -> type === 1
  // type === 2 -> url -> route
  const _recurseGetRoute = (menus) => {
    for (const menu of menus) {
      const route = allRoutes.find((route) => route.path === menu.url)
      if (route) routes.push(route)

      if (menu.type === 1) {
        _recurseGetRoute(menu.children)
      }
    }
  }

  _recurseGetRoute(userMenus)
  return routes
  // return allRoutes
}
