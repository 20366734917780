let BASE_URL = ''
const TIME_OUT = 60000
if (process.env.NODE_ENV === 'development') {
  BASE_URL = '/saas-biz-service/manager/admin'
} else if (process.env.NODE_ENV === 'production') {
  //'https://platform.teamcafe.cn/saas-biz-service/manager/admin'
  BASE_URL = 'https://yizhigeapi.yizhige.cn/saas-biz-service/manager/admin'
  // BASE_URL = 'http://47.117.1.209:40018/saas-biz-service/manager/admin'
} else {
  BASE_URL = 'https://yizhigeapi.yizhige.cn/saas-biz-service/manager/admin'
  // BASE_URL = 'http://47.117.1.209:40018/saas-biz-service/manager/admin'
}

export { BASE_URL, TIME_OUT }
