import { tips } from '@/utils/tips'
import emitter from '@/utils/eventbus'
import {
	getOrderList,
	getCardList,
	getRechargeRuleList,
	addOrder,
	saveOrder,
	getCardDetail,
	getUserAccountBalance,
	getServeCategoryList,
	getServeList,
	getGoodsCategoryList,
	getGoodsList,
	getCardHandselList,
	getShopTeacher,
	getUserBalance,
	userBalancePay,
	getPayQrcode,
	setWaitAmount,
	setOrderBack,
	getUserInfo,
	getOrderDetail,
	getUserBuyServeQuanyi,
	getUserCardList,
	getUserDikouList,
	setOrderQuanyiPay,
	setUserOrderBalancePay,
	setUserOrderStoredValueCardPay,
	setKaidanShouDongdk,
	addDaiDIkou,
	updatePayInfo,
	setRecordPaytype,
	xianjinLog,
	setOrderRecordPaytype,
	getOrderRefoundList,
	getCommentList,
	zekouCardLog,
	useCoupon,
	updateXjqPayPrice,
	setOrderFinsh,
	setXiaosouYeji,
	addTicheng
} from '@/service/main/order'
import { messageNotifyBackOrder } from '@/service/main/base'
import { getOrderList2 } from '@/service/main/customer'
import {
	getCardList2,
	getRechargeRuleList2,
	getUserBalance2,
	getShopTeacher2,
	addOrder2,
	saveOrder2,
	userBalancePay2
} from '@/service/main/card'
import dayjs from 'dayjs'
import { COMPANY_ID } from '@/common/constant'
import pay from '@/router/main/order/pay'
import router from '@/router'
import { ElMessage } from 'element-plus'
import { deepClone } from '@/utils/clone'
import cache from '@/utils/cache'

const orderModule = {
	namespaced: true,
	state() {
		return {
			allOrderInfoList: [
				{
					buy_time: '2020-10:12 12:23:01',
					order_num: '1234234546sdf23',
					avatar:
						'https://pic1.zhimg.com/v2-59764b7f551bcfbeae70f9c0cb2e8eaf_s.jpg?source=06d4cd63',
					goods_name: '全身舒缓按摩',
					price: 1234,
					teacher: '刘德华',
					buy_num: 1,
					client: {
						nickname: '王海海',
						phone: 178213231233,
						grade: 23
					},
					balance: 8888,
					buy_shop: '一指阁静安寺店',
					state: 1,
					serve_num: 1
				},
				{
					buy_time: '2020-10:12 12:23:01',
					order_num: '1234234546sdf23',
					avatar:
						'https://pic1.zhimg.com/v2-59764b7f551bcfbeae70f9c0cb2e8eaf_s.jpg?source=06d4cd63',
					goods_name: '全身舒缓按摩',
					price: 1234,
					teacher: '刘德华',
					buy_num: 1,
					client: {
						nickname: '王海海',
						phone: 178213231233,
						grade: 23
					},
					balance: 8888,
					buy_shop: '一指阁静安寺店',
					state: 1,
					serve_num: 1
				}
			],
			orderTitleInfoList: [
				'商品',
				'单价',
				'数量',
				'消耗/销售人员',
				'是否回客',
				'是否首购/续卡',
				'客户',
				'金额',
				'下单门店',
				'状态',
				'操作'
			],
			titleInfoList: [
				'预约日期',
				'销售人员',
				'服务人员',
				'客户属性',
				'账户情况',
				'探寻需求',
				'匹配方案',
				'提供方案',
				'收款成功',
				'复盘',
				'操作'
			],
			titleInfoListTwo: [
				'填写人',
				'主诉',
				'重点部位/肌肉',
				'客户认同',
				'疗效判断',
				'反预约时间',
				'了解用户',
				'备注'
			],
			orderServeInfoList: [
				{
					name: '全身舒缓推拿',
					price: 189
				},
				{
					name: '全身舒缓推拿2',
					price: 1892
				},
				{
					name: '全身舒缓推拿3',
					price: 1893
				},
				{
					name: '全身舒缓推拿4',
					price: 1894
				},
				{
					name: '全身舒缓推拿5',
					price: 1895
				}
			],
			orderConsumeInfoList: [
				{
					name: '深度筋膜放松-罐疗套餐',
					price: 12323,
					teacher: '张学友'
				},
				{
					name: '深度筋膜放松-罐疗套餐2',
					price: 6666,
					teacher: '刘德华'
				}
			],
			orderGoodsInfoList: [
				{
					name: '高级薰衣草香薰',
					price: 1233
				},
				{
					name: '高级薰衣草香薰2',
					price: 546
				},
				{
					name: '高级薰衣草香薰3',
					price: 12233
				},
				{
					name: '高级薰衣草香薰4',
					price: 123
				}
			],
			secondaryCardInfoList: [
				{
					name: '运动康复卡',
					num: 12,
					price: 3000,
					type: 0
				},
				{
					name: '运动康复卡2',
					num: 10,
					price: 3000,
					type: 0
				},
				{
					name: '运动康复卡3',
					num: 2,
					price: 3000,
					type: 0
				},
				{
					name: '运动康复卡4',
					num: 6,
					price: 3000,
					type: 0
				}
			],
			storedValueCardInfoList: [
				{
					name: '储值卡A',
					num: 12,
					type: 1,
					handsel: 500,
					price: 3000
				},
				{
					name: '储值卡B',
					num: 12,
					type: 1,
					handsel: 500,
					price: 3000
				},
				{
					name: '储值卡C',
					num: 12,
					type: 1,
					handsel: 500,
					price: 3000
				}
			],
			customCardInfoList: [
				{
					name: '运动康复卡',
					num: 12,
					price: 3000,
					type: 3
				},
				{
					name: '运动康复卡2',
					num: 10,
					price: 3000,
					type: 3
				}
			],
			rechargeInfoList: [
				{
					total: 3500,
					capital: 500,
					handsel: 200,
					shop: '一指阁（静安寺店）'
				},
				{
					total: 8000,
					capital: 1200,
					handsel: 2000,
					shop: '一指阁（外滩店）'
				}
			],
			consumeSecondaryCardInfoList: [
				{
					name: '运动康复卡',
					price: 12323,
					teacher: '张学友'
				},
				{
					name: '运动康复卡',
					price: 12323,
					teacher: '张学友'
				}
			],
			consumeStoredValueCardInfoList: [
				{
					name: '储值卡',
					price: 888888,
					teacher: '刘德华'
				}
			],
			customCardSelectInfoList: [
				{
					name: '手持热敏悬灸',
					oldPrice: 288,
					price: 399,
					num: 1
				}
			],
			orderInfoList: [],
			orderListCount: 0,
			rechargeRuleList: [],
			secondaryCardList: [],
			storedValueCardList: [],
			customCardList: [],
			userAccountBalance: 0,
			serveCategoryList: [],
			serveList: [],
			goodsList: [],
			goodsCategoryList: [],
			cardHandselList: [],
			cardInfo: {},
			shopTeacher: [],
			userBalance: [],
			userDetail: '',
			orderDetail: '',
			orderUserQuanyi: '',
			userCardList: [],
			dikouCardList: [],
			orderServeList: [],
			allOrderList: [],
			allOrderListCount: 0,
			waitShopOrderList: [],
			waitShopOrderListCount: 0,
			waitPayOrderList: [],
			waitPayOrderListCount: 0,
			finishOrderList: [],
			finishOrderListCount: 0,
			waitCommentOrderList: [],
			waitCommentOrderListCount: 0,
			cancelOrderList: [],
			cancelOrderListCount: 0,
			refoundList: [],
			refoundListCount: 0,
			orderCommentList: [],
			orderCommentListCount: 0,
			discountCardList: []
		}
	},
	getters: {
		pageListData(state) {
			return (pageName) => {
				return state[`${pageName}List`]
			}
		},
		pageListCount(state) {
			return (pageName) => {
				pageName = pageName.toLowerCase()
				return state[`${pageName}Count`]
			}
		}
	},
	mutations: {
		changeOrderList(state, list) {
			state.orderInfoList = list
		},
		changeOrderListCount(state, count) {
			state.orderListCount = count
		},
		changeRechargeRuleList(state, list) {
			state.rechargeRuleList = list
		},
		changeSecondaryCardList(state, list) {
			state.secondaryCardList = list
		},
		changeStoredValueCardList(state, list) {
			state.storedValueCardList = list
		},
		changeCustomCardList(state, list) {
			state.customCardList = list
		},
		changeUserAccountBalance(state, info) {
			state.userAccountBalance = info
		},
		changeServeCategoryList(state, list) {
			state.serveCategoryList = list
		},
		changeServeList(state, list) {
			state.serveList = list
		},
		changeGoodsCategoryList(state, list) {
			state.goodsCategoryList = list
		},
		changeGoodsList(state, list) {
			state.goodsList = list
		},
		changeCardHandselList(state, list) {
			state.cardHandselList = list
		},
		changeCardInfo(state, info) {
			state.cardInfo = info
		},
		changeShopTeacher(state, list) {
			state.shopTeacher = list
		},
		changeUserBalance(state, list) {
			state.userBalance = list
		},
		changeUserDetail(state, info) {
			state.userDetail = info
		},
		changeOrderDetail(state, info) {
			state.orderDetail = info
		},
		changeOrderUserQuanyi(state, info) {
			state.orderUserQuanyi = info
		},
		changeUserCardList(state, info) {
			state.userCardList = info
		},
		changeDikouCardList(state, list) {
			state.dikouCardList = list
		},
		changeOrderServeList(state, list) {
			state.orderServeList = list
		},
		changeRemoveOrderDetail(state, list) {
			state.orderDetail = ''
		},
		changeallOrderList(state, list) {
			state.allOrderList = list
		},
		changeallOrderListCount(state, count) {
			state.allOrderListCount = count
		},
		changewaitShopOrderList(state, list) {
			state.waitShopOrderList = list
		},
		changewaitShopOrderListCount(state, count) {
			state.waitShopOrderListCount = count
		},
		changewaitPayOrderList(state, list) {
			state.waitPayOrderList = list
		},
		changewaitPayOrderListCount(state, count) {
			state.waitPayOrderListCount = count
		},
		changefinishOrderList(state, list) {
			state.finishOrderList = list
		},
		changefinishOrderListCount(state, count) {
			state.finishOrderListCount = count
		},
		changewaitCommentOrderList(state, list) {
			state.waitCommentOrderList = list
		},
		changewaitCommentOrderListCount(state, count) {
			state.waitCommentOrderListCount = count
		},
		changcancelOrderList(state, list) {
			state.cancelOrderList = list
		},
		changecancelOrderListCount(state, count) {
			state.cancelOrderListCount = count
		},
		changeOrderRefoundList(state, list) {
			state.refoundList = list
		},
		changeOrderRefoundListCount(state, count) {
			state.refoundListCount = count
		},
		changeOrderCommentList(state, list) {
			state.orderCommentList = list
		},
		changeOrderCommentListCount(state, count) {
			state.orderCommentListCount = count
		},
		changeDiscountCardList(state, list) {
			state.discountCardList = list
		}
	},
	actions: {
		/*
		  tcType购买的种类
		  1劳动(服务)
		  2销售(卡)
		  3产品销售
		  4服务销售 暂时没有
		  5优惠券销售 暂时没有
	
		*/

		async getOrderListAction({ commit }, payload) {
			let obj = { ...payload }
			// let listtype = obj.listtype
			// let isCustomer = obj.isCustomer

			if (obj.date && obj.date.length > 0) {
				obj.starttime = obj.starttime + ' 00:00:00'
				obj.endtime = obj.endtime + ' 23:59:59'
			} else {
				obj.starttime = undefined
				obj.endtime = undefined
			}
			delete obj.date
			// delete obj.listtype
			// delete obj.isCustomer

			// const adminInfo = cache.getCache('adminInfo')

			// let res
			// if (isCustomer) {
			// 	res = await getOrderList2({ ...obj, userId: adminInfo.userId })
			// } else {
			let	res = await getOrderList({ ...obj})
			// }
			let list = res.data.list
			commit('changeallOrderList', list)
			commit('changeallOrderListCount', res.data.total)

			// 获取支付方式
			// const getPayTypeStr = (key) => {
			// 	let str = ''
			// 	switch (key) {
			// 		case 'Cash':
			// 			str = '现金'
			// 			break
			// 		case 'CashWechat':
			// 			str = '微信'
			// 			break
			// 		case 'CashAlipay':
			// 			str = '支付宝'
			// 			break
			// 		case 'youxianbenjin':
			// 			str = '有限次卡绑定服务'
			// 			break
			// 		case 'yuebenjin':
			// 			str = '余额本金'
			// 			break
			// 		case 'yuezengsong':
			// 			str = '余额赠送'
			// 			break
			// 		case 'youxianzengsong':
			// 			str = '有限次卡赠送服务'
			// 			break
			// 		case 'buxianbenjin':
			// 			str = '不限次卡绑定服务'
			// 			break
			// 		case 'buxianzengsong':
			// 			str = '不限次卡赠送服务'
			// 			break
			// 		case 'dingzhiben':
			// 			str = '定制卡绑定服务'
			// 			break
			// 		case 'dingzhizengsong':
			// 			str = '定制卡赠送服务'
			// 			break
			// 		case 'shangpin':
			// 			str = '商品兑换服务'
			// 			break
			// 		case 'duihuan':
			// 			str = '兑换券'
			// 			break
			// 		case 'chuzhikabenjin':
			// 			str = '储值卡本金'
			// 			break
			// 		case 'chuzhikazengsong':
			// 			str = '储值卡赠送'
			// 			break
			// 	}
			// 	return str
			// }

			// list.forEach((item) => {
			// 	let payList = item.paylist
			// 	let payTypeArr = []
			// 	let total = 0
			// 	payList.forEach((ele) => {
			// 		let payStr = getPayTypeStr(ele.payway)
			// 		payTypeArr.push(`${(ele.payprice / 100).toFixed(2)}`)
			// 		total += ele.payprice
			// 	})
			// 	item.payTypeArr = payTypeArr
			// 	item.actualPay = (total / 100).toFixed(2)
			// })

			// if (listtype == 0) {
			// 	commit('changeallOrderList', list)
			// 	commit('changeallOrderListCount', res.data.total)
			// } else if (listtype == 1) {
			// 	commit('changewaitShopOrderList', list)
			// 	commit('changewaitShopOrderListCount', res.data.total)
			// } else if (listtype == 2) {
			// 	commit('changewaitPayOrderList', list)
			// 	commit('changewaitPayOrderListCount', res.data.total)
			// } else if (listtype == 3) {
			// 	commit('changefinishOrderList', list)
			// 	commit('changefinishOrderListCount', res.data.total)
			// } else if (listtype == 4) {
			// 	commit('changewaitCommentOrderList', list)
			// 	commit('changewaitCommentOrderListCount', res.data.total)
			// } else if (listtype == 5) {
			// 	commit('changcancelOrderList', list)
			// 	commit('changecancelOrderListCount', res.data.total)
			// }
		},
		async getCardListAction({ commit }, payload) {
			//次卡
			const secondaryCard = await getCardList2({
				companyId: COMPANY_ID,
				kindid: 7,
				inshop: 1,
				outshop: '',
				shopId: '',
				...payload
			})

			// 储值卡
			const storedValueCard = await getCardList2({
				companyId: COMPANY_ID,
				kindid: 5,
				inshop: 1,
				outshop: '',
				shopId: '',
				...payload
			})

			// 折扣卡
			const discountCard = await getCardList2({
				companyId: COMPANY_ID,
				kindid: 6,
				inshop: 1,
				outshop: '',
				shopId: '',
				...payload
			})

			commit('changeSecondaryCardList', secondaryCard.data.list)
			commit('changeStoredValueCardList', storedValueCard.data.list)
			// commit('changeCustomCardList', customCard.data.list)
			commit('changeDiscountCardList', discountCard.data.list)
		},
		async getRechargeRuleListAction({ commit }, payload) {
			const res = await getRechargeRuleList2({
				companyId: COMPANY_ID,
				sale: 0
			})
			commit('changeRechargeRuleList', res.data.list)
		},
		async addOrderAction({ commit }, payload) {
			let obj = { ...payload }
			let optBalance = obj.balance
			let currentOrderType = obj.type
			let consumeList = obj.consumeList
			let onlinePayPrice = 0
			let currentPayTypeArr = []
			let curentPayPriceArr = []
			const adminInfo = cache.getCache('adminInfo')
			// 清楚之前的支付缓存
			cache.deleteCache('curentPayPriceArr')
			cache.deleteCache('currentpayTypeArr')
			cache.deleteCache('lastPayType')
			cache.deleteCache('currentXianjinArr')
			cache.deleteCache('curentXianjinPriceArr')

			if (currentOrderType == 3) {
				var _ruleId = obj.ruleId
				var _cardCash = obj.cardCash
				var _promCash = obj.promCash
				delete obj.ruleId
				delete obj.cardCash
				delete obj.promCash
			}

			// console.log(obj.empId)
			// 判断是否选择理疗师
			if (obj.empId.length == 0) {
				// ElMessage({
				//   message: '请先选择理疗师!',
				//   type: 'warning'
				// })
				// return
				obj.empId = 0
			}

			let cardDetail = (
				await getCardDetail({
					cardId: obj.cardId,
					companyId: obj.companyId
				})
			).data

			delete obj.cardId
			delete obj.qty
			delete obj.balance
			delete obj.type
			delete obj.consumeList
			delete obj.promCash
			delete obj.cardCash

			//type -1折扣卡 0 次卡 1储值卡 2定制卡 3充值
			// 判断是不是充值订单
			if (currentOrderType != 3) {
				Promise.all(
					consumeList.map(async (ele, indey) => {
						let cardDetail = (
							await getCardDetail({
								cardId: ele.cardId,
								companyId: obj.companyId
							})
						).data
						let d = new Date()
						let ss = Number(d.getSeconds()) + Number(indey)
						ss = Number(ss) > 58 ? 59 : ss
						ss = Number(ss) < 10 ? '0' + String(ss) : ss

						return {
							expireUseFlag: cardDetail.expireUseFlag,
							timeLimitFlag: cardDetail.timeLimitFlag,
							startTime: dayjs(new Date()).format(`YYYY-MM-DD HH:mm:${ss}`),
							fixEndTime: dayjs(new Date()).format(`YYYY-MM-DD HH:mm:${ss}`),
							itemName: ele.cardName,
							itemId: ele.cardId,
							paraId: 0,
							cardCash: currentOrderType == 1 ? cardDetail.exactPrice : 0,
							promCash: currentOrderType == 1 ? cardDetail.promPrice : 0,
							qty: ele.buyNum,
							orderDate: dayjs(new Date()).format(`YYYYMMDDHHmm${ss}`),
							orderKind: ele.kindId == 6 ? 8 : ele.kindId,
							salePrice: ele.salePrice,
							userId: obj.userId,
							companyId: obj.companyId
						}
					})
				).then(async (resList) => {
					const res = await addOrder2({ ...obj, items: resList, cashUid: adminInfo.userId })
					if (res.code == 500) {
						ElMessage({
							message: res.msg,
							type: 'warning'
						})
						return
					}
					const currentOrderNo = res.data.orderNo
					if (optBalance.length > 0) {
						const userPalance = (await getUserBalance2(obj.userId)).data.list
						let cardType = cardDetail.kindId
						let itemKind
						if (cardType == 3) {
							itemKind = 2
						} else if (cardType == 4) {
							itemKind = 3
						} else {
							itemKind = 4
						}

						let balanceParams = {
							subOrderNo: res.data.orderNo,
							userId: obj.userId,
							payprice: obj.totalPrice,
							serveName: obj.itemName,
							appNo: '',
							companyId: obj.companyId,
							itemKind,
							cash: 0,
							cashProm: 0,
							payfrom: 2
						}

						let onlinePayMoney = 0
						let payTypeStr

						// 如果只选择了本金
						if (optBalance.findIndex((item) => item == 1) != -1) {
							// 如果本金大于支付金额 余额支付
							if (Number(userPalance[0].userCash >= obj.totalPrice)) {
								balanceParams.cash = obj.totalPrice
								currentPayTypeArr = ['yuebenjin_0']
								curentPayPriceArr = [balanceParams.cash]
							}
							// 如果本金小于支付金额  组合支付
							else {
								// 调用支付待处理接口
								let waitSetParams1 = {
									orderNo: res.data.orderNo,
									kindId: 1,
									cashProm: 0,
									cash: userPalance[0].userCash,
									userId: obj.userId,
									cardNo: '',
									calc: 2
								}
								// 设置支付回滚
								let backParams = {
									orderNo: res.data.orderNo,
									payPrice: Number(
										obj.totalPrice - Number(userPalance[0].userCash)
									),
									combineFlag: 1,
									rollBack: 0
								}

								onlinePayPrice = backParams.payPrice
								currentPayTypeArr = ['yuebenjin_0']
								curentPayPriceArr = [userPalance[0].userCash]
								cache.setCache('currentpayTypeArr', currentPayTypeArr)
								cache.setCache('curentPayPriceArr', curentPayPriceArr)

								const waitSetRes1 = await setWaitAmount(waitSetParams1)
								const setBackRes = await setOrderBack(backParams)

								await updatePayInfo({
									subOrderNo: res.data.orderNo,
									// 网上支付
									payPrice: Number(
										obj.totalPrice - Number(userPalance[0].userCash)
									),
									// 折扣支付
									combinePrice: Number(userPalance[0].userCash)
								})

								emitter.emit('changeOrderQrcodePayOPenPayDialog', {
									oid: res.data.orderNo,
									orderKind: currentOrderType,
									payPrice: obj.totalPrice,
									isBack: true,
									consumeList,
									orderDate: obj.orderDate,
									waitPayPrice: Number(
										obj.totalPrice - Number(userPalance[0].userCash)
									),
									isOpenKaika: true,
									payKind: 2,
									tcType: 2,
									buyshopId: obj.shopId
								})
								return
							}
						}
						// 如果只选择了赠金
						else if (optBalance.findIndex((item) => item == 2) != -1) {
							// 如果赠金大于支付金额 余额支付
							if (Number(userPalance[1].userCash >= obj.totalPrice)) {
								balanceParams.cashProm = obj.totalPrice
								currentPayTypeArr = ['yuezengsong_0']
								curentPayPriceArr = [balanceParams.cashProm]
							}
							// 如果赠金小于支付金额  组合支付
							else {
								// 调用支付待处理接口
								let waitSetParams1 = {
									orderNo: res.data.orderNo,
									kindId: 1,
									cashProm: userPalance[1].userCash,
									cash: 0,
									userId: obj.userId,
									cardNo: '',
									calc: 2
								}
								// 设置支付回滚
								let backParams = {
									orderNo: res.data.orderNo,
									payPrice: Number(
										obj.totalPrice - Number(userPalance[1].userCash)
									),
									combineFlag: 1,
									rollBack: 0
								}

								onlinePayPrice = backParams.payPrice
								// payTypeStr = `yuezengsong,${userPalance[1].userCash}&danci,${backParams.payPrice}`
								currentPayTypeArr = ['yuezengsong_0']
								curentPayPriceArr = [balanceParams.cashProm]
								cache.setCache('currentpayTypeArr', currentPayTypeArr)
								cache.setCache('curentPayPriceArr', curentPayPriceArr)

								const waitSetRes1 = await setWaitAmount(waitSetParams1)
								const setBackRes = await setOrderBack(backParams)

								await updatePayInfo({
									subOrderNo: res.data.orderNo,
									// 网上支付
									payPrice: Number(
										obj.totalPrice - Number(userPalance[1].userCash)
									),
									// 折扣支付
									combinePrice: Number(userPalance[1].userCash)
								})

								emitter.emit('changeOrderQrcodePayOPenPayDialog', {
									oid: res.data.orderNo,
									orderKind: currentOrderType,
									payPrice: obj.totalPrice,
									isBack: true,
									consumeList,
									orderDate: obj.orderDate,
									waitPayPrice: Number(
										obj.totalPrice - Number(userPalance[1].userCash)
									),
									isOpenKaika: true,
									payKind: 2,
									tcType: 2,
									buyshopId: obj.shopId
								})
								return
							}
						}
						const userBalancePayRes = await userBalancePay2(balanceParams)

						cache.setCache('currentpayTypeArr', currentPayTypeArr)
						cache.setCache('curentPayPriceArr', curentPayPriceArr)

						try {
							await setOrderRecordPaytype({
								orderNo: currentOrderNo,
								payWay: currentPayTypeArr.join(),
								payPrice: curentPayPriceArr.join(),
								payKind: 2
							})

							const empId = cache.getCache('currentPayEmpid')
							if (empId != '' && empId != 0) {
								let res1 = await setXiaosouYeji({
									orderNo: currentOrderNo,
									empId,
									shopId: obj.shopId
								})

								if (res1.code != 0) {
									ElMessage({
										message: res1.msg,
										type: 'warning'
									})
								}

								let res2 = await addTicheng({
									orderNo: currentOrderNo,
									empId,
									shopId: obj.shopId,
									bindKind: 2
								})

								if (res2.code != 0) {
									ElMessage({
										message: res2.msg,
										type: 'warning'
									})
								}
							}
						} catch (error) {
							// console.log(error)
						}

						tips(userBalancePayRes)
						router.push('/main/order/list')
					}
					//直接二维码支付
					else {
						// 调用在线支付金额接口
						let onlinePayPrice = obj.totalPrice

						emitter.emit('changeOrderQrcodePayOPenPayDialog', {
							oid: res.data.orderNo,
							orderKind: currentOrderType,
							payPrice: obj.totalPrice,
							// 是否回滚处理 余额充值时需要
							isBack: currentOrderType == 1 ? true : false,
							consumeList,
							orderDate: obj.orderDate,
							waitPayPrice: obj.totalPrice,
							isOpenKaika: true,
							payKind: 2,
							tcType: 2,
							buyshopId: obj.shopId
						})
					}
				})
			} else {
				let saveParams = {
					expireUseFlag: '0',
					timeLimitFlag: '0',
					startTime: dayjs(new Date()).format(`YYYY-MM-DD HH:mm:ss`),
					fixEndTime: dayjs(new Date()).format(`YYYY-MM-DD HH:mm:ss`),
					itemName: obj.itemName,
					itemId: _ruleId,
					paraId: 0,
					cardCash: _cardCash,
					promCash: _promCash,
					qty: 1,
					orderDate: obj.orderDate,
					orderKind: obj.orderKind,
					salePrice: obj.totalPrice,
					userId: obj.userId,
					companyId: obj.companyId
				}
				const res = await addOrder2({ ...obj, items: [saveParams], cashUid: adminInfo.userId })
				let backParams = {
					orderNo: res.data.orderNo,
					payPrice: obj.totalPrice,
					combineFlag: 1,
					rollBack: 0
				}

				const setBackRes = await setOrderBack(backParams)

				emitter.emit('changeOrderQrcodePayOPenPayDialog', {
					oid: res.data.orderNo,
					orderKind: currentOrderType,
					payPrice: obj.totalPrice,
					isBack: true,
					consumeList,
					orderDate: obj.orderDate,
					waitPayPrice: obj.totalPrice,
					isOpenKaika: true,
					payKind: 2,
					tcType: 2,
					buyshopId: obj.shopId
				})
			}
		},
		async getUserInfoAction({ commit }, payload) {
			const res = await getUserInfo(payload)
			commit('changeUserDetail', res.data)
		},
		async getUserAccountBalanceAction({ commit }, payload) {
			const res = await getUserAccountBalance(payload)
			commit('changeUserAccountBalance', res.data)
		},
		async getServeCategoryListAction({ commit }, payload) {
			const res = await getServeCategoryList(payload)
			let list = res.data.serveCata
			commit('changeServeCategoryList', list)
		},
		async getServeListAction({ commit }, payload) {
			let obj = {
				companyId: COMPANY_ID,
				page: 1,
				servestatus: 1,
				keyword: '',
				kindid: payload
			}
			const res = await getServeList(obj)
			commit('changeServeList', res.data.allServe)
		},
		async getGoodsCategoryListAction({ commit }, payload) {
			const res = await getGoodsCategoryList(payload)
			let list = res.data.goodCata
			if (list.length > 0) {
				const goodsRes = await getGoodsList(list[0].kindId)
				commit('changeGoodsList', goodsRes.data.list)
			}
			commit('changeGoodsCategoryList', list)
		},
		async getGoodsListAction({ commit }, payload) {
			const res = await getGoodsList(payload)
			commit('changeGoodsList', res.data.list)
		},
		async getCardDetailAction({ commit }, payload) {
			const res = await getCardHandselList(...payload)
			commit('changeCardHandselList', res.data)
		},
		async getCardInfoAction({ commit }, payload) {
			const res = await getCardDetail(payload)
			commit('changeCardInfo', res.data)
		},
		async getShopTeacherAction({ commit }, payload) {
			const res = await getShopTeacher2(payload)
			commit('changeShopTeacher', res.data.list)
		},
		async getUserBalanceAction({ commit }, payload) {
			if (payload == undefined) {
				return
			}
			const res = await getUserBalance(payload)
			commit('changeUserBalance', res.data.list)
		},
		async getOrderDetailAction({ commit }, payload) {
			console.log(...payload.slice(0, 2))
			const res = await getOrderDetail(...payload.slice(0, 2))
			const serveList = res.data.serveItems
			let buyItems = res.data.buyItems
			Promise.all(
				serveList.map(async (item) => {
					return await getUserDikouList(
						payload[2] ? payload[2] : res.data.orderDetail.userId,
						item.serveId,
						item.paraId
					)
				})
			).then(async (resList) => {
				resList.forEach((ele, indey) => {
					let _servelist = ele.data.servelist
					let _giftlift = ele.data.giftlift
					_servelist.forEach((el, indez) => {
						_servelist[indez].dkType = 0
					})
					_giftlift.forEach((el, indez) => {
						_giftlift[indez].dkType = 1
					})
					let _newServeList = [..._servelist, ..._giftlift]
					_newServeList.forEach((elem, indek) => {
						_newServeList[indek].value = elem.cardNo + indek
					})
					serveList[indey].quanyiList = _newServeList
				})

				const userCardRes = payload[2] ? await getUserCardList(payload[2]) : await getUserCardList(res.data.orderDetail.userId)
				const cardList = userCardRes.data.cardlist
				let newCardList = cardList.filter((item) => item.kindId == 5)

				serveList.forEach((ele, index) => {
					ele.qty = buyItems[index].qty
				})


				commit('changeOrderServeList', serveList)
				commit('changeUserCardList', newCardList)
				commit('changeOrderDetail', res.data)
			})
		},

		async openkaidanAction({ commit }, payload) {
			let obj = { ...payload }
			let buyshopId = obj.shopId
			delete obj.shopId
			let consumeList = obj.consumeList
			let payType = obj.payType
			let detailMessageRes = await getOrderDetail(COMPANY_ID, obj.subOrderNo)
			let { orderDetail, empinfo } = detailMessageRes.data
			let messageObj = {
				userId: orderDetail.userId,
				orderNo: obj.subOrderNo,
				changeTime: dayjs(orderDetail.createTime).format(
					'YYYY年MM月DD日 HH:mm:ss'
				),
				kind: '',
				cardNo: '0',
				empName: empinfo[0]['userRealname']
			}

			let quanyiList = obj.quanyipayInfo

			let currentPayTypeArr = []
			let curentPayPriceArr = []
			let cardKindNameArr = []

			// 清楚之前的支付缓存
			cache.deleteCache('curentPayPriceArr')
			cache.deleteCache('currentpayTypeArr')
			cache.deleteCache('lastPayType')
			cache.deleteCache('currentXianjinArr')
			cache.deleteCache('curentXianjinPriceArr')
			cache.deleteCache('zekoukaPrice')

			quanyiList.forEach((item, index) => {
				let cardtype = item.quanyiCardType
				let bindtype = item.quanyiType
				let price = item.servePrice
				let cardNo = item.quanyiCardNo
				let str
				if (cardtype == 3 && bindtype == 0) {
					str = `youxianbenjin_${cardNo}`
					cardKindNameArr.push('youxianbenjin')
				} else if (cardtype == 3 && bindtype == 1) {
					str = `youxianzengsong_${cardNo}`
					cardKindNameArr.push('youxianzengsong')
				} else if (cardtype == 4 && bindtype == 0) {
					str = `buxianbenjin_${cardNo}`
					cardKindNameArr.push('buxianbenjin')
				} else if (cardtype == 4 && bindtype == 1) {
					str = `buxianzengsong_${cardNo}`
					cardKindNameArr.push('buxianzengsong')
				}
				currentPayTypeArr.push(str)
				curentPayPriceArr.push(price)
			})

			// 消耗储值卡 传入已经消耗掉的价格
			let onUseRecord = async (consume) => {
				let params = {
					cardNo: obj.cuzaiCardNo,
					orderNo: obj.subOrderNo,
					userId: obj.userId,
					price: obj.totalPrice * 100 - (consume || 0),
					serveName: obj.cuziserveName,
					companyId: COMPANY_ID,
					appNo: obj.reserveNo,
					cash: 0,
					cashProm: 0
				}
				let benjin = obj.cuzibj
				let zengjin = obj.cuzizj
				let payTypeStr
				let payPriceStr
				if (benjin >= params.price) {
					params.cash = params.price
					payTypeStr = `chuzhikabenjin_${obj.cuzaiCardNo}`
					payPriceStr = `${params.price}`
				} else if (zengjin >= params.price) {
					params.cashProm = params.price
					payTypeStr = `chuzhikazengsong_${obj.cuzaiCardNo}`
					payPriceStr = `${params.price}`
				} else if (benjin + zengjin >= params.price) {
					if (benjin > zengjin) {
						params.cash = benjin
						params.cashProm = params.price - benjin
					} else {
						params.cashProm = zengjin
						params.cash = params.price - zengjin
					}
					payTypeStr = `chuzhikabenjin_${obj.cuzaiCardNo},chuzhikazengsong_${obj.cuzaiCardNo}`
					payPriceStr = `${params.cash},${params.cashProm}`
				}

				const res = await setUserOrderStoredValueCardPay(params)
				try {
					await setOrderRecordPaytype({
						orderNo: obj.subOrderNo,
						payWay: payTypeStr,
						payPrice: payPriceStr,
						payKind: 1
					})

					// 发送余额/储值卡/折扣卡使用的消息通知
					messageObj['kind'] = 2
					messageObj['cardNo'] = obj.cuzaiCardNo
					await messageNotifyBackOrder(messageObj)
				} catch (error) {
					// console.log(error)
				}

				tips(res)
				router.push('/main/reserve/check')
			}

			/*
			支付方式
			// 0: 券   1: 券 + 储值   2: 次卡
			// 3: 储值卡  4: 次卡 + 储值  5: 储值 + 现金
			// 6: 次卡 + 现金  7: 券 + 现金  8: 现金

			0权益支付
			1储值卡支付
			3直接线上支付
			5储值卡+线上支付
	  
		   */
			if (payType == 0) {
				// 消耗掉券
				await useCoupon({
					couponNo: obj.xjqCouponNo.couponNo,
					userId: obj.userId,
					orderNo: obj.subOrderNo
				})

				// 使用券消费订单
				await updateXjqPayPrice({
					subOrderNo: obj.subOrderNo,
					payPrice: 0,
					cashPrice: obj.totalPrice * 100
				})

				// 结束订单
				await setOrderFinsh({
					orderNo: obj.subOrderNo,
					companyId: COMPANY_ID,
					appNo: obj.reserveNo
				})

				ElMessage({
					message: '支付成功!',
					type: 'success'
				})
				router.push('/main/reserve/check')
				return
			}

			if (payType == 1) {
				// 券 + 储值  消耗掉劵剩下的全部使用储值

				// 消耗掉券
				await useCoupon({
					couponNo: obj.xjqCouponNo.couponNo,
					userId: obj.userId,
					orderNo: obj.subOrderNo
				})

				// 使用券消费订单
				await updateXjqPayPrice({
					subOrderNo: obj.subOrderNo,
					payPrice: obj.totalPrice * 100 - obj.xjqPayPrice,
					cashPrice: obj.xjqPayPrice
				})

				await onUseRecord(obj.xjqPayPrice)
				return
			}


			if (payType == 2) {
				// 权益支付
				try {
					await setOrderRecordPaytype({
						orderNo: obj.subOrderNo,
						payWay: currentPayTypeArr.join(),
						payKind: 1,
						payPrice: curentPayPriceArr.join()
					})
				} catch (error) {
					// console.log(error)
				}

				let dikouArr = []
				obj.quanyipayInfo.forEach((item) => {
					dikouArr.push(
						`${item.quanyiCardNo},${item.quanyiType},${item.serveId},${item.paraId}`
					)
				})

				let params = {
					orderNo: obj.subOrderNo,
					cardNo: dikouArr.join('&'),
					userId: obj.userId,
					kindId: 0,
					payKind: 3,
					companyId: COMPANY_ID,
					appNo: obj.reserveNo,
					cardKindName: cardKindNameArr.join()
				}
				const res = await setOrderQuanyiPay(params)
				tips(res)
				router.push('/main/reserve/check')
				return
			}

			if (payType == 3) {
				// 储值卡支付
				await onUseRecord(0)
				return
			}

			// if (payType == 4) {

			// 	// 权益 + 储值

			// 	// 储值相关
			// 	let params = {
			// 		cardNo: obj.cuzaiCardNo,
			// 		orderNo: obj.subOrderNo,
			// 		userId: obj.userId,
			// 		price: obj.totalPrice * 100 - (consume || 0),
			// 		serveName: obj.cuziserveName,
			// 		companyId: COMPANY_ID,
			// 		appNo: obj.reserveNo,
			// 		cash: 0,
			// 		cashProm: 0
			// 	}
			// 	let benjin = obj.cuzibj
			// 	let zengjin = obj.cuzizj
			// 	let payTypeStr
			// 	let payPriceStr
			// 	if (benjin >= params.price) {
			// 		params.cash = params.price
			// 		payTypeStr = `chuzhikabenjin_${obj.cuzaiCardNo}`
			// 		payPriceStr = `${params.price}`
			// 	} else if (zengjin >= params.price) {
			// 		params.cashProm = params.price
			// 		payTypeStr = `chuzhikazengsong_${obj.cuzaiCardNo}`
			// 		payPriceStr = `${params.price}`
			// 	} else if (benjin + zengjin >= params.price) {
			// 		if (benjin > zengjin) {
			// 			params.cash = benjin
			// 			params.cashProm = params.price - benjin
			// 		} else {
			// 			params.cashProm = zengjin
			// 			params.cash = params.price - zengjin
			// 		}
			// 		payTypeStr = `chuzhikabenjin_${obj.cuzaiCardNo},chuzhikazengsong_${obj.cuzaiCardNo}`
			// 		payPriceStr = `${params.cash},${params.cashProm}`
			// 	}
				
			// 	// 上传支付方式
			// 	try {
			// 		await setOrderRecordPaytype({
			// 			orderNo: obj.subOrderNo,
			// 			payWay: currentPayTypeArr.join() + ',' + payTypeStr,
			// 			payKind: 1,
			// 			payPrice: curentPayPriceArr.join() + ',' + payPriceStr
			// 		})
			// 	} catch (error) {
			// 		// console.log(error)
			// 	}
				
			// 	// 消耗储值
			// 	let res = await setUserOrderStoredValueCardPay(params)
			// 	try {
			// 		// 发送余额/储值卡/折扣卡使用的消息通知
			// 		messageObj['kind'] = 2
			// 		messageObj['cardNo'] = obj.cuzaiCardNo
			// 		await messageNotifyBackOrder(messageObj)
			// 	} catch (error) {
			// 		// console.log(error)
			// 	}

			// 	// 消耗权益
			// 	let dikouArr = []
			// 	obj.quanyipayInfo.forEach((item) => {
			// 		dikouArr.push(
			// 			`${item.quanyiCardNo},${item.quanyiType},${item.serveId},${item.paraId}`
			// 		)
			// 	})

			// 	let params1 = {
			// 		orderNo: obj.subOrderNo,
			// 		cardNo: dikouArr.join('&'),
			// 		userId: obj.userId,
			// 		kindId: 0,
			// 		payKind: 3,
			// 		companyId: COMPANY_ID,
			// 		appNo: obj.reserveNo,
			// 		cardKindName: cardKindNameArr.join()
			// 	}
			// 	const res1 = await setOrderQuanyiPay(params)
			// 	tips(res1)
			// 	router.push('/main/reserve/check')
			// 	return
			// }

			/*
			支付方式和金额 本地缓存记录
			currentpayTypeArr
			线上支付（微信 支付宝 现金）
			0 线上支付
			1 折扣+线上支付
			2 储值卡+线上支付
			3 余额+线上支付
			*/

			// 储值卡+线上支付
			if (payType == 5) {
				let bj = obj.cuzibj
				let zj = obj.cuzizj
				let currentPayTypeArr = []
				let curentPayPriceArr = []

				if (bj > 0 && zj > 0) {
					let waitSetParams1 = {
						orderNo: obj.subOrderNo,
						kindId: 2,
						cash: bj,
						cashProm: zj,
						userId: obj.userId,
						cardNo: obj.cuzaiCardNo,
						calc: 2
					}

					const waitSetRes1 = await setWaitAmount(waitSetParams1)

					let backParams = {
						orderNo: obj.subOrderNo,
						payPrice: obj.totalPrice * 100 - (bj + zj),
						combineFlag: 1,
						rollBack: 0
					}
					const setBackRes = await setOrderBack(backParams)

					await updatePayInfo({
						subOrderNo: obj.subOrderNo,
						// 网上支付
						payPrice: obj.totalPrice * 100 - (bj + zj),
						// 折扣支付
						combinePrice: bj + zj
					})

					// 记录支付方式
					currentPayTypeArr = [
						`chuzhikabenjin_${obj.cuzaiCardNo}`,
						`chuzhikazengsong_${obj.cuzaiCardNo}`
					]
					curentPayPriceArr = [bj, zj]
					cache.setCache('currentpayTypeArr', currentPayTypeArr)
					cache.setCache('curentPayPriceArr', curentPayPriceArr)

					emitter.emit('changeOrderKaidanQrcodePayOPenPayDialog', {
						payPrice: obj.totalPrice * 100,
						oid: obj.subOrderNo,
						isBack: true,
						orderKind: 6,
						consumeList: [consumeList],
						reserveNo: obj.reserveNo,
						waitPayPrice: obj.totalPrice * 100 - (bj + zj),
						payKind: 1,
						tcType: 1,
						buyshopId
					})
				} else if (bj > 0) {
					let waitSetParams1 = {
						orderNo: obj.subOrderNo,
						kindId: 2,
						cash: bj,
						cashProm: 0,
						userId: obj.userId,
						cardNo: obj.cuzaiCardNo,
						calc: 2
					}
					const waitSetRes1 = await setWaitAmount(waitSetParams1)
					let backParams = {
						orderNo: obj.subOrderNo,
						payPrice: obj.totalPrice * 100 - bj,
						combineFlag: 1,
						rollBack: 0
					}

					const setBackRes = await setOrderBack(backParams)

					await updatePayInfo({
						subOrderNo: obj.subOrderNo,
						// 网上支付
						payPrice: obj.totalPrice * 100 - bj,
						// 折扣支付
						combinePrice: bj
					})

					// 记录支付方式
					currentPayTypeArr = [`chuzhikabenjin_${obj.cuzaiCardNo}`]
					curentPayPriceArr = [bj]
					cache.setCache('currentpayTypeArr', currentPayTypeArr)
					cache.setCache('curentPayPriceArr', curentPayPriceArr)

					emitter.emit('changeOrderKaidanQrcodePayOPenPayDialog', {
						payPrice: obj.totalPrice * 100,
						oid: obj.subOrderNo,
						isBack: true,
						orderKind: 6,
						consumeList: [consumeList],
						reserveNo: obj.reserveNo,
						waitPayPrice: obj.totalPrice * 100 - bj,
						payKind: 1,
						tcType: 1,
						buyshopId
					})
				} else if (zj > 0) {
					let waitSetParams2 = {
						orderNo: obj.subOrderNo,
						kindId: 2,
						cashProm: zj,
						cash: 0,
						userId: obj.userId,
						cardNo: obj.cuzaiCardNo,
						calc: 2
					}
					const waitSetRes2 = await setWaitAmount(waitSetParams2)
					let backParams = {
						orderNo: obj.subOrderNo,
						payPrice: obj.totalPrice * 100 - zj,
						combineFlag: 1,
						rollBack: 0
					}

					const setBackRes = await setOrderBack(backParams)

					await updatePayInfo({
						subOrderNo: obj.subOrderNo,
						// 网上支付
						payPrice: obj.totalPrice * 100 - zj,
						// 折扣支付
						combinePrice: zj
					})

					// 记录支付方式
					currentPayTypeArr = [`chuzhikazengsong_${obj.cuzaiCardNo}`]
					curentPayPriceArr = [zj]
					cache.setCache('currentpayTypeArr', currentPayTypeArr)
					cache.setCache('curentPayPriceArr', curentPayPriceArr)

					emitter.emit('changeOrderKaidanQrcodePayOPenPayDialog', {
						payPrice: obj.totalPrice * 100,
						oid: obj.subOrderNo,
						isBack: true,
						orderKind: 6,
						consumeList: [consumeList],
						reserveNo: obj.reserveNo,
						waitPayPrice: obj.totalPrice * 100 - zj,
						payKind: 1,
						tcType: 1,
						buyshopId
					})
				}
			}

			if (payType == 6) {
				// 权益 + 现金
				// 记录支付方式
				// 记录支付方式
				cache.setCache('currentpayTypeArr', currentPayTypeArr)
				cache.setCache('curentPayPriceArr', curentPayPriceArr)
				// try {
				// 	await setOrderRecordPaytype({
				// 		orderNo: obj.subOrderNo,
				// 		payWay: currentPayTypeArr.join(),
				// 		payKind: 1,
				// 		payPrice: curentPayPriceArr.join()
				// 	})
				// } catch (error) {
				// 	// console.log(error)
				// }

				let dikouArr = []
				obj.quanyipayInfo.forEach((item) => {
					dikouArr.push(
						`${item.quanyiCardNo},${item.quanyiType},${item.serveId},${item.paraId}`
					)
				})

				let params = {
					orderNo: obj.subOrderNo,
					cardNo: dikouArr.join('&'),
					userId: obj.userId,
					kindId: 0,
					payKind: 3,
					companyId: COMPANY_ID,
					appNo: obj.reserveNo,
					cardKindName: cardKindNameArr.join()
				}
				await setOrderQuanyiPay(params)

				let quanyiPayTotal = 0
				if (quanyiList.length > 0) {
					quanyiList.forEach(item => {
						quanyiPayTotal += item.servePrice
					})
				}

				// 剩余现金支付
				let waitPayPrice = obj.totalPrice * 100 - quanyiPayTotal

				emitter.emit('changeOrderKaidanQrcodePayOPenPayDialog', {
					payPrice: obj.totalPrice * 100,
					oid: obj.subOrderNo,
					isBack: true,
					orderKind: 7,
					consumeList: [consumeList],
					reserveNo: obj.reserveNo,
					waitPayPrice,
					payKind: 1,
					tcType: 1,
					buyshopId
				})

				return
			}

			if (payType == 7) {
				// 券 + 现金  消耗掉劵剩下的现金支付

				// 消耗掉券
				await useCoupon({
					couponNo: obj.xjqCouponNo.couponNo,
					userId: obj.userId,
					orderNo: obj.subOrderNo
				})

				// 使用券消费订单
				await updateXjqPayPrice({
					subOrderNo: obj.subOrderNo,
					payPrice: obj.totalPrice * 100 - obj.xjqPayPrice,
					cashPrice: obj.xjqPayPrice
				})

				// 剩余现金支付
				let waitPayPrice = obj.totalPrice * 100 - obj.xjqPayPrice
				let payPrice = obj.totalPrice * 100

				emitter.emit('changeOrderKaidanQrcodePayOPenPayDialog', {
					payPrice: payPrice,
					oid: obj.subOrderNo,
					isBack: false,
					orderKind: 4,
					consumeList: [consumeList],
					reserveNo: obj.reserveNo,
					waitPayPrice,
					payKind: 1,
					tcType: 1,
					buyshopId
				})
				return
			}

			
			if (payType == 8) {
				// 线上支付(全部)
				let waitPayPrice = obj.totalPrice * 100
				let payPrice = obj.totalPrice * 100

				emitter.emit('changeOrderKaidanQrcodePayOPenPayDialog', {
					payPrice: payPrice,
					oid: obj.subOrderNo,
					isBack: false,
					orderKind: 4,
					consumeList: [consumeList],
					reserveNo: obj.reserveNo,
					waitPayPrice,
					payKind: 1,
					tcType: 1,
					buyshopId
				})
			}
		},


		async getRefoundListAction({ commit }, payload) {
			let obj = { ...payload }
			delete obj.date
			const res = await getOrderRefoundList(obj)
			let list = res.data.list
			let total = res.data.total
			commit('changeOrderRefoundList', list)
			commit('changeOrderRefoundListCount', total)

			// console.log(res)
		},
		async getOrderCommentListAction({ commit }, payload) {
			let obj = { ...payload }
			delete obj.date
			delete obj.timeType
			delete obj.showState
			const res = await getCommentList(obj)
			let list = res.data.list
			let count = res.data.total
			commit('changeOrderCommentList', list)
			commit('changeOrderCommentListCount', count)
		}
	}
}

export default orderModule
