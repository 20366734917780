import { request4 } from '@/service/request4'
import cache from "@/utils/cache"

export function getShopList(companyId) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/permission/shop-basic/${companyId}/${cashUid}`,
    method: 'post'
  })
}

export function testYeji() {
  return request4({
    url: `/manager/money/test-yeji-list`,
    method: 'post'
  })
}

export function testTicheng() {
  return request4({
    url: `/manager/money/test-ticheng-list`,
    method: 'post'
  })
}

export function testTicheng2() {
  return request4({
    url: `/manager/money/test-ticheng-list-norule`,
    method: 'post'
  })
}

export function testTicheng3(shopid, year, month) {
  return request4({
    url: `/manager/money/total-shop-yeji/${shopid}/${year}/${month}`,
    method: 'post'
  })
}

export function testTicheng4(shopId, year, month) {
  return request4({
    url: `/manager/money/new-shop-total-ticheng/${shopId}/${year}/${month}`,
    method: 'post'
  })
}

export function countTichang(obj) {
  return request4({
    url: `/manager/money/brand-new-sale-ticheng`,
    method: 'post',
    data: obj
  })
}

export function updateUserBalance(obj) {
  return request4({
    url: `/manager/money/test-update-customer-cash`,
    method: 'post',
    data: obj
  })
}

export function updateChuzhiBalance(cardId, cash, cashProm) {
  return request4({
    url: `/manager/money/test-update-card-cash/${cardId}/${cash}/${cashProm}`,
    method: 'post'
  })
}

export function searchStaff(obj) {
  return request4({
    url: `/manager/admin/search-emp`,
    method: 'post',
    data: obj
  })
}

export function setTuiguangImg(id) {
  return request4({
    url: `/manager/card/coupon/qrcode/${id}`,
    method: 'post'
  })
}

export function getRankList(id) {
  return request4({
    url: `/manager/admin/simple-get-role/${id}`,
    method: 'post'
  })
}

export function isBuyCard() {
  return request4({
    url: `/manager/admin/cash-buy-card`,
    method: 'post'
  })
}

export function updateIsBuyCard(n) {
  return request4({
    url: `/manager/admin/update-cash-card/${n}`,
    method: 'post'
  })
}

export function getPingdaoList() {
  return request4({
    url: `/manager/permission/saas-channels`,
    method: 'post'
  })
}

export function getPingdaoPageList(cid) {
  return request4({
    url: `/manager/permission/saas-pages/${cid}`,
    method: 'post'
  })
}

export function addInter(obj) {
  return request4({
    url: `/manager/permission/new-saas-api`,
    method: 'post',
    data: obj
  })
}

export function addChannel(name) {
  return request4({
    url: `/manager/permission/new-saas-channel/${name}`,
    method: 'post'
  })
}

export function addPage(cid, name) {
  return request4({
    url: `/manager/permission/new-saas-page/${cid}/${name}`,
    method: 'post'
  })
}

export function addGongNeng(obj) {
  return request4({
    url: `/manager/permission/new-permission-func`,
    method: 'post',
    data: obj
  })
}

export function getGongNengList(id) {
  return request4({
    url: `/manager/permission/permission-funcs/${id}`,
    method: 'post'
  })
}

export function getQuanxianList(id) {
  return request4({
    url: `/manager/permission/permission-pages/${id}`,
    method: 'post'
  })
}

export function messageNotify1(obj) {
  return request4({
    url: `/manager/order/notifyBackCash`,
    method: 'post',
    data: obj
  })
}

export function messageComment(obj) {
  return request4({
    url: `/manager/order/notifyCommentEmp`,
    method: 'post',
    data: obj
  })
}

export function messageNotifyBackOrder(obj) {
  return request4({
    url: `/manager/order/notifyBackOrder`,
    method: 'post',
    data: obj
  })
}






