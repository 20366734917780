import {
  addGoodsTag,
  addGoodsCategory,
  getGoodsCategoryList,
  updateGoodsCategoryInfo,
  getGoodsTagList,
  addGoods,
  savePic,
  saveGoodsFormat,
  getGoodsList,
  getGoodsDetail,
  getGoodsFormat,
  getGoodsPic,
  removeGoodsPic,
  updateGoodsInfo,
  removeGoodsFormat,
  updateGoodsState,
  removeGoodsTag,
  getGoodsPic2,
  getGoodsCategoryName,
  setGoodsShareInfo,
  setBatchGoodsState,
  updateGoodsFormat
} from '@/service/main/goods'
import { updateTagName } from '@/service/main/public'
import { tips } from '@/utils/tips'
import { handlleRecursionInfo } from '@/utils/recursion'
import emitter from '@/utils/eventbus'
import router from '@/router'
import { COMPANY_ID } from '@/common/constant'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'

const goodsModule = {
  namespaced: true,
  state() {
    return {
      goodsInfoList: [],
      goodsCategoryList: [],
      goodsTagList: [],
      goodsInfoCount: 0,
      updateGoodsDetail: {},
      goodsFormatList: [],
      goodsPicList: [],
      newAddGoodsId: '',
      updateGoodsId: '',
      goodsAllCategoryList: []
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changeNewAddGoodsId(state, info) {
      state.newAddGoodsId = info
    },
    changeGoodsCategoryList(state, list) {
      state.goodsCategoryList = list
    },
    changeAllGoodsCategoryList(state, list) {
      state.goodsAllCategoryList = list
    },
    changeGoodsTagList(state, list) {
      state.goodsTagList = list
    },
    changeGoodsList(state, list) {
      state.goodsInfoList = list
    },
    changeGoodsListCount(state, count) {
      state.goodsInfoCount = count
    },
    changeUpdateGoods(state, info) {
      state.updateGoodsDetail = info
    },
    changeGoodsFormat(state, info) {
      state.goodsFormatList = info
    },
    changeGoodsPic(state, list) {
      state.goodsPicList = list
    },
    changeUpdateGoodsId(state, info) {
      state.updateGoodsId = info
    }
  },
  actions: {
    async getgoodsInfoListAction({ commit }, payload) {
      const obj = { ...payload }
      delete obj.pageName
      const res = await getGoodsList(obj)
      let list = res.data.goodItem
      Promise.all(
        list.map(async (item) => {
          return await getGoodsPic2(item.goodId)
        })
      ).then((shopImgres) => {
        shopImgres.forEach((item, index) => {
          list[index].pic = item.data
        })
        Promise.all(
          list.map(async (item) => {
            return await getGoodsCategoryName(
              `${item.cataId},${item.subCataId}`
            )
          })
        ).then((cateRes) => {
          cateRes.forEach((item, index) => {
            list[index].gtag = item.data.list
          })

          Promise.all(
            list.map(async (elem) => {
              let formatParams = {
                companyId: COMPANY_ID,
                goodId: elem.goodId
              }
              return await getGoodsFormat(formatParams)
            })
          ).then((FormatList) => {
            FormatList.forEach((eleme, indey) => {
              list[indey].formatList = eleme.data.goodCata
            })
            commit('changeGoodsList', list)
            commit('changeGoodsListCount', res.data.total)
          })
        })
      })
    },
    async addTagAction({ commit }, payload) {
      const obj = Object.assign(payload, { tagKind: 2 })
      const res = await addGoodsTag(obj)
      tips(res)
      if (res.code == 0) {
        emitter.emit('goodsTagListChange')
      }
    },
    async addGoodsCategoryAction({ commit }, payload) {
      const res = await addGoodsCategory(payload)
      tips(res)
      emitter.emit('goodsCategoryUpdateNameChange')
    },
    async getGoodsCategoryList({ commit }, payload) {
      const res = await getGoodsCategoryList(payload)
      const list = handlleRecursionInfo(res.data.goodCata)
      commit('changeGoodsCategoryList', list)
    },
    async getGoodsAllCategoryList({ commit }, payload) {
      const res = await getGoodsCategoryList(payload)
      const list = handlleRecursionInfo(res.data.goodCata)
      commit('changeAllGoodsCategoryList', list)
    },
    async updateGoodsCategoryInfoAction({ commit }, payload) {
      const res = await updateGoodsCategoryInfo(payload)
      tips(res)
      emitter.emit('goodsCategoryUpdateNameChange')
    },
    async getGoodsTagAction({ commit }, payload) {
      const res = await getGoodsTagList(payload)
      commit('changeGoodsTagList', res.data.tagListByCata)
    },
    async addGoodsAcction({ commit }, payload) {
      let obj = { ...payload }

      let ruleForm = obj.ruleForm
      let formatForm = obj.formatForm
      let goodsImgList = obj.goodsImgList
      delete ruleForm.category
      const res = await addGoods(ruleForm)
      commit('changeNewAddGoodsId', res.data)
      Promise.all(
        goodsImgList.map(async (item) => {
          let shopImgParams = {
            picUrl: item,
            relateKind: 2,
            relateId: res.data
          }
          return await savePic(shopImgParams)
        })
      ).then((shopImgres) => {})

      Promise.all(
        formatForm.map(async (item) => {
          let formatParams = {
            companyId: item.companyId,
            paraName: item.paraName,
            paraDetail: item.paraDetail,
            salePrice: item.salePrice,
            promPrice: item.promPrice,
            goodId: res.data
          }
          return await saveGoodsFormat(formatParams)
        })
      ).then((formatRes) => {})

      tips(res)
      if (res.code == 0) {
        emitter.on('refreshGoodsListPage')
      }
    },
    async getGoodDetailAction({ commit }, payload) {
      const res = await getGoodsDetail(payload)
      let detail = res.data

      let paramsArr = []
      if (detail && detail.cataId != null && detail.subCataId != null) {
        paramsArr = [detail.cataId, detail.subCataId]
      } else if (detail && detail.cataId != null) {
        paramsArr = [detail.cataId]
      } else if (detail && detail.subCataId) {
        paramsArr = [detail.subCataId]
      }

      let categoryName = await getGoodsCategoryName(paramsArr.join())
      detail.categoryNameList = categoryName.data.list
      commit('changeUpdateGoods', res.data)
    },
    async getGoodsFormat({ commit }, payload) {
      const res = await getGoodsFormat(payload)
      commit('changeGoodsFormat', res.data.goodCata)
    },
    async getGoodsPicAction({ commit }, payload) {
      const res = await getGoodsPic(payload)
      commit('changeGoodsPic', res.data.goodCata)
    },
    async removeGoodsPic({ commit }, payload) {
      const res = await removeGoodsPic(payload)
      tips(res)
    },
    async updateGoodsAcction({ commit }, payload) {
      let obj = { ...payload }
      let ruleForm = obj.ruleForm
      let formatForm = obj.formatForm
      let goodId = obj.goodId
      commit('changeUpdateGoodsId', goodId)
      delete ruleForm.category
      const res = await updateGoodsInfo(ruleForm)

      formatForm.forEach((item) => {
        delete item.id
      })

      Promise.all(
        formatForm.map(async (item) => {
          let formatParams = {
            companyId: item.companyId,
            paraName: item.paraName,
            paraDetail: item.paraDetail,
            salePrice: item.salePrice,
            promPrice: item.promPrice,
            goodId
          }
          return await saveGoodsFormat(formatParams)
        })
      ).then((formatRes) => {})
      tips(res)
      emitter.emit('refreshGoodsUpdataPage')
    },
    async removeGoodsFormatAction({ commit }, payload) {
      const res = await removeGoodsFormat(payload)
      tips(res)
      emitter.emit('refreshGoodsUpdataPage')
    },
    async saveGoodsPicAction({ commit }, payload) {
      const res = await savePic(payload)
      tips(res)
    },
    async updateGoodsStateAction({ commit }, payload) {
      const res = await updateGoodsState(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshGoodsDrawerPage')
        emitter.emit('refreshGoodsListPage')
      }
    },
    async removeGoodsTagAction({ commit }, payload) {
      const tagList = [...payload]
      Promise.all(
        tagList.map(async (item) => {
          let obj = {
            companyId: item.companyId,
            tagId: item.tagId,
            tagName: item.tagName
          }
          return await removeGoodsTag(obj)
        })
      ).then((resList) => {
        const flag = resList.every((item) => item.code == 0)
        if (flag) {
          ElMessage({
            message: '删除成功!',
            type: 'success'
          })
        } else {
          ElMessage({
            message: '标签被占用，不得删除！',
            type: 'warning'
          })
        }
        emitter.emit('refreshTagListChange')
      })
    },
    async updateGoodsTagName({ commit }, payload) {
      const res = await updateTagName(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('updateTagNameChangeAction')
      }
    },
    async addGoodsShareinfoAction({ commit, state }, payload) {
      const obj = { ...payload }
      if (obj.isUpdate) {
        obj.goodId = state.updateGoodsId
      } else {
        obj.goodId = state.newAddGoodsId
      }

      const res = await setGoodsShareInfo(obj)
      tips(res)
      if (res.code == 0) {
        router.push('/main/goods/list')
        emitter.emit('emptyPageMdEditorValue')
      }
    },
    async setBatchGoodsStateAction({ commit }, payload) {
      const res = await setBatchGoodsState(payload)
      tips(res)
    },
    async setGoodsBatchSetStateAction({ commit }, payload) {
      const res = await setBatchGoodsState(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshGoodsListPage')
      }
    },
    async updateGoodsFormatAction({ commit }, payload) {
      const res = await updateGoodsFormat(payload)
      tips(res)
    },
  }
}

export default goodsModule
