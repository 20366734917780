import { createApp } from 'vue'
import App from './App.vue'
//按钮权限控制方法
import btnPerms from "@/utils/btnPerms";
import 'normalize.css'
import './assets/css/index.less'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElIcons from '@element-plus/icons'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import localCache from '@/utils/cache'
import { getUserResourceList,getUserButtonTagList } from "@/service/main/staff"
import { mapMenusToRoutes } from '@/utils/map-menus'

const token = localCache.getCache('token')

const app = createApp(App)
btnPerms(app)

const initRouter = () => {
  return new Promise((resolve, reject) => {
    const adminInfo = localCache.getCache('adminInfo')
    if (adminInfo && adminInfo.userId) {
      getUserResourceList(adminInfo.userId).then((v) => {
        const route = mapMenusToRoutes(v.data)
        route.forEach((route) => {
          router.addRoute('main', route)
        })
        resolve()
      })
    } else {
      resolve()
    }
  })
}
const initButton = async () => {
  const adminInfo = localCache.getCache('adminInfo')
  if (adminInfo && adminInfo.userId) {
   let res = await getUserButtonTagList()
   localCache.setCache('tagList', res.data)
  }
}

const boot = async () => {
  await initRouter()
  initButton()
  for (const name in ElIcons) {
    app.component(name, ElIcons[name])
  }
  app.use(store)
  app.use(router)
  app.use(ElementPlus, {
    locale: zhCn
  })
  app.use(mavonEditor)
  app.component('QuillEditor', QuillEditor)
  app.mount('#app')
}

boot()


