import { request4 } from '@/service/request4'
import cache from "@/utils/cache"

export function getRuleList(obj) {
  return request4({
    url: `/client/customer/cash-rule`,
    method: 'post',
    data: obj
  })
}

export function addRule(obj) {
  return request4({
    url: `/manager/card/new-cash-rule`,
    method: 'post',
    data: obj
  })
}

export function updateRule(obj) {
  return request4({
    url: `/manager/card/update-cash-rule`,
    method: 'post',
    data: obj
  })
}

export function removeRule(companyId, id) {
  return request4({
    url: `/manager/card/del-cash-rule/${companyId}/${id}`,
    method: 'post'
  })
}

export function setRechargeSellState(id, sale) {
  return request4({
    url: `/manager/card/update-cash-rule-sale-status/${id}/${sale}`,
    method: 'post'
  })
}

export function getShopList(companyId) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/permission/shop-basic/${companyId}/${cashUid}`,
    method: 'post'
  })
}

export function getStaffList(jobLevel, companyId) {
  return request4({
    url: `/manager/permission/get-emp-from-joblevel/${jobLevel}/${companyId}`,
    method: 'post'
  })
}

export function addDianzhangTarget(obj) {
  return request4({
    url: `/manager/admin/new-dz-aim`,
    method: 'post',
    data: obj
  })
}

export function addQuyuTarget(obj) {
  return request4({
    url: `/manager/admin/new-area-aim`,
    method: 'post',
    data: obj
  })
}

export function addQiantaiTarget(obj) {
  return request4({
    url: `/manager/admin/new-front-aim`,
    method: 'post',
    data: obj
  })
}

export function addTeacherTarget(obj) {
  return request4({
    url: `/manager/admin/new-liao-aim`,
    method: 'post',
    data: obj
  })
}

//获取发起的考核列表
export function getCheckList(obj) {
  return request4({
    url: `/check/web/getCheckList`,
    method: 'post',
    data: obj
  })
}
//获取门店下用户有的可用考核信息
export function getCheckUserList(obj) {
  return request4({
    url: `/check/web/getCheckUserByRole`,
    method: 'post',
    data: obj
  })
}
//发起考核
export function createCheck(obj) {
  return request4({
    url: `/check/web/createCheck`,
    method: 'post',
    data: obj
  })
}
//编辑考核
export function editCheck(obj) {
  return request4({
    url: `/check/web/editCheck`,
    method: 'put',
    data: obj
  })
}
//获取考核信息
export function getCheck(id) {
  return request4({
    url: `/check/web/getCheck?id=${id}`,
    method: 'get'
  })
}
//填写考核信息
export function fillInCheck(obj) {
  return request4({
    url: `/check/web/fillInCheck`,
    method: 'post',
    data: obj
  })
}

//获取考核列表
export function getCheckTableList(obj) {
  return request4({
    url: `/checkTable/web/getCheckTableList`,
    method: 'post',
    data: obj
  })
}
//创建考核表
export function createCheckTable(obj) {
  return request4({
    url: `/checkTable/web/createCheckTable`,
    method: 'post',
    data: obj
  })
}
//编辑考核表
export function editCheckTable(obj) {
  return request4({
    url: `/checkTable/web/editCheckTable`,
    method: 'PUT',
    data: obj
  })
}
//获取考核表
export function getCheckTable(id) {
  return request4({
    url: `/checkTable/web/getCheckTable?id=${id}`,
    method: 'get'
  })
}
//删除考核列表
export function deleteCheckTable(id) {
  return request4({
    url: `/checkTable/web/deleteCheckTable?id=${id}`,
    method: 'DELETE',
  })
}
//获取指标列表
export function getCheckIndicatorsList(obj) {
  return request4({
    url: `/checkIndicators/web/getCheckIndicatorsList`,
    method: 'post',
    data: obj
  })
}
//获取考核指标下拉列表
export function getCheckIndicatorsDownList(obj) {
  return request4({
    url: `/checkIndicators/web/getCheckIndicatorsDownList`,
    method: 'post',
    data: obj
  })
}
//删除考核指标
export function batchDelete(obj) {
  return request4({
    url: `/checkIndicators/web/batchDelete`,
    method: 'post',
    data: obj
  })
}
//添加考核指标
export function createCheckIndicators(obj) {
  return request4({
    url: `/checkIndicators/web/createCheckIndicators`,
    method: 'post',
    data: obj
  })
}
//获取考核指标
export function getCheckIndicators(id) {
  return request4({
    url: `/checkIndicators/web/getCheckIndicators?id=${id}`,
    method: 'get'
  })
}
//编辑考核指标
export function editCheckIndicators(obj) {
  return request4({
    url: `/checkIndicators/web/editCheckIndicators`,
    method: 'PUT',
    data: obj
  })
}
//获取系统指标字段信息
export function getSystemIndicators(roleId) {
  return request4({
    url: `/checkIndicators/web/getSystemIndicators?roleId=${roleId}`,
    method: 'get'
  })
}
//删除考核关联用户
export function deleteMpUser(data) {
  return request4({
    url: `/check/web/deleteMpUser`,
    method: 'post',
    data
  })
}

export function isTeacherTarget(userId,targetKind) {
  return request4({
    url: `/manager/money/has-sale-target/${userId}/${targetKind}`,
    method: 'post',
  })
}

export function getTargetDetail(userId,targetKind,targetYear) {
  return request4({
    url: `/manager/money/get-aims/${userId}/${targetKind}/${targetYear}`,
    method: 'post',
  })
}

export function updateTeacherTarget(obj) {
  return request4({
    url: `/manager/money/update-liliao-aim`,
    method: 'post',
    data:obj
  })
}

export function updateQiantaiTarget(obj) {
  return request4({
    url: `/manager/money/update-qt-aim`,
    method: 'post',
    data:obj
  })
}

export function updateDianzhangTarget(obj) {
  return request4({
    url: `/manager/money/update-dz-aim`,
    method: 'post',
    data:obj
  })
}

export function getTargetHUike(empId,appYear) {
  return request4({
    url: `/manager/money/count-point-customer/${empId}/${appYear}`,
    method: 'post',
  })
}

export function getTargetYouxiaokehu(empId,appYear) {
  return request4({
    url: `/manager/money/count-good-customer/${empId}/${appYear}`,
    method: 'post',
  })
}

export function getTargetXiaoshoue(empId,appYear) {
  return request4({
    url: `/manager/money/count-sale-qty/${empId}/${appYear}`,
    method: 'post',
  })
}

export function getTargetXukasxu(empId,appYear) {
  return request4({
    url: `/manager/money/count-buy-cards/${empId}/${appYear}`,
    method: 'post',
  })
}

export function getTargetXinkelv(empId,appYear) {
  return request4({
    url: `/manager/money/new-customer/${empId}/${appYear}`,
    method: 'post',
  })
}














