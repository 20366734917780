import { ElMessage } from 'element-plus'
import router from '@/router'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import { tips } from '@/utils/tips'
import { COMPANY_ID } from '@/common/constant'
import {
  getRuleList,
  addRule,
  updateRule,
  removeRule
} from '@/service/main/target'

const loginModule = {
  namespaced: true,
  state() {
    return {
      ruleList: []
    }
  },
  getters: {},
  mutations: {
    changeRuleList(state, list) {
      state.ruleList = list
    }
  },
  actions: {
    async getRuleListAction({ commit }, payload) {
      const res = await getRuleList({
        companyId: COMPANY_ID,
        sale: payload
      })

      commit('changeRuleList', res.data.list)
    },
    async addRuleAction({ commit }, payload) {
      const res = await addRule(payload)
      if (res.code == 0) {
        emitter.emit('changeTargetRechargeRuleList')
        tips(res)
      }
    },
    async updateRuleAction({ commit }, payload) {
      const res = await updateRule(payload)
      if (res.code == 0) {
        emitter.emit('changeTargetRechargeRuleList')
        tips(res)
      }
    },
    async removeRuleAction({ commit }, payload) {
      const res = await removeRule(...payload)
      if (res.code == 0) {
        emitter.emit('changeTargetRechargeRuleList')
        tips(res)
      }
    }
  }
}

export default loginModule
