import { request } from '../../request'
import { request3 } from '@/service/request3'
import { request4 } from '@/service/request4'
import cache from "@/utils/cache"

// export function addServe(obj) {
//   return request({
//     url: '/insert-serve',
//     method: 'post',
//     data: obj
//   })
// }
export function addServe(obj) {
  return request4({
    url: '/serve/web/createServe',
    method: 'post',
    data: obj
  })
}
export function editServe(obj) {
  return request4({
    url: '/serve/web/editServe',
    method: 'put',
    data: obj
  })
}

export function addServeFormat(obj) {
  return request({
    url: '/insert-serve-para',
    method: 'post',
    data: obj
  })
}

export function addServeCategory(obj) {
  return request({
    url: '/insert-serve-kind',
    method: 'post',
    data: obj
  })
}

export function getServeCategory(obj) {
  return request({
    url: '/serve-cat',
    method: 'post',
    data: obj
  })
}

export function removeServeCategory(obj) {
  return request({
    url: '/remove-serve-kind',
    method: 'post',
    data: obj
  })
}

export function updateServeCategoryName(obj) {
  return request({
    url: '/update-serve-kind-name',
    method: 'post',
    data: obj
  })
}

export function addServeTag(obj) {
  return request({
    url: '/insert-tag',
    method: 'post',
    data: obj
  })
}

export function getServeTag(obj) {
  return request({
    url: '/tag-list',
    method: 'post',
    data: obj
  })
}

export function removeServeTag(obj) {
  return request({
    url: '/remove-tag',
    method: 'post',
    data: obj
  })
}

export function savPic(obj) {
  return request({
    url: '/insert-shop-pic',
    method: 'post',
    data: obj
  })
}

// export function getServeList(obj) {
//   return request({
//     url: '/serve-list',
//     method: 'post',
//     data: obj
//   })
// }
export function getServeList(obj) {
  return request4({
    url: '/serve/web/getServeList',
    method: 'post',
    data: obj
  })
}

export function getServeDetail(obj) {
  return request({
    url: '/serve-detail',
    method: 'post',
    data: obj
  })
}

export function getServeDetailData(obj) {
  return request4({
    url: '/serve/web/getServe',
    method: 'get',
    params: obj
  })
}

export function getServePicList(obj) {
  return request({
    url: '/get-pic',
    method: 'post',
    data: obj
  })
}

export function getServeFormatList(serveId) {
  return request({
    url: `/serve-para/${serveId}`,
    method: 'post'
  })
}

export function removeServeFormatList(paraId, serveId) {
  return request({
    url: `remove-serve-para/${paraId}/${serveId}`,
    method: 'post'
  })
}

export function getServePic2(relateId) {
  return request({
    url: `/get-cover/${relateId}/1`,
    method: 'post'
  })
}

export function setServeStatus(status, serveId, companyId) {
  return request({
    url: `/update-serve-status/${status}/${serveId}/${companyId}`,
    method: 'post'
  })
}
export function enabledServe(id) {
  return request4({
    url: `/serve/web/enabledServe?id=${id}`,
    method: 'get'
  })
}

export function getShopList(obj) {
  let adminInfo = cache.getCache('adminInfo')
  let userId = adminInfo.userId
  return request({
    url: `/shop-list`,
    method: 'post',
    data: {...obj,userId}
  })
}

export function setShopServeBind(serveId, shopId) {
  return request({
    url: `/bind-serve-top-shop/${serveId}/${shopId}`,
    method: 'post'
  })
}

export function removeShopServeBind(serveId, shopId) {
  return request({
    url: `/update-shop-serve/${serveId}/${shopId}`,
    method: 'post'
  })
}

export function getShopServeBindList(serveID) {
  return request({
    url: `/serve-in-shop/${serveID}`,
    method: 'post'
  })
}

export function getRankList(obj) {
  return request3({
    url: `/job/level/list`,
    method: 'post',
    data: obj
  })
}

export function updateServeInfo(obj) {
  return request({
    url: `/update-serve-basic`,
    method: 'post',
    data: obj
  })
}

export function updateServeFormat(obj) {
  return request({
    url: `/update-serve-para`,
    method: 'post',
    data: obj
  })
}

export function getServeCategoryList(companyId) {
  return request4({
    url: `/manager/appointment/serve-cata/${companyId}`,
    method: 'post'
  })
}

export function setServeBatchSetState(obj) {
  return request({
    url: `/multi-update-serve-status`,
    method: 'post',
    data: obj
  })
}

export function addtServeShareInfo(obj) {
  return request({
    url: `/general-content-update`,
    method: 'post',
    data: obj
  })
}

export function saveServePic(obj) {
  return request({
    url: '/insert-shop-pic',
    method: 'post',
    data: obj
  })
}
//服务与门店绑定
export function batchBindShop(obj) {
  return request({
    url: '/multi-bind-serve-to-shop',
    method: 'post',
    data: obj
  })
}
//服务与门店解绑
export function batchBindoutShop(obj) {
  return request({
    url: '/multi-unbind-serve-to-shop',
    method: 'post',
    data: obj
  })
}

export function getServeList2(obj) {
  return request({
    url: '/select-serves',
    method: 'post',
    data: obj
  })
}
//服务分配门店
export function allocateServe(obj) {
  return request4({
    url: '/serve/web/allocateServe',
    method: 'post',
    data: obj
  })
}
//获取服务绑定信息列表
export function getServeBindList(obj) {
  return request4({
    url: '/serve/web/getServeBindList',
    method: 'post',
    data: obj
  })
}
//获取美团服务绑定列表
export function getTripartiteServeList(obj) {
  return request4({
    url: '/serve/web/getTripartiteServeList',
    method: 'post',
    data: obj
  })
}
//获取抖音服务绑定列表
export function getTiktokServeList(obj) {
  return request4({
    url: '/serve/web/getTiktokServeList',
    method: 'post',
    data: obj
  })
}
//绑定服务信息
export function bindServe(obj) {
  return request4({
    url: '/serve/web/bindServe',
    method: 'post',
    data: obj
  })
}
//解除绑定
export function unbindServe(id) {
  return request4({
    url: `/serve/web/unbindServe?id=${id}`,
    method: 'get'
  })
}
//批量解除绑定
export function bathUnbind(obj) {
  return request4({
    url: '/serve/web/bathUnbind',
    method: 'post',
    data: obj
  })
}
//单个绑定服务
export function signBindServe(obj) {
  return request4({
    url: '/serve/web/signBindServe',
    method: 'post',
    data: obj
  })
}
//获取消息通知列表
export function getAppointmentMsgList(obj) {
  return request4({
    url: '/appointment/web/getAppointmentMsgList',
    method: 'post',
    data: obj
  })
}
//全部已读
export function allRead() {
  return request4({
    url: '/appointment/web/allRead',
    method: 'get'
  })
}