import { getRuleList, getRoleList } from '@/service/main/commission'
import { COMPANY_ID } from '@/common/constant'

const commissionModule = {
  namespaced: true,
  state() {
    return {
      commissionList: [],
      commissionxiaohaoCount: 0,
      commissionxiaoshouCount: 0,
      roleList:[]
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changeCommissionList(state, list) {
      state.commissionList = list
    },
    changeCommissionxiaohaoCount(state, count) {
      state.commissionxiaohaoCount = count
    },
    changeCommissionxiaoshouCount(state, count) {
      state.commissionxiaoshouCount = count
    },
    changeRoleList(state,list){
      state.roleList=list
    }
  },
  actions: {
    async getCommissionListAction({ commit }, payload) {
      const res = await getRuleList(payload)
      let list = res.data.list
      let xiaohao = list.filter(
        (item) => item.ruleKind == 1 || item.ruleKind == 3
      )
      let xiaoshou = list.filter(
        (item) => item.ruleKind == 2 || item.ruleKind == 4
      )
      commit('changeCommissionxiaohaoCount', xiaohao.length)
      commit('changeCommissionxiaoshouCount', xiaoshou.length)
      commit('changeCommissionList', res.data.list)
    },
    async getRoleListAction({ commit }, payload) {
      const res = await getRoleList(COMPANY_ID)
      commit('changeRoleList', res.data.list)
    }
  }
}

export default commissionModule
