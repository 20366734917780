import { createRouter, createWebHashHistory } from 'vue-router'
import localCache from '@/utils/cache'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () =>
      import('@/views/login/cpns/forgot-password/forgot-password.vue')
  },
  {
    path: '/empower',
    name: 'empower',
    component: () => import('@/views/main/empower/index.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main/main.vue'),
    children: [
      {
        path: '/main/card/add',
        name: 'cardAdd',
        component: () => import('@/views/main/card/add/add.vue')
      },
      {
        path: '/main/card/update',
        name: 'cardUpdate',
        component: () => import('@/views/main/card/update/update.vue')
      },
      {
        path: '/main/shop/update',
        name: 'shopUpdate',
        component: () => import('@/views/main/shop/update/update.vue')
      },
      {
        path: '/main/shop/empower',
        name: 'shopEmpower',
        component: () => import('@/views/main/shop/list/empower.vue')
      },
      {
        path: '/main/shop/add',
        name: 'shopAdd',
        component: () => import('@/views/main/shop/add/add.vue')
      },
      {
        path: '/main/serve/add',
        name: 'serveAdd',
        component: () => import('@/views/main/serve/add/add.vue')
      },
      {
        path: '/main/serve/update',
        name: 'serveUpdate',
        component: () => import('@/views/main/serve/update/update.vue')
      },
      {
        path: '/main/staff/shiftSettings',
        name: 'shiftSettings',
        component: () =>
          import('@/views/main/staff/shift-settings/shift-settings.vue')
      },
      {
        path: '/main/staff/editStaffPage',
        name: 'editStaffPage',
        component: () =>
          import('@/views/main/staff/base-ui/edit-staff/edit-staff.vue')
      },
      {
        path: '/main/staff/staffCommission',
        name: 'staffCommission',
        component: () => import('@/views/main/staff/commission/commission.vue')
      },
      {
        path: '/main/staff/staffScheduling',
        name: 'staffStaffScheduling',
        component: () =>
          import('@/views/main/staff/staff-scheduling/staff-scheduling.vue')
      },

      {
        path: '/main/staff/rankManagement',
        name: 'rankManagement',
        component: () =>
          import('@/views/main/staff/rank-management/rank-management.vue')
      },
      {
        path: '/main/staff/roleManagement',
        name: 'roleManagement',
        component: () =>
          import('@/views/main/staff/role-management/role-management.vue')
      },
      {
        path: '/main/customer/detail',
        name: 'customerDetaill',
        component: () => import('@/views/main/customer/detail/detail.vue')
      },
      {
        path: '/main/reserve/editRule',
        name: 'reserveEditRule',
        component: () =>
          import('@/views/main/reserve/site/cpns/edit-rule/edit-rule.vue')
      },
      {
        path: '/main/reserve/addReserveSite',
        name: 'addReserveSite',
        component: () =>
          import('@/views/main/reserve/site/cpns/add-site/add-site.vue')
      },
      {
        path: '/main/reserve/reserveSiteDetail',
        name: 'reserveSiteDetail',
        component: () =>
          import('@/views/main/reserve/site/cpns/site-detail/site-detail.vue')
      },
      {
        path: '/main/reserve/teacherDetailCheck',
        name: 'teacherDetailCheck',
        component: () =>
          import(
            '@/views/main/reserve/teacher-detail-check/teacher-detail-check.vue'
          )
      },
      {
        path: '/main/commission/addConsumeRule',
        name: 'addConsumeRule',
        component: () =>
          import('@/views/main/commission/add/consume/consume.vue')
      },
      {
        path: '/main/commission/addSellRule',
        name: 'addSellRule',
        component: () => import('@/views/main/commission/add/sell/sell.vue')
      },
      {
        path: '/main/commissionSettings/detail',
        name: 'commissionSettingsDetail',
        component: () =>
          import('@/views/main/commission-settings/detail/detail.vue')
      },
      {
        path: '/main/order/openCard',
        name: 'orderOpenCard',
        component: () => import('@/views/main/order/open-card/open-card.vue')
      },
      {
        path: '/main/order/billing',
        name: 'orderBilling',
        component: () => import('@/views/main/order/billing/index.vue')
      },
      {
        path: '/main/order/orderPay',
        name: 'orderPay',
        component: () => import('@/views/main/order/pay/pay.vue')
      },
      {
        path: '/main/order/detail',
        name: 'orderDetail',
        component: () => import('@/views/main/order/detail/order-detail.vue')
      },
      {
        path: '/main/order/orderRefundDetail',
        name: 'orderRefundDetail',
        component: () =>
          import('@/views/main/order/refund-detail/refund-detail.vue')
      },
      {
        path: '/main/goods/update',
        name: 'goodsUpdate',
        component: () => import('@/views/main/goods/update/update.vue')
      },
      {
        path: '/main/goods/add',
        name: 'goodsAdd',
        component: () => import('@/views/main/goods/add/add.vue')
      },

      {
        path: '/main/commission/detail',
        name: 'commissionDetail',
        component: () => import('@/views/main/commission/detail/detail.vue')
      },
      {
        path: '/main/commission/update',
        name: 'commissionUpdate',
        component: () => import('@/views/main/commission/update/update.vue')
      },
      {
        path: '/main/target/addCategoryTarget',
        name: 'addCategoryTarget',
        component: () =>
          import(
            '@/views/main/target/category-tagetory/cpns/add-category-target/add-category-target.vue'
          )
      },
      {
        path: '/main/target/updateCategoryTarget',
        name: 'updateCategoryTarget',
        component: () =>
          import(
            '@/views/main/target/category-tagetory/cpns/update-category-target/update-category-target.vue'
          )
      },
      {
        path: '/main/staff/addStaffPage',
        name: 'addStaffPage',
        component: () =>
          import('@/views/main/staff/base-ui/add-staff/add-staff.vue')
      },
      {
        path: '/main/coupons/list',
        name: 'couponsList',
        component: () => import('@/views/main/cash-coupons/list/list.vue')
      },
      {
        path: '/main/coupons/add',
        name: 'addCoupons',
        component: () => import('@/views/main/cash-coupons/add/add.vue')
      },
      {
        path: '/main/coupons/update',
        name: 'updateCoupons',
        component: () => import('@/views/main/cash-coupons/update/update.vue')
      },
      {
        path: '/main/coupons/claimDetails',
        name: 'claimDetails',
        component: () => import('@/views/main/cash-coupons/claimDetails/list.vue')
      },
      {
        path: '/main/order/updateStaffScore',
        name: 'updateStaffScore',
        component: () =>
          import('@/views/main/order/update-staff-score/update-staff-score.vue')
      },
      {
        path: '/main/coupon/maketingAddCoupon',
        name: 'maketingAddCoupon',
        component: () => import('@/views/main/marketing/coupon/add/add.vue')
      },
      {
        path: '/main/maketing/serveBind',
        name: 'maketingServeBind',
        component: () => import('@/views/main/marketing/serve/bind-serve/index.vue')
      },
      {
        path: '/main/score/scoreAddrule',
        name: 'scoreAddrule',
        component: () => import('@/views/main/score/add-rule/add-rule.vue')
      },
      {
        path: '/main/score/scoreAddTicheng',
        name: 'scoreAddTicheng',
        component: () => import('@/views/main/score/commission/add/add.vue')
      },
      {
        path: '/main/score/scoreEditrule',
        name: 'scoreEditrule',
        component: () => import('@/views/main/score/edit-rule/edit-rule.vue')
      },
      {
        path: '/main/score/scoreEditTicheng',
        name: 'scoreEditTicheng',
        component: () => import('@/views/main/score/commission/edit/edit.vue')
      },
      {
        path: '/main/invite/list',
        name: 'inviteList',
        component: () => import('@/views/main/marketing/invite/list.vue')
      },
      {
        path: '/main/family/list',
        name: 'familyList',
        component: () => import('@/views/main/marketing/family/index.vue')
      },
      {
        path: '/main/maketing/addMaketing',
        name: 'AddMaketing',
        component: () => import('@/views/main/marketing/invite/addMaketing.vue')
      },
      {
        path: '/main/maketing/editMaketing',
        name: 'EditMaketing',
        component: () => import('@/views/main/marketing/invite/addMaketing.vue')
      },
      {
        path: '/main/bindTiktok/list',
        name: 'bindTiktokList',
        component: () => import('@/views/main/bindTiktok/list/index.vue')
      },
      {
        path: '/main/bindshop/list',
        name: 'bindShopList',
        component: () => import('@/views/main/bindshop/list/index.vue')
      },
      {
        path: '/main/coupons/list',
        name: 'couponsList',
        component: () => import('@/views/main/cash-coupons/list/list.vue')
      },
      {
        path: '/main/coupon/maketingCouponList',
        name: 'maketingCouponList',
        component: () => import('@/views/main/marketing/coupon/list/list.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/login' || to.path == '/forgotPassword'||to.path == '/empower') {
    next()
  } else {
    // const token = localCache.getCache('token')
    const adminInfo = localCache.getCache('adminInfo')
    if (adminInfo && adminInfo.userId) {
      next();
    } else {
      next({ path: '/login' })
    }
  }
})

export default router
