import {
  // addServe,
  addServeFormat,
  addServeCategory,
  getServeCategory,
  removeServeCategory,
  updateServeCategoryName,
  addServeTag,
  getServeTag,
  removeServeTag,
  savPic,
  getServeList,
  getServeDetail,
  getServeDetailData,
  getServePicList,
  getServeFormatList,
  removeServeFormatList,
  getServePic2,
  setServeStatus,
  getShopList,
  setShopServeBind,
  removeShopServeBind,
  getShopServeBindList,
  getRankList,
  updateServeInfo,
  updateServeFormat,
  getServeCategoryList,
  setServeBatchSetState,
  addtServeShareInfo,
  getStaffServeBindList
} from '@/service/main/serve'
import { updateTagName } from '@/service/main/public'
import { tips } from '@/utils/tips'
import emitter from '@/utils/eventbus'
import router from '@/router'
import { ElMessage } from 'element-plus'

const serveModule = {
  namespaced: true,
  state() {
    return {
      serveList: [],
      serveCount: 0,
      serveCategoryList: [],
      serveTagList: [],
      serveDetail: '',
      serveDetailData: '',
      serveDetailPicList: [],
      serveDetailFormatList: [],
      shopList: [],
      shopCount: 0,
      shopServeBindList: [],
      rankList: [],
      serveCategoryInfoList: [],
      newAddServeId: '',
      updateShopId: ''
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changeServeCategoryList(state, list) {
      state.serveCategoryList = list
    },
    changeServeTagList(state, list) {
      state.serveTagList = list
    },
    changeServeList(state, list) {
      state.serveList = list
    },
    changeServeCount(state, num) {
      state.serveCount = num
    },
    changeServeDetail(state, info) {
      state.serveDetail = info
    },
    changeServeDetailData(state, info) {
      state.serveDetailData = info
    },
    changeServePicList(state, list) {
      state.serveDetailPicList = list
    },
    changeServeFormat(state, list) {
      state.serveDetailFormatList = list
    },
    changeShopList(state, list) {
      state.shopList = list
    },
    changeShopCount(state, count) {
      state.shopCount = count
    },
    changeShopServeBind(state, list) {
      state.shopServeBindList = list
    },
    changeRankList(state, list) {
      state.rankList = list
    },
    changeServeCategoryInfoList(state, list) {
      state.serveCategoryInfoList = list
    },
    changeNewAddServeId(state, info) {
      state.newAddServeId = info
    },
    changeUpdateShopId(state, info) {
      state.updateShopId = info
    },
    emptyServeDetailMutations(state) {
      // console.log('清楚')
      state.serveDetailPicList = []
      state.serveDetail = ''
    }
  },
  actions: {
    async getserveListAction({ commit }, payload) {
      let obj = {
        companyId: payload.companyId,
        page: payload.page,
        pagesize: payload.pagesize,
        servestatus: payload.servestatus,
        keyword: payload.keyword,
        kindid: payload.kindid
      }
      const res = await getServeList(obj)
      let list = res.data.list
      const sortId = (a, b) => {
        return a.serveStatus - b.serveStatus
      }
      list.sort(sortId)

      commit('changeServeList', list)
      commit('changeServeCount', Number(res.data.total))
      // Promise.all(
      //   list.map(async (item) => {
      //     return await getServePic2(item.serveId)
      //   })
      // ).then((shopImgres) => {
      //   shopImgres.forEach((item, index) => {
      //     list[index].pic = item.data
      //     list[index].paraIdList = []
      //   })

      //   commit('changeServeList', list)
      //   commit('changeServeCount', res.data.total)
      // })
    },
    // async addServeAction({ commit }, payload) {
    //   let ruleForm = payload.addForm
    //   let imgList = payload.serverImgList
    //   let formatFormList = payload.formatForm
    //   ruleForm.serveTags = ruleForm.serveTags.join(',')
    //   const res = await addServe(ruleForm)
    //   if (res.code == 99) {
    //     ElMessage({
    //       message: res.msg,
    //       type: 'warning'
    //     })
    //     return
    //   }

    //   emitter.emit('addServeNextChange', res.data)
    //   // 发射编辑栏触发焦点
    //   setTimeout(() => {
    //     emitter.emit('changeCurrentMdEditOnFocus')
    //   }, 1000)

    //   commit('changeNewAddServeId', res.data)
    //   Promise.all(
    //     imgList.map(async (item) => {
    //       let shopImgParams = {
    //         picUrl: item,
    //         relateKind: 1,
    //         relateId: res.data
    //       }
    //       return await savPic(shopImgParams)
    //     })
    //   ).then((shopImgres) => {})

    //   Promise.all(
    //     formatFormList.map(async (item) => {
    //       let formatParams = {
    //         serveId: res.data,
    //         jobLvId: item.jobLvId,
    //         salePrice: item.salePrice,
    //         promPrice: item.promPrice,
    //         serveTimeLength: item.serveTimeLength,
    //         paraText: item.paraText
    //       }
    //       return await addServeFormat(formatParams)
    //     })
    //   ).then((formatRes) => {})

    //   tips(res)
    //   if (res.code == 0) {
    //     emitter.emit('refreshServeListPageContent')
    //   }
    // },
    async addServeCategoryAction({ commit }, payload) {
      const res = await addServeCategory(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshServeTagPage')
      }
    },
    async getServeCategoryList({ commit }, payload) {
      const res = await getServeCategory(payload)
      commit('changeServeCategoryList', res.data.serveCata)
    },
    async removeServeCategoryInfoAction({ commit }, payload) {
      const res = await removeServeCategory(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshServeTagPage')
      }
    },
    async updateServeCategoryInfoAction({ commit }, payload) {
      const res = await updateServeCategoryName(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshServeTagPage')
      }
    },
    async addTagAction({ commit }, payload) {
      const res = await addServeTag(payload)

      if (res.code == 0) {
        ElMessage({
          message: '添加成功!',
          type: 'success'
        })
        emitter.emit('refreshServeTagInfoPage')
      } else if (res.code == 1) {
        ElMessage({
          message: '标签名重复!',
          type: 'warning'
        })
      }
    },
    async getServeTagAction({ commit }, payload) {
      const res = await getServeTag(payload)
      commit('changeServeTagList', res.data.tagListByCata)
    },
    async removeServeTagAction({ commit }, payload) {
      const tagList = [...payload]
      Promise.all(
        tagList.map(async (item) => {
          let obj = {
            companyId: item.companyId,
            tagId: item.tagId,
            tagName: item.tagName
          }
          return await removeServeTag(obj)
        })
      ).then((resList) => {
        const flag = resList.every((item) => item.code == 0)

        if (flag) {
          ElMessage({
            message: '删除成功!',
            type: 'success'
          })
          emitter.emit('refreshServeTagInfoPage')
        } else {
          ElMessage({
            message: '标签被占用，不得删除!',
            type: 'warning'
          })
        }
      })
    },
    async getServeDetailAction({ commit }, payload) {
      const res = await getServeDetail(payload)
      commit('changeServeDetail', res.data)
    },
    async getServePicAction({ commit }, payload) {
      const res = await getServePicList(payload)
      commit('changeServePicList', res.data.goodCata)
    },
    async getServeDetailData({ commit }, payload) {
      const res = await getServeDetailData(payload)
      res.data.picture=[res.data.picture]
      commit('changeServeDetailData', res.data)
    },
    async getServeFormatAction({ commit }, payload) {
      const res = await getServeFormatList(payload)
      commit('changeServeFormat', res.data.list)
    },
    async removeServeFormatAction({ commit }, payload) {
      const res = await removeServeFormatList(payload.paraId, payload.serveId)
      tips(res)
    },
    async setServeStatusAction({ commit }, payload) {
      const res = await setServeStatus(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshServeListPageContent')
        emitter.emit('refreshServeEditDrawPage')
      }
    },
    async getshopListAction({ commit }, payload) {
      const res = await getShopList(payload)
      if (res.code==500) {
        ElMessage({
          message: res.msg,
          type: 'error'
        })
        return
      }
      let list = res.data.allShops
      list = list.filter((ele) => ele.runningStatus == 1)

      commit('changeShopCount', res.data.total)
      commit('changeShopList', list)
    },
    async setShopServeBindAction({ commit }, payload) {
      const res = await setShopServeBind(payload.serveId, payload.shopId)
      tips(res)
    },
    async removeShopServeBindAction({ commit }, payload) {
      const res = await removeShopServeBind(payload.serveId, payload.shopId)
      tips(res)
    },
    async getServeShopBindListAction({ commit }, payload) {
      const res = await getShopServeBindList(payload)
      const list = res.data.list
      let shopIdList = []
      list.forEach((item) => {
        shopIdList.push(item.shopId)
      })
      commit('changeShopServeBind', shopIdList)
    },
    async getRankListAction({ commit }, payload) {
      const res = await getRankList(payload)
      commit('changeRankList', res.data.list)
    },
    async updateGoodsTagName({ commit }, payload) {
      const res = await updateTagName(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('updateAddServeTagNameChangeAction')
      }
    },
    async updateServeAction({ commit, state }, payload) {
      let obj = { ...payload }
      let ruleForm = obj.ruleForm
      ruleForm.serveTags = ruleForm.serveTags.join()
      let formatForm = obj.formatForm
      // let serveImgList = obj.serveImgList
      commit('changeUpdateShopId', ruleForm.serveId)
      const newFormatList = formatForm.filter((item) =>
        item.hasOwnProperty('paraId') ? false : true
      )

      Promise.all(
        newFormatList.map(async (item) => {
          let formatParams = {
            serveId: ruleForm.serveId,
            jobLvId: item.jobLvId,
            salePrice: item.salePrice,
            promPrice: item.promPrice,
            serveTimeLength: item.serveTimeLength
          }
          return await addServeFormat(formatParams)
        })
      ).then((formatRes) => {})
      const res = await updateServeInfo(ruleForm)
      tips(res)
    },
    async updateServeFormat({ commit }, payload) {
      const obj = { ...payload }
      delete obj.isUpdate
      const res = await updateServeFormat(obj)
      tips(res)
    },
    async getServeCategoryListAction({ commit }, payload) {
      const res = await getServeCategoryList(payload)
      commit('changeServeCategoryInfoList', res.data.list)
    },
    async setServeBatchSetStateAction({ commit }, payload) {
      const res = await setServeBatchSetState(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshServeListPageContent')
      }
    },
    async addtServeShareInfoAction({ commit, state }, payload) {
      let obj = { ...payload }
      if (obj.isUpdate) {
        obj.serveId = state.updateShopId
      } else {
        obj.serveId = state.newAddServeId
      }

      const res = await addtServeShareInfo(obj)
      tips(res)
      if (res.code == 0) {
        router.push('/main/serve/list')
      }
    }
  }
}

export default serveModule
