import { request3 } from '@/service/request3'
import { request4 } from '@/service/request4'

export function addCard(obj) {
  return request3({
    url: '/card/new-card',
    method: 'post',
    data: obj
  })
}

export function cardServeBind(obj) {
  return request3({
    url: '/card/serve-in-card',
    method: 'post',
    data: obj
  })
}

export function cardGiveawayBind(obj) {
  return request3({
    url: '/card/gift-bind-card',
    method: 'post',
    data: obj
  })
}

export function getCardList(obj) {
  return request3({
    url: '/card/card-item',
    method: 'post',
    data: obj
  })
}

//获取后台卡项列表
export function getAllCardList(obj) {
  return request4({
    url: '/cardItem/web/getCardList',
    method: 'post',
    data: obj
  })
}

export function setShopCardBind(cardId, shopId, companyId) {
  return request3({
    url: `/card/deal-card-shop/${cardId}/${shopId}/${companyId}`,
    method: 'post'
  })
}

export function getShopCardBindList(cardId) {
  return request3({
    url: `/card/card-in-shop/${cardId}`,
    method: 'post'
  })
}

export function setCardShareInfo(obj) {
  return request3({
    url: `/card/update-card-info`,
    method: 'post',
    data: obj
  })
}

export function getCardDetail(obj) {
  return request3({
    url: `/card/card-detail`,
    method: 'post',
    data: obj
  })
}

export function getCardServeList(cardId, companyId) {
  return request3({
    url: `/card/serve-in-card/${cardId}/${companyId}`,
    method: 'post'
  })
}

export function getCardHandselList(cardId, compaId) {
  return request3({
    url: `/card/card-gift/${cardId}/${compaId}`,
    method: 'post'
  })
}

export function removeCardServeBind(serveId, cardId, companyId, paraId) {
  return request3({
    url: `/card/remove-card-serve/${serveId}/${cardId}/${companyId}/${paraId}`,
    method: 'post'
  })
}

export function removeCardHandselBind(cardId, giftKind, relateId, paraId) {
  return request3({
    url: `/card/remove-card-gift/${cardId}/${giftKind}/${relateId}/${paraId}`,
    method: 'post'
  })
}

export function updateCardInfo(obj) {
  return request3({
    url: `/card/update-card-basic`,
    method: 'post',
    data: obj
  })
}

export function updateCardServeInfo(obj) {
  return request3({
    url: `/card/update-card-serve`,
    method: 'post',
    data: obj
  })
}

export function updateCardHandselInfo(obj) {
  return request3({
    url: `/card/update-card-gift`,
    method: 'post',
    data: obj
  })
}

export function getCardCategoryList(companyId) {
  return request3({
    url: `/card/cardkind/${companyId}`,
    method: 'post'
  })
}

export function updateCardState(cardId, companyId, status) {
  return request3({
    url: `/card/update-card-status/${cardId}/${companyId}/${status}`,
    method: 'post'
  })
}

export function getCardCategory(companyId) {
  return request3({
    url: `/card/cardkind/${companyId}`,
    method: 'post'
  })
}

export function batchSetCardState(obj) {
  return request3({
    url: `/card/multi-update-card-status`,
    method: 'post',
    data: obj
  })
}

export function saveShareDescInfo(obj) {
  return request3({
    url: `/card/general-get-content`,
    method: 'post',
    data: obj
  })
}

export function saveCardShareInfo(obj) {
  return request4({
    url: `/manager/card/update-card-info`,
    method: 'post',
    data: obj
  })
}

export function getCardBuyShopNum(cardid) {
  return request4({
    url: `/manager/order/count-card-in-shop/${cardid}`,
    method: 'post'
  })
}

export function getCardBuyNum(cardid,kind) {
  return request4({
    url: `/manager/order/count-card-sale-qty/${cardid}/${kind}`,
    method: 'post'
  })
}

export function getCardList2(obj) {
  return request4({
    url: '/manager/order/card-in-cash',
    method: 'post',
    data: obj
  })
}

export function getRechargeRuleList2(obj) {
  return request4({
    url: `/manager/card/cash-rule`,
    method: 'post',
    data: obj
  })
}

export function getUserBalance2(userId) {
  return request4({
    url: `/manager/customer/new-customer-cash/${userId}`,
    method: 'post'
  })
}

export function getShopTeacher2(shopId) {
  return request4({
    url: `/manager/card/shop-emp-list/${shopId}`,
    method: 'post'
  })
}

export function isNewUser2(userId) {
  return request4({
    url: `/manager/customer/is-new-user/${userId}`,
    method: 'post'
  })
}

export function addOrder2(obj) {
  return request4({
    url: `/manager/order/back-end-make-order`,
    method: 'post',
    data: obj
  })
}

export function saveOrder2(obj) {
  return request4({
    url: `/manager/order/back-end-order-items`,
    method: 'post',
    data: obj
  })
}

export function userBalancePay2(obj) {
  return request4({
    url: `/manager/card/pay-by-cash`,
    method: 'post',
    data: obj
  })
}
