import { getShopTime } from '@/service/main/reserve'
import { ref } from 'vue'
// export const reserveTimeList = [
//   {
//     time: '07:00'
//   },
//   {
//     time: '07:10'
//   },
//   {
//     time: '07:20'
//   },
//   {
//     time: '07:30'
//   },
//   {
//     time: '07:40'
//   },
//   {
//     time: '07:50'
//   },
//   {
//     time: '08:00'
//   },
//   {
//     time: '08:10'
//   },
//   {
//     time: '08:20'
//   },
//   {
//     time: '08:30'
//   },
//   {
//     time: '08:40'
//   },
//   {
//     time: '08:50'
//   },
//   {
//     time: '09:00'
//   },
//   {
//     time: '09:10'
//   },
//   {
//     time: '09:20'
//   },
//   {
//     time: '09:30'
//   },
//   {
//     time: '09:40'
//   },
//   {
//     time: '09:50'
//   },
//   {
//     time: '10:00'
//   },
//   {
//     time: '10:10'
//   },
//   {
//     time: '10:20'
//   },
//   {
//     time: '10:30'
//   },
//   {
//     time: '10:40'
//   },
//   {
//     time: '10:50'
//   },
//   {
//     time: '11:00'
//   },
//   {
//     time: '11:10'
//   },
//   {
//     time: '11:20'
//   },
//   {
//     time: '11:30'
//   },
//   {
//     time: '11:40'
//   },
//   {
//     time: '11:50'
//   },
//   {
//     time: '12:00'
//   },
//   {
//     time: '12:10'
//   },
//   {
//     time: '12:20'
//   },
//   {
//     time: '12:30'
//   },
//   {
//     time: '12:40'
//   },
//   {
//     time: '12:50'
//   },
//   {
//     time: '13:00'
//   },
//   {
//     time: '13:10'
//   },
//   {
//     time: '13:20'
//   },
//   {
//     time: '13:30'
//   },
//   {
//     time: '13:40'
//   },
//   {
//     time: '13:50'
//   },
//   {
//     time: '14:00'
//   },
//   {
//     time: '14:10'
//   },
//   {
//     time: '14:20'
//   },
//   {
//     time: '14:30'
//   },
//   {
//     time: '14:40'
//   },
//   {
//     time: '14:50'
//   },
//   {
//     time: '15:00'
//   },
//   {
//     time: '15:10'
//   },
//   {
//     time: '15:20'
//   },
//   {
//     time: '15:30'
//   },
//   {
//     time: '15:40'
//   },
//   {
//     time: '15:50'
//   },
//   {
//     time: '16:00'
//   },
//   {
//     time: '16:10'
//   },
//   {
//     time: '16:20'
//   },
//   {
//     time: '16:30'
//   },
//   {
//     time: '16:40'
//   },
//   {
//     time: '16:50'
//   },
//   {
//     time: '17:00'
//   },
//   {
//     time: '17:10'
//   },
//   {
//     time: '17:20'
//   },
//   {
//     time: '17:30'
//   },
//   {
//     time: '17:40'
//   },
//   {
//     time: '17:50'
//   },
//   {
//     time: '18:00'
//   },
//   {
//     time: '18:10'
//   },
//   {
//     time: '18:20'
//   },
//   {
//     time: '18:30'
//   },
//   {
//     time: '18:40'
//   },
//   {
//     time: '18:50'
//   },
//   {
//     time: '19:00'
//   },
//   {
//     time: '19:10'
//   },
//   {
//     time: '19:20'
//   },
//   {
//     time: '19:30'
//   },
//   {
//     time: '19:40'
//   },
//   {
//     time: '19:50'
//   },
//   {
//     time: '20:00'
//   },
//   {
//     time: '20:10'
//   },
//   {
//     time: '20:20'
//   },

//   {
//     time: '20:30'
//   },

//   {
//     time: '20:40'
//   },
//   {
//     time: '20:50'
//   },
//   {
//     time: '21:00'
//   },
//   {
//     time: '21:10'
//   },
//   {
//     time: '21:20'
//   },
//   {
//     time: '21:30'
//   },
//   {
//     time: '21:40'
//   },
//   {
//     time: '21:50'
//   },
//   {
//     time: '22:00'
//   },
//   {
//     time: '22:10'
//   },
//   {
//     time: '22:20'
//   },
//   {
//     time: '22:30'
//   },
//   {
//     time: '22:40'
//   },
//   {
//     time: '22:50'
//   },
//   {
//     time: '23:00'
//   },
//   {
//     time: '23:10'
//   },
//   {
//     time: '23:20'
//   },
//   {
//     time: '23:30'
//   },
//   {
//     time: '23:40'
//   },
//   {
//     time: '23:50'
//   },
//   {
//     time: '24:00'
//   }
// ]


export const useShopTime = (id) => {
  const reserveTimeList = ref([])
  const reserveTimeList_30 = ref([])
  const reserveTimeList_60 = ref([])
  getShopTime(id).then((res) => {
    reserveTimeList.value = res.data
    reserveTimeList_30.value = res.data,
      reserveTimeList_60.value = res.data
  });
  return { reserveTimeList, reserveTimeList_30, reserveTimeList_60 }
}


// export const reserveTimeList_30 = [
//   {
//     time: '07:00'
//   },
//   {
//     time: '07:30'
//   },
//   {
//     time: '08:00'
//   },
//   {
//     time: '08:30'
//   },
//   {
//     time: '09:00'
//   },
//   {
//     time: '09:30'
//   },
//   {
//     time: '10:00'
//   },
//   {
//     time: '10:30'
//   },
//   {
//     time: '11:00'
//   },
//   {
//     time: '11:30'
//   },
//   {
//     time: '12:00'
//   },
//   {
//     time: '12:30'
//   },
//   {
//     time: '13:00'
//   },

//   {
//     time: '13:30'
//   },

//   {
//     time: '14:00'
//   },
//   {
//     time: '14:30'
//   },
//   {
//     time: '15:00'
//   },
//   {
//     time: '15:30'
//   },
//   {
//     time: '16:00'
//   },
//   {
//     time: '16:30'
//   },
//   {
//     time: '17:00'
//   },
//   {
//     time: '17:30'
//   },
//   {
//     time: '18:00'
//   },
//   {
//     time: '18:30'
//   },
//   {
//     time: '19:00'
//   },
//   {
//     time: '19:30'
//   },
//   {
//     time: '20:00'
//   },
//   {
//     time: '20:30'
//   },
//   {
//     time: '21:00'
//   },
//   {
//     time: '21:30'
//   },
//   {
//     time: '22:00'
//   },
//   {
//     time: '22:30'
//   },
//   {
//     time: '23:00'
//   },
//   {
//     time: '23:30'
//   },
//   {
//     time: '24:00'
//   }
// ]

// export const reserveTimeList_60 = [
//   {
//     time: '07:00'
//   },
//   {
//     time: '08:00'
//   },
//   {
//     time: '09:00'
//   },
//   {
//     time: '10:00'
//   },
//   {
//     time: '11:00'
//   },
//   {
//     time: '12:00'
//   },
//   {
//     time: '13:00'
//   },
//   {
//     time: '14:00'
//   },
//   {
//     time: '15:00'
//   },
//   {
//     time: '16:00'
//   },
//   {
//     time: '17:00'
//   },
//   {
//     time: '18:00'
//   },
//   {
//     time: '19:00'
//   },
//   {
//     time: '20:00'
//   },
//   {
//     time: '21:00'
//   },
//   {
//     time: '22:00'
//   },
//   {
//     time: '23:00'
//   },
//   {
//     time: '24:00'
//   }
// ]

export const roomTimeList = [
  // {
  //   time: '07:00'
  // },
  // {
  //   time: '07:10'
  // },
  // {
  //   time: '07:20'
  // },
  // {
  //   time: '07:30'
  // },
  // {
  //   time: '07:40'
  // },
  // {
  //   time: '07:50'
  // },
  // {
  //   time: '08:00'
  // },
  // {
  //   time: '08:10'
  // },
  // {
  //   time: '08:20'
  // },
  // {
  //   time: '08:30'
  // },
  // {
  //   time: '08:40'
  // },
  // {
  //   time: '08:50'
  // },
  // {
  //   time: '09:00'
  // },
  // {
  //   time: '09:10'
  // },
  // {
  //   time: '09:20'
  // },
  // {
  //   time: '09:30'
  // },
  // {
  //   time: '09:40'
  // },
  // {
  //   time: '09:50'
  // },
  {
    time: '10:00'
  },
  {
    time: '10:10'
  },
  {
    time: '10:20'
  },
  {
    time: '10:30'
  },
  {
    time: '10:40'
  },
  {
    time: '10:50'
  },
  {
    time: '11:00'
  },
  {
    time: '11:10'
  },
  {
    time: '11:20'
  },
  {
    time: '11:30'
  },
  {
    time: '11:40'
  },
  {
    time: '11:50'
  },
  {
    time: '12:00'
  },
  {
    time: '12:10'
  },
  {
    time: '12:20'
  },
  {
    time: '12:30'
  },
  {
    time: '12:40'
  },
  {
    time: '12:50'
  },
  {
    time: '13:00'
  },
  {
    time: '13:10'
  },
  {
    time: '13:20'
  },
  {
    time: '13:30'
  },
  {
    time: '13:40'
  },
  {
    time: '13:50'
  },
  {
    time: '14:00'
  },
  {
    time: '14:10'
  },
  {
    time: '14:20'
  },
  {
    time: '14:30'
  },
  {
    time: '14:40'
  },
  {
    time: '14:50'
  },
  {
    time: '15:00'
  },
  {
    time: '15:10'
  },
  {
    time: '15:20'
  },
  {
    time: '15:30'
  },
  {
    time: '15:40'
  },
  {
    time: '15:50'
  },
  {
    time: '16:00'
  },
  {
    time: '16:10'
  },
  {
    time: '16:20'
  },
  {
    time: '16:30'
  },
  {
    time: '16:40'
  },
  {
    time: '16:50'
  },
  {
    time: '17:00'
  },
  {
    time: '17:10'
  },
  {
    time: '17:20'
  },
  {
    time: '17:30'
  },
  {
    time: '17:40'
  },
  {
    time: '17:50'
  },
  {
    time: '18:00'
  },
  {
    time: '18:10'
  },
  {
    time: '18:20'
  },
  {
    time: '18:30'
  },
  {
    time: '18:40'
  },
  {
    time: '18:50'
  },
  {
    time: '19:00'
  },
  {
    time: '19:10'
  },
  {
    time: '19:20'
  },
  {
    time: '19:30'
  },
  {
    time: '19:40'
  },
  {
    time: '19:50'
  },
  {
    time: '20:00'
  },
  {
    time: '20:10'
  },
  {
    time: '20:20'
  },

  {
    time: '20:30'
  },

  {
    time: '20:40'
  },
  {
    time: '20:50'
  },
  {
    time: '21:00'
  },
  {
    time: '21:10'
  },
  {
    time: '21:20'
  },
  {
    time: '21:30'
  },
  {
    time: '21:40'
  },
  {
    time: '21:50'
  },
  {
    time: '22:00'
  },
  {
    time: '22:10'
  },
  {
    time: '22:20'
  },
  {
    time: '22:30'
  },
  {
    time: '22:40'
  },
  {
    time: '22:50'
  },
  {
    time: '23:00'
  },
  {
    time: '23:10'
  },
  {
    time: '23:20'
  },
  {
    time: '23:30'
  },
  {
    time: '23:40'
  },
  {
    time: '23:50'
  },
  {
    time: '24:00'
  }
]

export const timeGapOption = [
  {
    label: '10分钟',
    value: '10'
  },
  {
    label: '30分钟',
    value: '30'
  },
  {
    label: '60分钟',
    value: '60'
  }
]
