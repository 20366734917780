import dayjs from 'dayjs'
import Calendar from 'dayjs/plugin/calendar'
dayjs.extend(Calendar)

export function getCalendar(currentDate) {
  // console.log(currentDate);
  const date = new Date(currentDate)
  let ym=dayjs(currentDate).format('YYYY-MM')
  let week = dayjs(ym+'-01').day()
  // console.log(week)
  const totalDay = dayjs(currentDate).daysInMonth()
  const dateArr = []
  for (let i = 1; i <= totalDay; i++) {
    let day = {}
    let currentWeek
    switch (week) {
      case 0:
        currentWeek = '天'
        break
      case 1:
        currentWeek = '一'
        break
      case 2:
        currentWeek = '二'
        break
      case 3:
        currentWeek = '三'
        break
      case 4:
        currentWeek = '四'
        break
      case 5:
        currentWeek = '五'
        break
      case 6:
        currentWeek = '六'
        break
    }
    week++
    if (week > 6) {
      week = 0
    }
    dateArr.push({
      week: currentWeek,
      day: i
    })
  }
  return dateArr
}

export function getDateData(d, dayCount) {
  d.setDate(d.getDate() + dayCount)
  var y = d.getFullYear()
  var m = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
  var day = d.getDate() > 9 ? d.getDate() : '0' + d.getDate()
  return y + '-' + m + '-' + day
}
