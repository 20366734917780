import { request4 } from '@/service/request4'

export function getRuleList(companyId) {
  return request4({
    url: `/manager/admin/archieve-rule/${companyId}`,
    method: 'post'
  })
}

export function getRoleList(companyId) {
  return request4({
    url: `/manager/permission/simple-get-role/${companyId}`,
    method: 'post'
  })
}

export function getServeCategoryList(obj) {
  return request4({
    url: `/manager/admin/serve-cat`,
    method: 'post',
    data: obj
  })
}

export function getServeList(obj) {
  return request4({
    url: '/manager/admin/serve-list',
    method: 'post',
    data: obj
  })
}

export function addRule(obj) {
  return request4({
    url: '/manager/admin/new-archieve-rule',
    method: 'post',
    data: obj
  })
}

export function addRuleSaveRule(obj) {
  return request4({
    url: '/manager/admin/bind-serve-to-arch-rule',
    method: 'post',
    data: obj
  })
}

export function addRuleSaveJieDuanRule(obj) {
  return request4({
    url: '/manager/admin/new-arch-range-goal',
    method: 'post',
    data: obj
  })
}

export function getCardList(obj) {
  return request4({
    url: '/manager/card/card-item',
    method: 'post',
    data: obj
  })
}

export function addRuleSaveCardRule(obj) {
  return request4({
    url: '/manager/admin/bind-card-to-arch-rule',
    method: 'post',
    data: obj
  })
}

export function getRuleDetail(companyId, ruleId) {
  return request4({
    url: `/manager/admin/get-arch-rule-detail/${companyId}/${ruleId}`,
    method: 'post'
  })
}

export function updateRule(obj) {
  return request4({
    url: `/manager/admin/update-arch-rule`,
    method: 'post',
    data: obj
  })
}

export function updateRuleServe(obj) {
  return request4({
    url: `/manager/admin/update-arch-serves`,
    method: 'post',
    data: obj
  })
}

export function updateRuleJieDuan(obj) {
  return request4({
    url: `/manager/admin/update-arch-rule-range`,
    method: 'post',
    data: obj
  })
}

export function setRuleBind(obj) {
  return request4({
    url: `/manager/admin/deal-archieve-bind`,
    method: 'post',
    data: obj
  })
}

export function getRuleBindList(ruleId) {
  return request4({
    url: `/manager/admin/get-bind-relates/${ruleId}`,
    method: 'post'
  })
}

export function setRuleRuleState(ruleId, status) {
  return request4({
    url: `/manager/admin/update-archrule-status/${ruleId}/${status}`,
    method: 'post'
  })
}

export function removeRuleRuleState(id, companyId) {
  return request4({
    url: `/manager/admin/del-archieve/${id}/${companyId}`,
    method: 'post'
  })
}

export function getunArchShopsList(id, companyId) {
  return request4({
    url: `/manager/admin/un-arch-shops`,
    method: 'post'
  })
}

export function getunArchRolesList(id, companyId) {
  return request4({
    url: `/manager/admin/un-arch-job`,
    method: 'post'
  })
}

export function getOldBindRole(obj) {
  return request4({
    url: `/manager/admin/get-arch-job`,
    method: 'post',
    data:obj
  })
}

export function getOldBindShop(obj) {
  return request4({
    url: `/manager/admin/get-bind-shops`,
    method: 'post',
    data:obj
  })
}
//删除业绩规则
export function deleteAchievementRule(id) {
  return request4({
    url: `/achievementRule/web/deleteAchievementRule?id=${id}`,
    method: 'DELETE'
  })
}

//获取未绑定规则的角色
export function getUnBindRuleRoleList(id,type) {
  return request4({
    url: id?`/role/web/getUnBindRuleRoleList?id=${id}&type=${type}`:`/role/web/getUnBindRuleRoleList?type=${type}`,
    method: 'get'
  })
}

//获取未绑定规则的门店
export function getUnBindRuleShopList(id,type) {
  return request4({
    url: id?`/shop/web/getUnBindRuleShopList?ruleId=${id}&type=${type}`:`/shop/web/getUnBindRuleShopList?type=${type}`,
    method: 'get'
  })
}

//获取卡项树型数据
export function getCardTreeList(id) {
  return request4({
    url: `/cardItem/web/getCardTree`,
    method: 'get'
  })
}
//获取未绑定规则的人员
export function getNotBindRuleEmp(data) {
  return request4({
    url: `/adminUser/web/getNotBindRuleEmp`,
    method: 'post',
    data
  })
}
