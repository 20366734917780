import axios from 'axios'
import { showLoading, hideLoading } from '../../common/loading'
import { BASE_URL, TIME_OUT } from './config'
import localCache from '@/utils/cache'
import router from '@/router'
import { ElMessage } from 'element-plus'

export function request5(config) {
  showLoading()
  // 1.创建axios的实例
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    retry: 6,
    retryDelay: 1000
  })

  // 2.axios的拦截器
  instance.interceptors.request.use(
    (config) => {
      try {
        let value = document.querySelector("meta[name='_csrf']")?.content
        let key = document.querySelector("meta[name='_csrf_header']")?.content
        if(key&&value) {
          config.headers[key] = value
        }
        if(userId) {
          config.headers['User-Id'] = userId
        }
      } catch (error) {
        // console.log(error)
      }
      return config
    },
    (err) => {
      // console.log(err)
    }
  )

  // 2.2.响应拦截
  instance.interceptors.response.use(
    (res) => {
      hideLoading()
      return res.data
    },
    (err) => {
      hideLoading()
      var config = err.config
      let status = err.response && err.response.data.status
      if (status == 500 || status == 404) {
        config.__retryCount = 6
        ElMessage.error('服务器内部错误,请联系管理员！')
      }

      // 如果配置不存在或未设置重试选项，则拒绝
      if (!config || !config.retry) return Promise.reject(err)

      // 设置变量以跟踪重试次数

      config.__retryCount = config.__retryCount || 0

      // 检查我们是否已经注销了总重试次数

      if (config.__retryCount >= config.retry) {
        // 剔除错误

        return Promise.reject(err)
      }

      // 增加重试次数

      config.__retryCount += 1

      // 创建新的异步对象来处理重试请求

      var backoff = new Promise(function (resolve) {
        setTimeout(function () {
          resolve()
        }, config.retryDelay || 1)
      })

      return backoff.then(function () {
        return instance(config)
      })
    }
  )

  // 3.发送真正的网络请求
  return instance(config)
  // 此处返回promis对象
}
