import { request } from '../../request'
import { request4 } from '@/service/request4'

export function addGoodsTag(obj) {
  return request({
    url: '/insert-tag',
    method: 'post',
    data: obj
  })
}

export function addGoodsCategory(obj) {
  return request({
    url: '/insert-good-cata',
    method: 'post',
    data: obj
  })
}

export function getGoodsCategoryList(obj) {
  return request({
    url: '/good-cata',
    method: 'post',
    data: obj
  })
}

export function updateGoodsCategoryInfo(obj) {
  return request({
    url: '/update-good-cata',
    method: 'post',
    data: obj
  })
}

export function getGoodsTagList(obj) {
  return request({
    url: '/tag-list',
    method: 'post',
    data: obj
  })
}

export function addGoods(obj) {
  return request({
    url: '/insert-good',
    method: 'post',
    data: obj
  })
}

export function savePic(obj) {
  return request({
    url: '/insert-shop-pic',
    method: 'post',
    data: obj
  })
}

export function saveGoodsFormat(obj) {
  return request({
    url: '/insert-good-para',
    method: 'post',
    data: obj
  })
}

export function getGoodsList(obj) {
  return request({
    url: '/good-item',
    method: 'post',
    data: obj
  })
}

export function getGoodsDetail(obj) {
  return request({
    url: '/good-detail',
    method: 'post',
    data: obj
  })
}

export function getGoodsFormat(obj) {
  return request({
    url: '/good-para',
    method: 'post',
    data: obj
  })
}

export function getGoodsPic(obj) {
  return request({
    url: '/get-pic',
    method: 'post',
    data: obj
  })
}

export function removeGoodsPic(obj) {
  return request({
    url: '/update-pic',
    method: 'post',
    data: obj
  })
}

export function updateGoodsInfo(obj) {
  return request({
    url: '/update-good-basic',
    method: 'post',
    data: obj
  })
}

export function removeGoodsFormat(obj) {
  return request({
    url: '/update-good-para',
    method: 'post',
    data: obj
  })
}

export function updateGoodsState(obj) {
  return request({
    url: '/update-good-status',
    method: 'post',
    data: obj
  })
}

export function removeGoodsTag(obj) {
  return request({
    url: '/remove-good-tag',
    method: 'post',
    data: obj
  })
}

export function getGoodsPic2(relateId) {
  return request({
    url: `/get-cover/${relateId}/2`,
    method: 'post'
  })
}

export function getGoodsCategoryName(kindPara) {
  return request({
    url: `/good-kind/${kindPara}`,
    method: 'post'
  })
}

export function setGoodsShareInfo(obj) {
  return request({
    url: `/update-good-content`,
    method: 'post',
    data: obj
  })
}

export function setBatchGoodsState(obj) {
  return request({
    url: `/multi-update-good-status`,
    method: 'post',
    data: obj
  })
}

export function updateGoodsFormat(obj) {
  return request({
    url: `/update-good-para-items`,
    method: 'post',
    data: obj
  })
}

export function shopGoodsBind(shopId, goodId) {
  return request({
    url: `/bind-good-to-shop/${shopId}/${goodId}`,
    method: 'post'
  })
}

export function shopGoodsBindList(goodId) {
  return request({
    url: `/get-good-shop/${goodId}`,
    method: 'post'
  })
}

export function getCashCouponsList(data) {
  return request4({
    url: `/coupon/web/getCouponList`,
    method: 'post',
    data
  })
}

export function addCashCoupon(obj) {
  return request4({
    url: `/coupon/web/createCoupon`,
    method: 'post',
    data: obj
  })
}

export function updateCashCoupon(obj) {
  return request4({
    url: `/coupon/web/editCoupon`,
    method: 'PUT',
    data: obj
  })
}

export function addOpenCoupon(obj) {
  return request4({
    url: `/coupon/web/sendCoupon`,
    method: 'post',
    data: obj
  })
}

export function getOpenCouponLlist(obj) {
  return request4({
    url: `/coupon/web/getCouponEventList`,
    method: 'post',
    data: obj
  })
}

//禁用优惠券
export function enabledCoupon(id) {
  return request4({
    url: `/coupon/web/enabledCoupon?id=${id}`,
    method: 'get'
  })
}
//删除优惠券
export function deleteCoupon(id) {
  return request4({
    url: `/coupon/web/deleteCoupon?id=${id}`,
    method: 'DELETE'
  })
}
//获取优惠券
export function getCoupon(id) {
  return request4({
    url: `/coupon/web/getCoupon?id=${id}`,
    method: 'get'
  })
}
//获取优惠券领取明细
export function getUserCouponUsedList(data) {
  return request4({
    url: `/coupon/web/getUserCouponUsedList`,
    method: 'post',
    data
  })
}
//停止用户用券
export function stopUserCoupon(id) {
  return request4({
    url: `/coupon/web/stopUserCoupon?id=${id}`,
    method: 'get'
  })
}