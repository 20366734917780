import {
  saveShopInfo,
  saveShopBed,
  saveShopPic,
  getAllShopList,
  getShopDetail,
  getShopPicList,
  updateShopInfo,
  removeGoodsPic,
  stopShop,
  getShopBedNum,
  getShopBedList,
  getShopListPic,
  removeShopRoom,
  updateShopRoom
} from '@/service/main/shop'
import { tips } from '@/utils/tips'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import emitter from '@/utils/eventbus'
import { COMPANY_ID } from '@/common/constant'
import router from '@/router'
const shopModule = {
  namespaced: true,
  state() {
    return {
      test: '123',
      shopList: [],
      shopDetail: '',
      shopPicList: [],
      shopCount: 0,
      shopBedList: []
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changeShopList(state, list) {
      state.shopList = list
    },
    changeShopDetail(state, info) {
      state.shopDetail = info
    },
    changeShopPicList(state, list) {
      state.shopPicList = list
    },
    changeShopListCount(state, num) {
      state.shopCount = num
    },
    changeShopBedList(state, list) {
      state.shopBedList = list
    }
  },
  actions: {
    async saveShopInfoAction({ commit }, payload) {
      const obj = { ...payload }
      obj.shopRuntime = obj.runStartTime + '-' + obj.runEndTime
      const bedParams = obj.roomForm.map((item) => {
        return {
                roomNo : item.roomNum,
                bedQty : item.bedNum.replace(/，/g, ',')
              }
      })

      obj.picture = obj.pic[0]
      delete obj.pic
      delete obj.roomForm
      delete obj.runStartTime
      delete obj.runEndTime
      const res = await saveShopInfo({...obj,roomList:bedParams})
      if (res.code == 0) {
        router.push('/main/shop/list')
        ElMessage({
          message: '门店添加成功!',
          type: 'success'
        })
        emitter.emit('refreshShopListPageContent')
      } else {
        ElMessage.error(res.msg)
      }
    },
    async getshopListAction({ commit }, payload) {
      let obj = {
        current: payload.page,
        size: payload.pagesize,
        keyword: payload.keyword
      }
      const res = await getAllShopList(obj)
      let list = res.data.list
      commit('changeShopList', list)
      commit('changeShopListCount', Number(res.data.total))
    },
    async getShopDetailAction({ commit }, payload) {
      const res = await getShopDetail(payload)
      res.data.picture=[res.data.picture]
      commit('changeShopDetail', res.data)
    },
    async getShopPicList({ commit }, payload) {
      const res = await getShopPicList(payload)
      commit('changeShopPicList', res.data.goodCata)
    },
    async updateShopInfoAction({ commit }, payload) {
      const obj = { ...payload }
      obj.shopRuntime = obj.runStartTime + '-' + obj.runEndTime
      obj.picture = obj.pic[0].url
      const bedParams = obj.roomFormList.map((item) => {
        return {
                roomNo : item.roomNo,
                roomId : item.rommId,
                bedQty : item.bedQty.replace(/，/g, ',')
              }
      })
      delete obj.pic
      delete obj.runStartTime
      delete obj.runEndTime
      delete obj.roomFormList
      const res = await updateShopInfo({...obj,roomList:bedParams})
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshShopListPage')
        router.push('/main/shop/list')
      }
    },
    async saveServePicAction({ commit }, payload) {
      const res = await saveShopPic(payload)
      tips(res)
    },
    async removeShopPic({ commit }, payload) {
      const res = await removeGoodsPic(payload)
      tips(res)
    },
    async stopShopAction({ commit }, payload) {
      const res = await stopShop(payload)
      tips(res)
    },
    async getShopBedListAction({ commit }, payload) {
      const res = await getShopBedList(payload.shopId)
      commit('changeShopBedList', res.data.list)
    },
    async setShopStateAction({ commit }, payload) {
      const res = await stopShop(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshShopListPage')
        emitter.emit('refreshShopEditDrawPage')
      }
    },
    async changeShopBedList({ commit }, payload) {
      const res = await getShopBedList(payload.shopId)
      commit('changeShopBedList', res.data.list)
    },
    async removeShopRoomAction({ commit }, payload) {
      const res = await removeShopRoom(payload)
      tips(res)
    },
    async updateShopRoomAction({ commit }, payload) {
      const res = await updateShopRoom(payload)
      tips(res)
    }
  }
}

export default shopModule
