
import { ElMessage } from 'element-plus'
export function tips(res,message='操作成功!'){
  if(res.code==0){
    ElMessage({
      message: message,
      type: 'success',
    })
  }else{
    ElMessage.error(res.msg?res.msg:'操作失败!')
  }
}
