const rankModule = {
  namespaced: true,
  state() {
    return {
      rankInfoList: [
        {
          id: 1,
          label: '店长',
          children: [
            {
              id: 2,
              label: 'A店长'
            },
            {
              id: 3,
              label: 'B店长'
            }
          ]
        },
        {
          id: 4,
          label: '普通职员'
        },
        {
          id: 5,
          label: '理疗师',
          children: [
            {
              id: 501,
              label: '初级理疗士(1)'
            },
            {
              id: 502,
              label: '中级理疗师(2)'
            },
            {
              id: 503,
              label: '高级理疗师(1)'
            },
            {
              id: 504,
              label: '技术总监(1)'
            }
          ]
        },
        {
          id: 6,
          label: '前台'
        }
      ],
      rankstaffList: [
        {
          name: {
            pic: '',
            name: '张学友'
          },
          office: '运营师',
          acount: '13987654321',
          role: '员工',
          department: '运营部',
          entry_time: '2021年11月1日'
        },
        {
          name: {
            pic: '',
            name: '张学友'
          },
          acount: '13987654321',
          office: '运营师',
          role: '员工',
          department: '运营部',
          entry_time: '2021年11月1日'
        },
        {
          name: {
            pic: '',
            name: '张学友'
          },
          office: '运营师',
          acount: '13987654321',
          role: '员工',

          department: '运营部',
          entry_time: '2021年11月1日'
        },
        {
          name: {
            pic: '',
            name: '张学友'
          },
          office: '运营师',
          acount: '13987654321',
          role: '员工',
          department: '运营部',
          entry_time: '2021年11月1日'
        },
        {
          name: {
            pic: '',
            name: '张学友'
          },
          office: '运营师',
          acount: '13987654321',
          role: '员工',
          department: '运营部',
          entry_time: '2021年11月1日'
        }
      ],
      staffCount: 5
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {},
  actions: {}
}

export default rankModule
