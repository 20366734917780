const authorityModule = {
  namespaced: true,
  state() {
    return {
      authorityInfoList: [
        {
          name: '超级管理员',
          authority: '全部',
          acountNum: 1,
          createTime: '2020-12-01 17:55:09',
          type:1
        }
      ]
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {},
  actions: {}
}

export default authorityModule
