import { COMPANY_ID } from '@/common/constant'
import router from '@/router'
import {
  getReserveList,
  getShopRoomList,
  getTeacherList,
  setToShop,
  setReserveRemark,
  getReserveRemarkList,
  removeReserveRemark,
  addCustomerInfo,
  getCustomerInfoList,
  removeCustomerInfo,
  cancelReserve,
  getStaffList,
  getShopList,
  getShopList2,
  getShopTeacherList,
  getTeacherServeList,
  getClientInfo,
  addReserve,
  getTeacheriSAvaiable,
  getTeacherReserveList,
  getTeacherRankServeList,
  getRoomReserveList,
  getReservePaySourceList,
  setTeacherTimeOccupy,
  registerUser,
  addReserveRule,
  getReserveRuleList,
  updateReserveRule,
  removeReserveRule,
  getTeacherOccupyTimeList,
  getStaffPaiBanList,
  newReserve,
  getReserveShopList,
  reserveLastSet,
  cancelReserveApp,
  refund,
  cancelOrder,
  updateReserveDate,
  getTeacherReserveList2,
  newSetOrderNo,
  newRefund,
  newCancleReserve,
  addTicheng
} from '@/service/main/reserve'
import { setReserveRemark2 } from '@/service/main/order'
import { getBelongShop } from '@/service/main/staff'
import emitter from '@/utils/eventbus'
import { tips } from '@/utils/tips'
import {
  // reserveTimeList,
  roomTimeList,
  // reserveTimeList_30,
  // reserveTimeList_60,
  useShopTime
} from '@/views/main/reserve/check/cpns/config'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { RESERVE_START_TIME } from '@/common/constant'
import { deepClone } from '@/utils/clone'
import localCache from '@/utils/cache'
import cache from '@/utils/cache'

const reserveModule = {
  namespaced: true,
  state() {
    return {
      reserveAllList: [],
      siteRuleInfoList: [],
      reserveListCount: [],
      allReserveList: [],
      reserveWaitShopList: [],
      reserveToShopList: [],
      reserveBillingList: [],
      reserveFinishList: [],
      reserveCancelList: [],
      reserveRefundList: [],
      shopRoomList: [],
      teacherList: [],
      reserveRemarkList: [],
      customerInfoList: [],
      staffList: [],
      staffListCount: 0,
      reserveTimeList: [],
      shopList: [],
      shopTeacherList: [],
      teacherServeList: [],
      clientInfo: '',
      teacherReserveList: [],
      reservePaySourceList: [],
      roomReserveList: [],
      roomList: [],
      roomTimeList: [],
      reserveRuleList: [],
      teacherReserveStateInfo: [],
      roomReserveStateInfo: [],
      occupyTimeList: [],
      reserveWaitShopListCount: 0,
      reserveToShopListCount: 0,
      reserveFinishListCount: 0,
      reserveFinishListCount: 0,
      reserveCancelListCount: 0,
      refundListCount: 0
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changeReserveListCount(state, count) {
      state.reserveListCount = count
    },
    changeAllReserveList(state, list) {
      state.allReserveList = list
    },
    changheReserveWaitShopList(state, list) {
      state.reserveWaitShopList = list
    },
    changheReserveWaitShopListCount(state, count) {
      state.reserveWaitShopListCount = count
    },
    changheReserveToShopListCount(state, count) {
      state.reserveToShopListCount = count
    },
    changheReserveToShopList(state, list) {
      state.reserveToShopList = list
    },
    changeReserveBillingList(state, list) {
      state.reserveBillingList = list
    },
    changeReserveBillingListCount(state, count) {
      state.reserveBillingListCount = count
    },
    changeReserveFinishList(state, list) {
      state.reserveFinishList = list
    },
    changeReserveFinishListCount(state, count) {
      state.reserveFinishListCount = count
    },
    changeReserveCancelList(state, list) {
      state.reserveCancelList = list
    },
    changeReserveCancelListCount(state, count) {
      state.reserveCancelListCount = count
    },
    changeReserveRefundList(state, list) {
      state.reserveRefundList = list
    },
    changeReserveRefundListCount(state, count) {
      state.refundListCount = count
    },
    changeShopRoomList(state, list) {
      state.shopRoomList = list
    },
    changeTeacherList(state, list) {
      state.teacherList = list
    },
    changeReserveRemarkList(state, list) {
      state.reserveRemarkList = list
    },
    changeCustomerInfoList(state, list) {
      state.customerInfoList = list
    },
    changeStaffList(state, list) {
      state.staffList = list
    },
    changeStaffListCount(state, info) {
      state.staffListCount = info
    },
    changeNewReserveTimeList(state, list) {
      state.reserveTimeList = list
    },
    changeShopList(state, list) {
      state.shopList = list
    },
    changeShopTeacherList(state, list) {
      state.shopTeacherList = list
    },
    changeTeacherServeList(state, list) {
      state.teacherServeList = list
    },
    changeClientInfo(state, info) {
      state.clientInfo = info
    },
    changeTeacherReserveList(state, list) {
      state.teacherReserveList = list
    },
    changeReservePaySourceList(state, list) {
      state.reservePaySourceList = list
    },
    changeRoomReserveList(state, list) {
      state.roomReserveList = list
    },
    changeRoomList(state, list) {
      state.roomList = list
    },
    changeRoomTimeList(state, list) {
      state.roomTimeList = list
    },
    changeReserveRuleList(state, list) {
      state.reserveRuleList = list
    },
    changeTeacherReserveStateInfo(state, info) {
      state.teacherReserveStateInfo = info
    },
    changeRoomReserveStateInfo(state, info) {
      state.roomReserveStateInfo = info
    },
    changeOccupyTimeList(state, list) {
      state.occupyTimeList = list
    }
  },
  actions: {
    async getReserveListAction({ commit }, payload) {
      const res = await getReserveList({ ...payload})
      const list = res.data.list
      commit('changeReserveListCount', Number(res.data.total))
      commit('changeAllReserveList', list)
    },
    async getShopRoomListAction({ commit }, payload) {
      if (payload == undefined) return
      const res = await getShopRoomList(payload)
      commit('changeShopRoomList', res.data.list)
    },
    async getTeacherListAction({ commit }, payload) {
      const res = await getTeacherList(COMPANY_ID)
      commit('changeTeacherList', res.data.list)
    },
    async setToShopAction({ commit }, payload) {
      const res = await setToShop(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('changeReserveEditDrawPageContent')
        emitter.emit('changeReservePhysiotherapyListPageContent')
        emitter.emit('changeReserveTopContentSearch')
        emitter.emit('changeReserveRoomListPageContent')
      }
    },
    async setReserveRemarkAction({ commit }, payload) {
      const res = await setReserveRemark(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshReserveRemarkListContent', payload.orderNo)
      }
    },
    async setOrderPageRemarkAction({ commit }, payload) {
      const res = await setReserveRemark(payload)
      tips(res)
    },
    async setOrderPageRemarkAction2({ commit }, payload) {
      const res = await setReserveRemark2(payload)
      tips(res)
    },
    async getReserveRemarkListAction({ commit }, payload) {
      if (payload == undefined) return
      const res = await getReserveRemarkList(payload)
      commit('changeReserveRemarkList', res.data.list)
    },
    async removeReserveRemarkAction({ commit }, payload) {
      const res = await removeReserveRemark(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshReserveRemarkListContent', payload[1])
      }
    },
    async addCustomerInfoAction({ commit }, payload) {
      const res = await addCustomerInfo(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshReserveCustomerListContent', payload.userId)
      }
    },
    async getCustomerInfoListAction({ commit }, payload) {
      const res = await getCustomerInfoList(payload)
      commit('changeCustomerInfoList', res.data.list)
    },
    async removeCustomerInfoAction({ commit }, payload) {
      let obj = { ...payload }
      const res = await removeCustomerInfo(obj.id, obj.orderNo)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshReserveCustomerListContent', obj.userId)
      }
    },
    async cancelReserveAction({ commit }, payload) {
      let obj = { ...payload }
      let {
        appNo,
        orderNo,
        serveName,
        custName,
        userId,
        paystatus,
        onlinePayPrice,
        orderKind
      } = obj
      // delete obj.appNo
      // delete obj.userId

      const cancleOrderFunc = async () => {
        const res = await newCancleReserve({
          orderKind,
          orderNo,
          userId,
          appNo,
          companyId: COMPANY_ID,
          paystatus
        })

        if (res.code == 0) {
          emitter.emit('refreshCyrrentReserveListPage')
          emitter.emit('refreshCurrentReservecheckPage')
          emitter.emit('changeReserveEditDrawPageContent')

          ElMessage({
            message: '操作成功!',
            type: 'success'
          })
        }
      }

      if (onlinePayPrice > 0) {
        const res1 = await newSetOrderNo({
          orderNo,
          serveName,
          custName
        })

        if (res1.code == 0) {
          const res2 = await newRefund({
            refundNo: res1.data,
            amount: onlinePayPrice
          })
          cancleOrderFunc()
        }
      } else {
        cancleOrderFunc()
      }
    },
    async getStaffOccupyListAction({ commit }, payload) {
      let obj = { ...payload }
      // let timeDiff = obj.timeDiff
      const res = await getReserveShopList(obj.shopId)
      let list = res.data.list
      list = list.filter((ele) => ele.status == 1)
      // let occupyTimeList = []

      Promise.all(
        list.map(async (item) => {
          return await getTeacherOccupyTimeList(item.userId, obj.time)
        })
      ).then((resList) => {
        // console.log('getStaffOccupyListAction1--->',resList)

        resList.forEach((ele, index) => {
          let timeList = ele.data.list
          list[index].occupyTime = timeList

          // // console.log('timeList--->',timeList)

          // occupyTimeList.push(timeList)
          // // 判断是否是休息状态 如果含有0点 说明是休息
          // let isRest = timeList.findIndex(
          //   (ele) => ele.appTime == '00:00' && ele.timeStatus == 4
          // )
          // let sliceIndex = timeList.findIndex(
          //   (ele) => ele.appTime == RESERVE_START_TIME
          // )
          // if (isRest != -1) {
          //   timeList = timeList.slice(sliceIndex)
          //   // 设置为休息状态
          //   list[index]['isRest'] = true
          // }

          // // 遍历所有的时间
          // let start = 0
          // let jiluTimeList = {}
          // jiluTimeList[start] = []
          // timeList.forEach((timeItem, indey) => {
          //   let date1 = dayjs('2022-12-12' + timeItem.appTime)
          //   let date2
          //   if (indey + 1 <= timeList.length - 1) {
          //     date2 = dayjs('2022-12-12' + timeList[indey + 1].appTime)
          //     let diff = date2.diff(date1, 'minute')
          //     jiluTimeList[start].push(timeItem.appTime)
          //     // 如果时间间隔大于10 就是写一个占用
          //     if (diff > 10) {
          //       start++
          //       jiluTimeList[start] = []
          //     }
          //   }

          //   // 最后一个时间也添加进去
          //   if (indey == timeList.length - 1) {
          //     jiluTimeList[start].push(timeItem.appTime)
          //   }
          // })

          // occupyTimeList[index] = jiluTimeList
        })

        // // 计算占用时间块
        // occupyTimeList.forEach((occupyItem, indeo) => {
        //   let arr = Object.values(occupyItem)
        //   list[indeo].occupyTime = []
        //   arr.forEach((timeele, indeq) => {
        //     let ariveTime = timeele[0]
        //     if (ariveTime == undefined) return

        //     let date1, date2, date3, date4, height, firstIndex
        //     date1 = dayjs('2022-12-12' + RESERVE_START_TIME)
        //     date2 = dayjs('2022-12-12' + ariveTime)
        //     if (timeDiff == 10) {
        //       firstIndex = date2.diff(date1, 'minute') / 10
        //     } else if (timeDiff == 30) {
        //       firstIndex = date2.diff(date1, 'minute') / 30
        //     } else if (timeDiff == 60) {
        //       firstIndex = date2.diff(date1, 'minute') / 60
        //     }
        //     date3 = dayjs('2022-12-12' + timeele[0])
        //     date4 = dayjs('2022-12-12' + timeele[timeele.length - 1])
        //     if (timeDiff == 10) {
        //       height = date4.diff(date3, 'minute') / 10
        //     } else if (timeDiff == 30) {
        //       height = date4.diff(date3, 'minute') / 30
        //     } else if (timeDiff == 60) {
        //       height = date4.diff(date3, 'minute') / 60
        //     }
        //     list[indeo].occupyTime.push({
        //       time: timeele,
        //       height,
        //       topIndex: firstIndex
        //     })

        //   })
        // })

        // console.log('getStaffOccupyListAction1222-->',list)
        commit('changeOccupyTimeList', list)
      })
    },
    async getStaffDetailListAction({ commit }, payload) {
      // console.log('payload',payload)
      let obj = { ...payload }
      let time, showDays
      let timeDiff = obj.timeDiff
      let days = obj.days
      let userId = obj.userId
      let currrentReserveTimeList = []
      let reserveList = []
      let totalReserve = []
      const { reserveTimeList, reserveTimeList_30, reserveTimeList_60 } = useShopTime(obj.shopId)

      if (days == 0) {
        time = obj.time
        showDays = 7
      } else {
        time = obj.start
        showDays = days
      }
      for (let i = 0; i <= showDays; i++) {
        let newDate = dayjs(time).add(i, 'day').format('YYYY-MM-DD')
        // console.log(newDate)
        let day = dayjs(newDate).day()
        let week
        switch (day) {
          case 0:
            week = '星期天'
            break
          case 1:
            week = '星期一'
            break
          case 2:
            week = '星期二'
            break
          case 3:
            week = '星期三'
            break
          case 4:
            week = '星期四'
            break
          case 5:
            week = '星期五'
            break
          case 6:
            week = '星期六'
            break
        }
        currrentReserveTimeList.push({
          time: newDate,
          week
        })
      }

      Promise.all(
        currrentReserveTimeList.map(async (item, index) => {
          reserveList[index] = {
            index,
            reserveList: []
          }
          return await getTeacherReserveList(userId, item.time)
        })
      ).then((resList) => {
        resList.forEach(async (ele, indey) => {
          let eleList = []
          let newReserveList = ele.data.list
          newReserveList.forEach((item, index) => {
            // 预约的项目
            let list = item.appDetail
            // 筛选出取消的预约
            list = list.filter((ele) => ele.appStatus <= 4)
            if (list.length >= 1) {
              let serveNameArr = [],
                timeListArr = [],
                mainTimeList = [],
                mainReserveId = [],
                mainReserveStartTime = []
              list.forEach((ele) => {
                mainTimeList.push(ele.timelist)
                mainReserveId.push(ele.id)
                serveNameArr.push(ele.serveName)
                timeListArr.push(...ele.timelist)
                mainReserveStartTime.push(ele.timelist[0].appTime)
              })
              // 给第一条预约添加用到的数据
              list[0]['ordeinfo'] = [item.orderInfo]
              list[0]['appNo'] = item.appNo
              list[0]['serveNameArr'] = serveNameArr
              list[0]['timelist'] = timeListArr
              list[0]['mainTimeList'] = mainTimeList
              list[0]['mainReserveId'] = mainReserveId
              list[0]['mainReserveStartTime'] = mainReserveStartTime
              eleList.push(list[0])
            }
          })

          eleList.forEach((el, indez) => {
            let ariveTime = el.arriveTime.slice(0, 5)
            let date1, date2, date3, date4, height, firstIndex

            date1 = dayjs('2022-12-12' + RESERVE_START_TIME)
            date2 = dayjs('2022-12-12' + ariveTime)
            if (timeDiff == 10) {
              firstIndex = date2.diff(date1, 'minute') / 10
            } else if (timeDiff == 30) {
              firstIndex = date2.diff(date1, 'minute') / 30
            } else if (timeDiff == 60) {
              firstIndex = date2.diff(date1, 'minute') / 60
            }

            let timeList = el.timelist

            // 解决没预约时间的bug
            if (timeList.length == 0) return

            let ariveTimeIndex = timeList.findIndex(
              (elem) => elem.appTime == ariveTime
            )

            if (ariveTimeIndex != -1) {
              timeList = timeList.slice(ariveTimeIndex)
            }

            date3 = dayjs('2022-12-12' + timeList[0].appTime)
            date4 = dayjs('2022-12-12' + timeList[timeList.length - 1].appTime)

            if (timeDiff == 10) {
              height = date4.diff(date3, 'minute') / 10
            } else if (timeDiff == 30) {
              height = date4.diff(date3, 'minute') / 30
            } else if (timeDiff == 60) {
              height = date4.diff(date3, 'minute') / 60
            }

            eleList[indez].height = height
            eleList[indez].timelist = timeList
            eleList[indez].orderlist = el.ordeinfo
            eleList[indez].topIndex = firstIndex
          })
          totalReserve = [...totalReserve, ...eleList]
          reserveList[indey].reserveList = eleList
        })
        let appStatus1 = totalReserve.filter((item) => item.appStatus == 1)
        let appStatus2 = totalReserve.filter((item) => item.appStatus == 2)
        let appStatus3 = totalReserve.filter((item) => item.appStatus == 3)
        let appStatus4 = totalReserve.filter((item) => item.appStatus == 4)
        let appStatus5 = totalReserve.filter((item) => item.appStatus == 5)
        let appStatus6 = totalReserve.filter((item) => item.appStatus == 6)
        commit('changeTeacherReserveStateInfo', [
          appStatus1.length,
          appStatus2.length,
          appStatus3.length,
          appStatus4.length,
          appStatus5.length,
          appStatus6.length
        ])
        commit('changeTeacherReserveList', reserveList)

        let paibanlist = []
        Promise.all(
          currrentReserveTimeList.map(async (ele, indey) => {
            return await getStaffPaiBanList(userId, ele.time)
          })
        ).then((resList) => {
          // console.log('resList', resList)
          resList.forEach((elem, indez) => {
            currrentReserveTimeList[indez].paibanList = elem.data.list
          })

          let newReserveTimeList = []

          if (timeDiff == 10) {
            reserveTimeList.value.forEach((item, index) => {
              newReserveTimeList[index] = item
              newReserveTimeList[index].staffList = currrentReserveTimeList
            })
          } else if (timeDiff == 30) {
            reserveTimeList_30.value.forEach((item, index) => {
              newReserveTimeList[index] = item
              newReserveTimeList[index].staffList = currrentReserveTimeList
            })
          } else if (timeDiff == 60) {
            reserveTimeList_60.value.forEach((item, index) => {
              newReserveTimeList[index] = item
              newReserveTimeList[index].staffList = currrentReserveTimeList
            })
          }

          commit('changeNewReserveTimeList', newReserveTimeList)
          commit('changeStaffList', currrentReserveTimeList)
        })
      })
    },
    async getStaffListAction({ commit }, payload) {
      let obj = { ...payload }
      let timeDiff = obj.timeDiff
      const res = await getReserveShopList(obj.shopId)
      const { reserveTimeList, reserveTimeList_30, reserveTimeList_60 } = useShopTime(obj.shopId)
      let list = res.data.list
      list = list.filter((ele) => ele.status == 1)
      let reserveList = []
      let totalReserve = []

      // console.log('reserveTimeList_30',reserveTimeList_30)
      Promise.all(
        list.map(async (item, index) => {
          reserveList[index] = {
            index,
            reserveList: []
          }
          return await getTeacherReserveList(item.userId, obj.time)
        })
      ).then((resList) => {
        resList.forEach((ele, indey) => {
          // 处理时间
          let eleList = []
          let newReserveList = ele.data.list
          // console.log('newReserveList',newReserveList)
          newReserveList.forEach((item, index) => {
            // 预约的项目
            let list = item.appDetail
            // 筛选出取消的预约
            list = list.filter((ele) => ele.appStatus <= 4)

            let serveNameArr = [],
              timeListArr = [],
              mainTimeList = [],
              mainReserveId = [],
              mainReserveStartTime = [],
              mainServeTimeArr = [],
              mainServeTimeLength = 0,
              mainServestrArr = [],
              mainServeId = [],
              appTimeArr = []

            if (list.length >= 1) {
              list.forEach((ele) => {
                let timeArr = []
                let opttimelist = ele.timelist
                opttimelist.forEach((item) => {
                  timeArr.push(item.appTime)
                })
                appTimeArr.push(timeArr.join())
                mainTimeList.push(ele.timelist)
                mainReserveId.push(ele.id)
                serveNameArr.push(ele.serveName)
                timeListArr.push(...ele.timelist)
                mainServeId.push(String(ele.serveId))

                if (ele.timelist.length > 0) {
                  mainReserveStartTime.push(ele.timelist[0].appTime)
                }

                mainServeTimeArr.push(ele.serveTimeLength)
                mainServeTimeLength += ele.serveTimeLength
                mainServestrArr.push(
                  `${ele.serveName},${ele.serveId},${ele.paraId},${ele.servePrice},${ele.serveTimeLength}`
                )
              })

              // 给第一条预约添加用到的数据
              list[0]['mainServeTimeLength'] = mainServeTimeLength
              list[0]['ordeinfo'] = [item.orderInfo]
              list[0]['appNo'] = item.appNo
              list[0]['serveNameArr'] = serveNameArr
              list[0]['timelist'] = timeListArr
              list[0]['mainTimeList'] = mainTimeList
              list[0]['mainReserveId'] = mainReserveId
              list[0]['mainReserveStartTime'] = mainReserveStartTime
              list[0]['mainServestrArr'] = mainServestrArr
              list[0]['mainServeId'] = mainServeId
              list[0]['mainServeTimeArr'] = mainServeTimeArr
              list[0]['appTimeArr'] = appTimeArr
              eleList.push(list[0])
            }
          })

          let cancelReserveIdArr = [],
            cancelReserveServeNameArr = [],
            cancelReserveNoArr = []

          eleList.forEach((ele) => {
            cancelReserveIdArr.push(ele.id)
            cancelReserveServeNameArr.push(ele.serveName)
            cancelReserveNoArr.push(ele.appNo)
          })

          eleList.forEach((el, indez) => {
            let timeList = el.timelist
            // 解决没预约时间的bug
            if (timeList.length == 0) return

            // 设置取消预约时的信息
            let paystatus = 0

            if (el.ordeinfo[0] != null && el.ordeinfo.length > 0) {
              let num = el.ordeinfo[0].paystatus
              switch (num) {
                case 1:
                  paystatus = 0
                  break
                case 2:
                  paystatus = 1
                  break
                default:
                  paystatus = 0
              }
            }

            el['cancelInfo'] = {
              companyId: COMPANY_ID,
              ids: cancelReserveIdArr.join(),
              paystatus: 0,
              appNos: cancelReserveNoArr.join(),
              serveName: cancelReserveServeNameArr.join(),
              custName: el.userRealName,
              paystatus,
              orderNo:
                el.ordeinfo[0] != null && el.ordeinfo.length > 0
                  ? el.ordeinfo[0].orderNo
                  : '',
              appNo: el.appNo,
              userId: el.userId
            }

            let ariveTime = timeList[0].appTime
            let date1, date2, date3, date4, height, firstIndex
            date1 = dayjs('2022-12-12' + RESERVE_START_TIME)
            date2 = dayjs('2022-12-12' + ariveTime)
            date3 = dayjs('2022-12-12' + timeList[0].appTime)
            date4 = dayjs('2022-12-12' + timeList[timeList.length - 1].appTime)

            if (timeDiff == 10) {
              firstIndex = date2.diff(date1, 'minute') / 10
            } else if (timeDiff == 30) {
              firstIndex = date2.diff(date1, 'minute') / 30
            } else if (timeDiff == 60) {
              firstIndex = date2.diff(date1, 'minute') / 60
            }

            let ariveTimeIndex = timeList.findIndex(
              (elem) => elem.appTime == ariveTime
            )

            if (ariveTimeIndex != -1) {
              timeList = timeList.slice(ariveTimeIndex)
            }

            if (timeDiff == 10) {
              height = date4.diff(date3, 'minute') / 10
            } else if (timeDiff == 30) {
              height = date4.diff(date3, 'minute') / 30
            } else if (timeDiff == 60) {
              height = date4.diff(date3, 'minute') / 60
            }

            eleList[indez].height = height
            eleList[indez].timelist = timeList
            eleList[indez].orderlist = el.ordeinfo
            eleList[indez].topIndex = firstIndex
          })
          totalReserve = [...totalReserve, ...eleList]
          reserveList[indey].reserveList = eleList
        })
        let appStatus1 = totalReserve.filter((item) => item.appStatus == 1)
        let appStatus2 = totalReserve.filter((item) => item.appStatus == 2)
        let appStatus3 = totalReserve.filter((item) => item.appStatus == 3)
        let appStatus4 = totalReserve.filter((item) => item.appStatus == 4)
        let appStatus5 = totalReserve.filter((item) => item.appStatus == 5)
        let appStatus6 = totalReserve.filter((item) => item.appStatus == 6)
        commit('changeTeacherReserveStateInfo', [
          appStatus1.length,
          appStatus2.length,
          appStatus3.length,
          appStatus4.length,
          appStatus5.length,
          appStatus6.length
        ])

        // console.log('reserveList', reserveList)

        commit('changeTeacherReserveList', reserveList)

        let paibanlist = []
        Promise.all(
          list.map(async (ele, indey) => {
            return await getStaffPaiBanList(ele.userId, obj.time)
          })
        ).then((resList) => {
          // console.log('resList2222', resList)

          resList.forEach((elem, indez) => {
            let pbList = elem.data.list
            list[indez].paibanList = pbList
          })

          let newReserveTimeList = []

          // console.log('!!!reserveTimeList!!!!!--->',reserveTimeList.value)


          if (timeDiff == 10) {
            reserveTimeList.value.forEach((item, index) => {
              newReserveTimeList[index] = item
              newReserveTimeList[index].staffList = list
              newReserveTimeList[index].flag = 1
            })
          } else if (timeDiff == 30) {
            reserveTimeList_30.value.forEach((item, index) => {
              newReserveTimeList[index] = item
              newReserveTimeList[index].staffList = list
              newReserveTimeList[index].flag = 1
            })
          } else if (timeDiff == 60) {
            reserveTimeList_60.value.forEach((item, index) => {
              newReserveTimeList[index] = item
              newReserveTimeList[index].staffList = list
            })
          }

          newReserveTimeList.forEach((item) => {
            let date = obj.time
            let currentDate = dayjs().valueOf()
            let optDate = dayjs(date + ' ' + item.time).valueOf()
            let status = 1
            if (optDate - currentDate < 0) {
              status = 0
            }
            // status 0 时间过期 1时间可用
            item['status'] = status
          })

          // console.log('newReserveTimeList',newReserveTimeList)
          commit('changeNewReserveTimeList', newReserveTimeList)
          commit('changeStaffList', list)
        })
      })
    },
    async getShopListAction({ commit }, payload) {
      const res = await getShopList(payload)
      commit('changeShopList', res.data.list)
    },
    async getShopListAction2({ commit }, payload) {
      const res = await getShopList2(payload)
      commit('changeShopList', res.data.list)
    },
    async getShopListAction3({ commit }, payload) {
      const res = await getBelongShop(payload)
      let ids = {
        id: res.data.list[0].shopId
      }
      cache.setCache('newShopId', ids)
      commit('changeShopList', res.data.list)
    },
    async getShopTeacherListAction({ commit }, payload) {
      const res = await getReserveShopList(payload)
      let list = res.data?.list || []
      list = list.length && list.filter((ele) => ele.status == 1)
      commit('changeShopTeacherList', list)
    },
    async getTeacherServeListAction({ commit }, payload) {
      const res = await getTeacherRankServeList(payload)
      let list = res.data
      let newList = []

      list.forEach((ele, index) => {
        let paralist = ele.infos
        paralist.forEach((item, indey) => {
          item['projectName'] = item['serveName']
          item['serveName'] = ele['kindName']
          item['serveKind'] = ele['kindId']
          newList.push(item)
        })
      })

      commit('changeTeacherServeList', newList)
    },
    async getClientInfoAction({ commit }, payload) {
      const res = await getClientInfo(payload)
      if (res.data.hasOwnProperty('userAva')) {
        commit('changeClientInfo', res.data)
      } else {
        commit('changeClientInfo', {})
      }
    },
    async addReserveAction({ commit }, payload) {
      let obj = { ...payload }
      let { reserveArr, arriveDate, arriveTime, reserveLastTime } = obj
      obj.arriveDate = dayjs(arriveDate).format('YYYY-MM-DD')
      let appTimes = [],
        timeList = [],
        exceedTimeArrs = []

      let lastTime = reserveLastTime
      let startTime = arriveTime
      delete obj.reserveLastTime

      if (obj.userId.length == 0) {
        let registerParams = {
          companyId: obj.companyId,
          userMobile: obj.userMobile,
          userRealname: obj.userRealname,
          shopId: obj.shopId
        }
        const registerRes = await registerUser(registerParams)
        if (registerRes.data != '手机号码已注册') {
          obj.userId = registerRes.data
        }
      }

      let timeExceed = dayjs(obj.arriveDate + lastTime)
        .add(10, 'minute')
        .format('HH:mm')

      reserveArr.forEach((ele) => {
        let arr = ele.split(',')
        let timeLength = arr[arr.length - 1] / 10
        let timeArr = []
        for (let i = 0; i <= timeLength; i++) {
          let date = dayjs(`${obj.arriveDate} ${startTime}`).add(
            10 * i,
            'minute'
          )
          let time = date.format('HH:mm')

          timeArr.push(time)
          timeList.push(time)

          if (i == timeLength) {
            startTime = dayjs(`${obj.arriveDate} ${time}`)
              .add(10, 'minute')
              .format('HH:mm')
          }
        }
        appTimes.push(timeArr.join())
      })

      let isTimeExceed = timeList.findIndex((ele) => ele == timeExceed)
      let timeExceedV = dayjs(obj.arriveDate + lastTime)
        .add(10, 'minute')
        .valueOf()

      startTime = arriveTime
      reserveArr.forEach((ele) => {
        let arr = ele.split(',')
        let timeLength = arr[arr.length - 1] / 10
        let exceedTimeArr = []
        for (let i = 0; i <= timeLength; i++) {
          let date = dayjs(`${obj.arriveDate} ${startTime}`).add(
            10 * i,
            'minute'
          )
          let time = date.format('HH:mm')
          let currentV1 = dayjs(`${obj.arriveDate} ${startTime}`)
            .add(10 * i, 'minute')
            .valueOf()
          if (currentV1 >= timeExceedV) {
            exceedTimeArr.push(time)
          }

          if (i == timeLength) {
            startTime = dayjs(`${obj.arriveDate} ${time}`)
              .add(10, 'minute')
              .format('HH:mm')
          }
        }
        if (exceedTimeArr.length > 0) {
          exceedTimeArrs.push(exceedTimeArr.join())
        }
      })

      let iSAvaiableParams = {
        appDate: obj.arriveDate,
        appTimes: timeList.join(),
        userId: obj.empId
      }

      const iSAvaiableRes = await getTeacheriSAvaiable(iSAvaiableParams)
      if (iSAvaiableRes.data > 0 && isTimeExceed == -1) {
        ElMessage({
          message: '理疗师时间不可用!',
          type: 'warning'
        })
        return
      }

      obj.timeStr = appTimes.join('&')
      obj.serveStr = obj.reserveArr.join('&')
      delete obj.userMobile
      delete obj.totalTime
      delete obj.reserveArr
      delete obj.userRealname
      const res = await newReserve(obj)

      try {
        let addTichentgObej = {
          orderNo: res.data.orderNo,
          empId: obj.empId,
          shopId: obj.shopId,
          bindKind: 1
        }
        let tichengRes = await addTicheng(addTichentgObej)

        if (tichengRes.code != 0) {
          setTimeout(() => {
            ElMessage({
              message: tichengRes.msg,
              type: 'warning'
            })
          }, 1000)
        }
      } catch (error) {
        // console.log(error)
      }

      let appNos = res.data.appNos
      let orderNo = res.data.orderNo
      // 超时服务处理
      if (isTimeExceed != -1) {
        let startIndex = reserveArr.length - exceedTimeArrs.length
        let appNoArr = appNos.split(',')
        appNoArr.reverse()
        let reserveArrs = []

        exceedTimeArrs.forEach((ele, index) => {
          let appNo = appNoArr[startIndex + index]
          reserveArrs.push(appNo)
        })

        Promise.all(
          exceedTimeArrs.map(async (item, index) => {
            return await reserveLastSet({
              companyId: COMPANY_ID,
              userId: obj.empId,
              appDate: obj.arriveDate,
              appNo: reserveArrs[index],
              orderNo,
              shopId: obj.shopId,
              timestr: item
            })
          })
        ).then((resList) => {
          if (resList[0].code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success'
            })
          }
        })
      }
      tips(res)
      if (res.code == 0) {
        emitter.emit('changeAddReservePageStaffReserveList')
      }
    },
    async getReserveRoomListAction({ commit }, payload) {
      let timeDiff = payload.timeDiff
      const roomRes = await getShopRoomList(payload.shopId)
      const { reserveTimeList, reserveTimeList_30, reserveTimeList_60 } = useShopTime(payload.shopId)

      let roomList = roomRes.data.list
      commit('changeRoomList', roomList)
      let reserveList = []
      let roomReserve = []
      Promise.all(
        roomList.map(async (item, index) => {
          reserveList[index] = {
            index,
            reserveList: []
          }
          return await getRoomReserveList(
            payload.shopId,
            item.roomId,
            payload.date
          )
        })
      ).then((reserveRes) => {
        reserveRes.forEach((el, indey) => {
          let list = el.data.list
          list.forEach((el, indez) => {
            let timeList = el.timelist
            // 解决没预约时间的bug
            if (timeList.length == 0) return

            let ariveTime = el.arriveTime.slice(0, 5)
            let date1, date2, date3, date4, height, firstIndex

            date1 = dayjs('2022-12-12' + RESERVE_START_TIME)
            date2 = dayjs('2022-12-12' + ariveTime)
            if (timeDiff == 10) {
              firstIndex = date2.diff(date1, 'minute') / 10
            } else if (timeDiff == 30) {
              firstIndex = date2.diff(date1, 'minute') / 30
            } else if (timeDiff == 60) {
              firstIndex = date2.diff(date1, 'minute') / 60
            }

            let ariveTimeIndex = timeList.findIndex(
              (elem) => elem.appTime == ariveTime
            )

            if (ariveTimeIndex != -1) {
              timeList = timeList.slice(ariveTimeIndex)
            }

            date3 = dayjs('2022-12-12' + timeList[0].appTime)
            date4 = dayjs('2022-12-12' + timeList[timeList.length - 1].appTime)

            if (timeDiff == 10) {
              height = date4.diff(date3, 'minute') / 10
            } else if (timeDiff == 30) {
              height = date4.diff(date3, 'minute') / 30
            } else if (timeDiff == 60) {
              height = date4.diff(date3, 'minute') / 60
            }

            list[indez].height = height
            list[indez].timelist = timeList
            list[indez].orderlist = el.ordeinfo
            list[indez].topIndex = firstIndex
          })
          roomReserve = [...roomReserve, ...el.data.list]
          reserveList[indey].reserveList = list
        })

        let newReserveTimeList = []

        if (timeDiff == 10) {
          reserveTimeList.value.forEach((item, index) => {
            newReserveTimeList[index] = item
            newReserveTimeList[index].roomList = roomList
          })
        } else if (timeDiff == 30) {
          reserveTimeList_30.value.forEach((item, index) => {
            newReserveTimeList[index] = item
            newReserveTimeList[index].roomList = roomList
          })
        } else if (timeDiff == 60) {
          reserveTimeList_60.value.forEach((item, index) => {
            newReserveTimeList[index] = item
            newReserveTimeList[index].roomList = roomList
          })
        }

        let appStatus1 = roomReserve.filter((item) => item.appStatus == 1)
        let appStatus2 = roomReserve.filter((item) => item.appStatus == 2)
        let appStatus3 = roomReserve.filter((item) => item.appStatus == 3)
        let appStatus4 = roomReserve.filter((item) => item.appStatus == 4)
        let appStatus5 = roomReserve.filter((item) => item.appStatus == 5)
        let appStatus6 = roomReserve.filter((item) => item.appStatus == 6)
        commit('changeRoomReserveStateInfo', [
          appStatus1.length,
          appStatus2.length,
          appStatus3.length,
          appStatus4.length,
          appStatus5.length,
          appStatus6.length
        ])

        commit('changeRoomTimeList', newReserveTimeList)
        commit('changeRoomReserveList', reserveList)
      })
    },
    async getReservePaySourceListAction({ commit }, payload) {
      const res = await getReservePaySourceList()
      commit('changeReservePaySourceList', res.data.list)
    },
    async setTeacherTimeOccopyAction({ commit }, payload) {
      const res = await setTeacherTimeOccupy(payload)
      tips(res)
    },
    async addReserveRuleAction({ commit }, payload) {
      let obj = { ...payload }
      if (obj.serveOccupyTimeRadio == '0') {
        obj.serveOccupyFlag = 0
      }
      if (obj.cancelTimeLimitRadio == '0') {
        obj.cancelTimeLimit = 0
      }
      if (obj.autoCancelRadio == '0') {
        obj.autoCancel = 0
      }

      obj.startMinutes = Number(obj.startMinutes)
      obj.endMinutes = obj.endMinutes * 24 * 60
      delete obj.serveOccupyTimeRadio
      delete obj.cancelTimeLimitRadio
      delete obj.autoCancelRadio
      delete obj.timeCategory

      const res = await addReserveRule(obj)
      tips(res)
      if (res.code == 0) {
        emitter.emit('changeReserveRuleListPageContent')
        router.push('/main/reserve/site')
      }
    },
    async getReserveRuleListAction({ commit }, payload) {
      const res = await getReserveRuleList(payload)
      commit('changeReserveRuleList', res.data.list)
    },
    async updateReserveRuleAction({ commit }, payload) {
      let obj = { ...payload }
      if (obj.serveOccupyTimeRadio == '0') {
        obj.serveOccupyFlag = 0
      }
      if (obj.cancelTimeLimitRadio == '0') {
        obj.cancelTimeLimit = 0
      }
      if (obj.autoCancelRadio == '0') {
        obj.autoCancel = 0
      }

      obj.startMinutes = Number(obj.startMinutes)
      obj.endMinutes = obj.endMinutes * 24 * 60
      delete obj.serveOccupyTimeRadio
      delete obj.cancelTimeLimitRadio
      delete obj.autoCancelRadio
      delete obj.timeCategory

      const res = await updateReserveRule(obj)
      tips(res)
      if (res.code == 0) {
        emitter.emit('changeReserveRuleListPageContent')
        emitter.emit('changeReserveRuleListCurrentindex')
      }
    },
    async removeReserveRuleAction({ commit }, payload) {
      const res = await removeReserveRule(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('changeReserveRuleListPageContent')
      }
    }
  }
}

export default reserveModule
