import { request2 } from '@/service/request2'
import { request3 } from '@/service/request3'
import { request4 } from '@/service/request4'
import qs from 'qs'
import cache from "@/utils/cache"

export function saveStaffRole(obj) {
  return request2({
    url: '/module/new-role',
    method: 'post',
    data: obj
  })
}

export function addSchedule(obj) {
  return request3({
    url: '/schedule/new-shift',
    method: 'post',
    data: obj
  })
}

export function getScheduleList(companyId, shopId) {
  return request3({
    url: `/schedule/schedules/${companyId}/${shopId}`,
    method: 'post'
  })
}

export function getStaffRankList(obj) {
  return request3({
    url: `/job/level/list`,
    method: 'post',
    data: obj
  })
}

export function getStaffDepartmentList(companyId) {
  return request3({
    url: `/permission/department/${companyId}`,
    method: 'post'
  })
}

export function addStaffAction(obj) {
  return request3({
    url: `/permission/new-admin-user`,
    method: 'post',
    data: obj,
    header: {
      'Content-Type': 'application/json'
    }
  })
}

export function getStaffList(obj) {
  return request3({
    url: `/permission/admin-users`,
    method: 'post',
    data: obj
  })
}

export function getShopTeacherList(shopId) {
  return request4({
    url: `/manager/admin/get-simple-emp-list/${shopId}`,
    method: 'post'
  })
}

export function getPageModelList() {
  return request3({
    url: `/permission/module/all`,
    method: 'post'
  })
}

export function getModelAllPage(id) {
  return request3({
    url: `/permission/module/allpage/${id}`,
    method: 'post'
  })
}

export function getModelAllPageBtn(pageid) {
  return request3({
    url: `/permission/permission-funcs/${pageid}`,
    method: 'post'
  })
}

export function getRoleList(kindId, companyId) {
  return request3({
    url: `/permission/module/get-role-by-id/${kindId}/${companyId}`,
    method: 'post'
  })
}

export function setStaffRoleBind(obj) {
  return request3({
    url: `/permission/add-user-to-role`,
    method: 'post',
    data: obj
  })
}

export function setStaffPassword(obj) {
  return request3({
    url: `/permission/module/update-emp-password`,
    method: 'post',
    data: obj
  })
}

//获取用户详情
export function getStaffDetail(userId, companyId) {
  return request4({
    url: `/adminUser/web/getEmp?id=${userId}`,
    method: 'get'
  })
}

export function getStaffBelongShop(userd, companyId) {
  return request3({
    url: `/permission/module/get-emp-shop/${userd}/${companyId}`,
    method: 'post'
  })
}

export function getStaffServeBindList(userId) {
  return request3({
    url: `/permission/user-serve/${userId}`,
    method: 'post'
  })
}

export function setStaffServeBind(serveId, userId, companyId, paraId) {
  return request3({
    url: `/permission/module/new-emp-serve/${serveId}/${userId}/${companyId}/${paraId}`,
    method: 'post'
  })
}

export function removeStaffServeBind(serveId, userId, companyId, paraId) {
  return request3({
    url: `/permission/module/update-emp-serve/${serveId}/${userId}/${companyId}/${paraId}`,
    method: 'post'
  })
}

export function getStaffPositionList(companyId) {
  return request3({
    url: `/permission/user-job/${companyId}`,
    method: 'post'
  })
}

export function getStaffChildRankList(parentId) {
  return request3({
    url: `/permission/sublevel/${parentId}`,
    method: 'post'
  })
}

export function getRoleCategoryList(companyId) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request3({
    url: `/permission/module/kind-roles/${companyId}/${cashUid}`,
    method: 'post'
  })
}

export function getRoleIdUserList(roleId, companyId) {
  return request3({
    url: `/permission/module/get-role-user/${roleId}/${companyId}`,
    method: 'post'
  })
}

export function removeRoleUserBind(roleId, userId, companyId) {
  return request3({
    url: `/permission/module/update-emp-role/${roleId}/${userId}/${companyId}`,
    method: 'post'
  })
}

export function updateStaffInfo(obj) {
  return request3({
    url: `/permission/update-emp-info`,
    method: 'post',
    data: obj
  })
}

export function updateScheduleState(obj) {
  return request3({
    url: `/schedule/update-shift/`,
    method: 'post',
    data: obj
  })
}

export function shopScheduleBind(shopId, shiftId) {
  return request3({
    url: `/schedule/add-shop-shift/${shopId}/${shiftId}`,
    method: 'post'
  })
}

export function removeShopScheduleBind(shopId, shiftId) {
  return request3({
    url: `/schedule/remove-shop-shift/${shopId}/${shiftId}`,
    method: 'post'
  })
}

export function addScheduleWeek(obj) {
  return request3({
    url: `/schedule/new-period`,
    method: 'post',
    data: obj
  })
}

export function addScheduleWeekSaveShift(dayNo, perId, shifId) {
  return request3({
    url: `/schedule/add-period-day/${dayNo}/${perId}/${shifId}`,
    method: 'post'
  })
}

export function getScheduleWeekList(companyId, shopId) {
  return request3({
    url: `/schedule/new-all-period/${companyId}/${shopId}`,
    method: 'post'
  })
}

export function getScheduleWeekShiftList(perId) {
  return request3({
    url: `/schedule/shift-of-period/${perId}`,
    method: 'post'
  })
}

export function setScheduleWeekShopBind(shopId, perId, companyId) {
  return request3({
    url: `/schedule/add-shop-period/${shopId}/${perId}/${companyId}`,
    method: 'post'
  })
}

// export function getScheduleWeekShopBind(shopId, perId, companyId) {
//   return request3({
//     url: `/schedule/shop-period/${companyId}`,
//     method: 'post'
//   })
// }

export function getScheduleBindList(shiftId) {
  return request3({
    url: `/schedule/shift-shop/${shiftId}`,
    method: 'post'
  })
}

export function addStaffRank(companyId, levelName) {
  return request3({
    url: `/permission/save-job-title/${companyId}/${levelName}`,
    method: 'post'
  })
}

export function getStaffAllRankList(companyId) {
  return request3({
    url: `/permission/get-job-title/${companyId}`,
    method: 'post'
  })
}

export function addPositionRank(parentId, companyId, levelName) {
  return request3({
    url: `/permission/save-job-level/${parentId}/${companyId}/${levelName}`,
    method: 'post'
  })
}

export function saveRolePermission(roleId, modules, pages, permissions) {
  return request3({
    url: `/permission/save-role-permission/${roleId}/${modules}/${pages}/${permissions}`,
    method: 'post'
  })
}

export function addDepartment(companyId, dpName) {
  return request3({
    url: `/permission/new-department/${companyId}/${dpName}/0/0`,
    method: 'post'
  })
}

export function getDepartmentList(companyId) {
  return request3({
    url: `/permission/department/${companyId}`,
    method: 'post'
  })
}

export function getDepList(companyId, flag = 0) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/permission/department/${companyId}/${flag}/${cashUid}`,
    method: 'post'
  })
}

export function saveStaffDeparentBind(dpId, userId) {
  return request3({
    url: `/permission/bind-user-dep/${dpId}/${userId}`,
    method: 'post'
  })
}

export function getStaffDeparentBindList(dpId, companyId) {
  return request3({
    url: `/permission/get-depart-user/${dpId}/${companyId}`,
    method: 'post'
  })
}

export function setStaffSchedule(obj) {
  return request3({
    url: `/schedule/new-emp-shift`,
    method: 'post',
    data: obj
  })
}

export function getStaffScheduleWeekBindList(perId) {
  return request3({
    url: `/schedule/period-shop/${perId}`,
    method: 'post'
  })
}

export function removeStaffScheduleWeekBind(obj) {
  return request3({
    url: `/schedule/update-shop-period`,
    method: 'post',
    data: obj
  })
}

export function updateScheduleInfo(id, status, companyId, startTime, endTime) {
  return request3({
    url: `/schedule/update-shift/${id}/${status}/${companyId}/${startTime}/${endTime}`,
    method: 'post'
  })
}

export function updateScheduleWeekInfo(obj) {
  return request3({
    url: `/schedule/update-period`,
    method: 'post',
    data: obj
  })
}

export function updateWeekStatus(status, companyId, id) {
  return request3({
    url: `/schedule/update-period-status/${status}/${companyId}/${id}`,
    method: 'post'
  })
}

export function batchRemoveDepartmentStaff(userId) {
  return request3({
    url: `/permission/dep-multi-unbind/${userId}`,
    method: 'post'
  })
}

export function getStaffSchedDetailList(obj) {
  return request3({
    url: `/schedule/get-emp-date`,
    method: 'post',
    data: obj
  })
}

export function getStaffRankBindList(subJobLevel, companyId) {
  return request3({
    url: `/permission/subjob-emp-list/${subJobLevel}/${companyId}`,
    method: 'post'
  })
}

export function setStaffRankBind(userId, jobLevel, subJobLevel) {
  return request3({
    url: `/permission/update-emp-joblevel/${userId}/${jobLevel}/${subJobLevel}`,
    method: 'post'
  })
}

export function setStaffWeekSchedule(obj) {
  return request3({
    url: `/schedule/save-emp-shift-by-period`,
    method: 'post',
    data: obj
  })
}

//切换用户状态
export function setStaffState(id) {
  return request4({
    url: `/adminUser/web/enabledEmp?id=${id}`,
    method: 'get'
  })
}

export function getBelongShop(companyId) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/permission/shop-basic/${companyId}/${cashUid}`,
    method: 'post'
  })
}

export function batchSetState(obj) {
  return request3({
    url: `/permission/multi-update-accounts`,
    method: 'post',
    data: obj
  })
}

export function updateStaffScheduleInfo(obj) {
  return request3({
    url: `/schedule/update-emp-shift`,
    method: 'post',
    data: obj
  })
}

export function removePeriod(id, companyId) {
  return request3({
    url: `/schedule/del-period/${id}/${companyId}`,
    method: 'post'
  })
}

export function removeShift(shiftId) {
  return request3({
    url: `/schedule/del-shift/${shiftId}`,
    method: 'post'
  })
}

export function getRankServeList(lvid) {
  return request3({
    url: `/admin/serve-from-job-level/${lvid}`,
    method: 'get'
  })
}
export function getResourceList() {
  return request3({
    url: `/authority/resourceList`,
    method: 'get'
  })
}
export function getUserAuthorityResourceListIds(data) {
  return request3({
    url: `/authority/getRoleAuthority`,
    method: 'get',
    params:data
  })
}
export function saveAuthority(obj) {
  return request3({
    url: `/authority/saveAuthority`,
    method: 'post',
    data: obj
  })
}

export function getOrderRewardList(obj) {
  return request4({
    url: `/order/web/getWebTipOrderList`,
    method: 'post',
    data: obj
  })
}

export function setStaffReserveState(companyId, userId, status) {
  return request4({
    url: `/manager/admin/update-show-shop/${companyId}/${userId}/${status}`,
    method: 'post'
  })
}

export function getStaffScoreList(obj) {
  return request4({
    url: `/manager/money/count-emp-yeji`,
    method: 'post',
    data: obj
  })
}

export function getStaffTichengList(obj) {
  return request4({
    url: `/manager/money/count-emp-ticheng`,
    method: 'post',
    data: obj
  })
}

export function getStaffyejimignxi(obj) {
  return request4({
    url: `/manager/money/summary-yeji`,
    method: 'post',
    data: obj
  })
}

export function getStaffkaidanyeji(obj) {
  return request4({
    url: `/manager/money/yeji-orders`,
    method: 'post',
    data: obj
  })
}

export function getStaffzongheyeji(obj) {
  return request4({
    url: `/manager/money/general-emp-yeji`,
    method: 'post',
    data: obj
  })
}

export function getShopList(shopId = 0) {
  return request4({
    url: `/manager/money/get-shop-emp/${shopId}`,
    method: 'post'
  })
}

export function getStaffzongheticheng(obj) {
  return request4({
    url: `/manager/money/general-emp-ticheng`,
    method: 'post',
    data: obj
  })
}

export function getStaffdanciyeji(obj) {
  return request4({
    url: `/manager/money/new-count-emp-yeji`,
    method: 'post',
    data: obj
  })
}

export function getStaffdanciticheng(obj) {
  return request4({
    url: `/manager/money/count-emp-ticheng-by-payway`,
    method: 'post',
    data: obj
  })
}

export function updateRankInfo(name, id) {
  return request4({
    url: `/manager/admin/update-joblevel/${name}/${id}`,
    method: 'post'
  })
}

export function updatezhiweiInfo(name, id) {
  return request4({
    url: `/manager/permission/update-job-name/${name}/${id}`,
    method: 'post'
  })
}

export function removeRank(id) {
  return request4({
    url: `/manager/admin/del-joblevel/${id}`,
    method: 'post'
  })
}

export function removeZhiwei(id) {
  return request4({
    url: `/manager/permission/del-job/${id}`,
    method: 'post'
  })
}

export function addZhiwei(obj) {
  return request4({
    url: `/manager/permission/multi-new-joblevel`,
    method: 'post',
    data: obj
  })
}

export function updateRole(name, id) {
  return request4({
    url: `/manager/permission/update-role-name/${name}/${id}`,
    method: 'post'
  })
}

export function removeRole(id) {
  return request4({
    url: `/manager/permission/del-role/${id}`,
    method: 'post'
  })
}

export function getRankBindServeList(lvid) {
  return request4({
    url: `/manager/admin/serves-from-sublevel/${lvid}`,
    method: 'post'
  })
}

export function getServeList(obj) {
  return request4({
    url: '/manager/admin/serve-list',
    method: 'post',
    data: obj
  })
}

export function setRankBindServeList(obj) {
  return request4({
    url: `/manager/admin/bind-serve-to-joblevel`,
    method: 'post',
    data: obj
  })
}

export function getAllRankBindServeList(lvid) {
  return request4({
    url: `/manager/admin/all-serves-from-joblevel/${lvid}`,
    method: 'post'
  })
}

export function updateDepInfo(name, id) {
  return request4({
    url: `/manager/admin/update-dep-name/${name}/${id}`,
    method: 'post'
  })
}

export function removeDepInfo(id) {
  return request4({
    url: `/manager/admin/del-department/${id}`,
    method: 'post'
  })
}

export function updateBanci(obj) {
  return request4({
    url: `/manager/schedule/update-shift`,
    method: 'post',
    data: obj
  })
}

export function getPaibanShopList(shopId) {
  return request4({
    url: `/manager/admin/get-app-emp-list/${shopId}`,
    method: 'post',
  })
}

export function getServeCategoryList(companyId) {
  return request4({
    url: `/manager/permission/serve-cata/${companyId}`,
    method: 'post'
  })
}

export function getShopList2(companyId) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/order/shop-basic/${companyId}/${cashUid}`,
    method: 'post'
  })
}

export function getDanbiTc(obj) {
  return request4({
    url: `/manager/money/money-emps-ticheng`,
    method: 'post',
    data:obj
  })
}
export function getShopTcYj(obj) {
  return request4({
    url: `/manager/money/shop-emps-yeji-ticheng`,
    method: 'post',
    data:obj
  })
}

// 权限列表
export function getUserResourceList(userId) {
  return request4({
    url: `/manager/authority/getUserResourceList?userId=${userId}`,
    method: 'get',
  })
}
//获取角色
export function getAllRoleList(isShop) {
  return request4({
    url: `/manager/permission/getAllRole` + (isShop == null ? '' : '?isShop=' + isShop),
    method: 'get',
  })
}
// 按钮权限列表
export function getUserButtonTagList() {
  return request4({
    url: `/adminUser/web/getUserButtonTagList`,
    method: 'get',
  })
}
//考核表下拉列表
export function getCheckTableDownList(roleId) {
  return request4({
    url: `/checkTable/web/getCheckTableDownList?roleId=${roleId}`,
    method: 'get',
  })
}
//获取考核表下指标
export function getCheckTableIndicatorsList(tableId) {
  return request4({
    url: `/checkIndicators/web/getCheckTableIndicatorsList?tableId=${tableId}`,
    method: 'get',
  })
}
//获取未被选择进考核的用户列表
export function getUnCheckUserList(obj) {
  return request4({
    url: `/check/web/getUnCheckUserList`,
    method: 'post',
    data:obj
  })
}

//获取员工树型
export function getAdminUSerTree(obj) {
  return request4({
    url: `/adminUser/web/getAdminUSerTree`,
    method: 'post',
    data:obj
  })
}

//创建用户
export function createEmp(obj) {
  return request4({
    url: `/adminUser/web/createEmp`,
    method: 'post',
    data:obj
  })
}

//编辑用户
export function editEmp(obj) {
  return request4({
    url: `/adminUser/web/editEmp`,
    method: 'PUT',
    data:obj
  })
}

//获取员工列表
export function getEmpList(obj) {
  return request4({
    url: `/adminUser/web/getEmpList`,
    method: 'post',
    data:obj
  })
}

//切换员工看板展示状态
export function showInShop(obj) {
  return request4({
    url: `/adminUser/web/showInShop`,
    method: 'post',
    data:obj
  })
}

//绑定职级与服务
export function bingJobLevelServe(obj) {
  return request4({
    url: `/serve/web/bingJobLevelServe`,
    method: 'post',
    data:obj
  })
}