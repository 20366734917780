import { COMPANY_ID } from '@/common/constant'
import pay from '@/router/main/order/pay'
import {
  getUserList,
  getUserLaiyuan,
  getShopList,
  getUserDetail,
  editUserInfo,
  getUsetGenjinList,
  getUsetCommrntList,
  getUserCardList,
  getExchangeCouponRecordList
} from '@/service/main/customer'
import {getOrderRewardList} from '@/service/main/staff'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import { tips } from '@/utils/tips'

const customerModule = {
  namespaced: true,
  state() {
    return {
      memberInfoList: [],
      memberInfoCount: 0,
      tradeOrderList: [
        {
          buy_time: '2022-01-28 17:50:07',
          order_num: 123,
          avatar: 'https://img.xiaopiu.com/userImages/img1914617ea5658180.png',
          goods_name: '全身舒缓按摩',
          price: 188,
          buy_num: 1,
          buy_shop: '一指阁静安寺店',
          teacher: '刘德华',
          state: 1
        },
        {
          buy_time: '2022-01-28 17:50:07',
          order_num: 1,
          avatar: 'https://img.xiaopiu.com/userImages/img1914617ea5658180.png',
          goods_name: '全身舒缓按摩',
          price: 188,
          buy_num: 1,
          buy_shop: '一指阁静安寺店',
          teacher: '马化腾',
          state: 1
        }
      ],
      titleList: [
        '商品',
        '单价',
        '数量',
        '是否点客',
        '金额',
        '下单门店',
        '消耗/销售',
        '状态'
      ],
      followOrderList: [
        {
          buy_time: '2022-01-28 17:50:07',
          order_num: 123,
          avatar: 'https://img.xiaopiu.com/userImages/img1914617ea5658180.png',
          goods_name: '全身舒缓按摩',
          comment: '有点牢记劳损，记得注意',
          follower: '刘德华',
          follow_up_time: '2022-01-28 17:50:07'
        },
        {
          buy_time: '2022-01-28 17:50:07',
          order_num: 123,
          avatar: 'https://img.xiaopiu.com/userImages/img1914617ea5658180.png',
          goods_name: '全身舒缓按摩',
          comment: '有点牢记劳损，记得注意',
          follower: '刘德华',
          follow_up_time: '2022-01-28 17:50:07'
        }
      ],
      commentOrderList: [
        {
          goods_name: '全身舒缓按摩',
          order_num: 123,
          buy_shop: '一指阁静安寺店',
          isShow: 0,
          commentValue: '',
          commentTime: '2022-01-28 17:50:07',
          commentObject: '张海',
          star: 5
        },
        {
          goods_name: '全身舒缓按摩',
          order_num: 123,
          buy_shop: '一指阁静安寺店',
          isShow: 1,
          commentValue: '很好，非常舒服',
          commentTime: '2022-01-28 17:50:07',
          commentObject: '张海',
          star: 3.5
        }
      ],
      cardInfoList: [
        {
          cardName: '随心卡',
          cardType: '次卡',
          remainingTimes: '剩余12次',
          balance: 699,
          efficient: '2023-01-26前有效',
          state: 1
        },
        {
          cardName: '运动体能恢复卡',
          cardType: '次卡',
          remainingTimes: '剩余1次',
          balance: 699,
          efficient: '2023-04-06前有效',
          state: 1
        },
        {
          cardName: '礼品卡',
          cardType: '次卡',
          remainingTimes: '剩余61次',
          balance: 699,
          efficient: '2026-04-06前有效',
          state: 1
        },
        {
          cardName: '储值卡',
          cardType: '次卡',
          remainingTimes: '剩余1次',
          balance: 6999,
          efficient: '2023-04-06前有效',
          state: 1
        }
      ],
      couponInfoList: [
        {
          name: '现金券',
          type: 0,
          discount: 100,
          useScope: '所有门店 所有项目通用',
          validityPeriod: '已绑定 2023-01-26有效',
          state: 0
        },
        {
          name: '体验券',
          type: 1,
          discount: 100,
          useScope: '所有门店 所有项目通用',
          validityPeriod: '已绑定 2023-01-26有效',
          state: 0
        },
        {
          name: '体验券',
          type: 1,
          discount: 1,
          useScope: '所有门店 所有项目通用',
          validityPeriod: '已绑定 2023-01-26有效',
          state: 1
        }
      ],
      clientInfoList: [
        {
          name: {
            name: '张丽丽',
            phone: 123456789,
            pic: ''
          },
          card_num: 123456,
          source: '超级猩猩',
          registration_time: '2022年1月30日 17:50',
          last_arrival_time: '2022年1月30日 17:50',
          shop: '一指阁淮海路店'
        }
      ],
      userLaiyaunList: [],
      shopList: [],
      userDetail: '',
      userDashangList: '',
      userDashangListCount: 0,
      userCommentList: [],
      userCommentListCount: 0,
      userCardList:[]
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changeMemberList(state, list) {
      state.memberInfoList = list
    },
    changeMemberListCount(state, count) {
      state.memberInfoCount = count
    },
    changeUserLaiyaun(state, list) {
      state.userLaiyaunList = list
    },
    changeShopList(state, list) {
      state.shopList = list
    },
    changeUserDetail(state, info) {
      state.userDetail = info
    },
    changeUserDashangList(state, list) {
      state.userDashangList = list
    },
    changeUserDashangListCount(state, count) {
      state.userDashangListCount = count
    },
    changeUserCouponList(state, list) {
      state.userCouponList = list
    },
    changeUserCouponListCount(state, count) {
      state.userCouponListCount = count
    },
    changeUsaerCommentList(state, list) {
      state.userCommentList = list
    },
    changeUsaerCommentListCount(state, count) {
      state.userCommentListCount = count
    },
    changeUserCardList(state, list) {
      state.userCardList = list
    }
  },
  actions: {
    async getmemberInfoListAction({ commit }, payload) {
      const adminInfo = cache.getCache('adminInfo')
      const res = await getUserList({...payload,userId:adminInfo.userId})
      commit('changeMemberList', res.data.list)
      commit('changeMemberListCount', res.data.total)
    },
    async getUserLaiyuanAction({ commit }, payload) {
      const res = await getUserLaiyuan()
      commit('changeUserLaiyaun', res.data.list)
    },
    async getShopListAction({ commit }, payload) {
      const res = await getShopList(COMPANY_ID)
      commit('changeShopList', res.data.list)
    },
    async getUsderDetailAction({ commit }, payload) {
      const res = await getUserDetail(payload, COMPANY_ID)
      commit('changeUserDetail', res.data)
    },
    async editUserInfoAction({ commit }, payload) {
      const res = await editUserInfo(payload)
      tips(res)
    },
    async getUsetDashangListAction({ commit }, payload) {
      const res = await getOrderRewardList(payload)
      commit('changeUserDashangList', res.data.list)
      commit('changeUserDashangListCount', res.data.total)
    },
    async getUsetCouponListAction({ commit }, payload) {
      const res = await getExchangeCouponRecordList(payload)
      commit('changeUserCouponList', res.data?res.data.list:[])
      commit('changeUserCouponListCount', res.data?res.data.total:0)
    },
    async getUsetCommrntListAction({ commit }, payload) {
      if (payload.date) {
        delete payload.date
      }
      const res = await getUsetCommrntList(payload)
      commit('changeUsaerCommentList', res.data.list)
      commit('changeUsaerCommentListCount', Number(res.data.total))
    },
    async getUserCardListAction({ commit }, payload) {
      const res = await getUserCardList(payload)
      commit('changeUserCardList', res.data)
    }
  }
}

export default customerModule
