let BASE_URL = ''
const TIME_OUT = 60000

if (process.env.NODE_ENV === 'development') {
  BASE_URL = '/saas-biz-service'
} else if (process.env.NODE_ENV === 'production') {
  
  //'https://platform.teamcafe.cn/saas-biz-service'
  BASE_URL = 'https://yizhigeapi.yizhige.cn/saas-biz-service'
  // BASE_URL = 'http://47.117.1.209:40018/saas-biz-service'
} else {
  BASE_URL = 'https://yizhigeapi.yizhige.cn/saas-biz-service'
  // BASE_URL = 'http://47.117.1.209:40018/saas-biz-service'
}

export { BASE_URL, TIME_OUT }
