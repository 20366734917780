import cache from '@/utils/cache'
export default function btnPerms(app) {
    app.directive('has', {
        mounted(el, binding) {
            if (!permsJudge(binding.value)) {
                el.parentNode.removeChild(el);
            }
            function permsJudge(value){
                let perms = cache.getCache("tagList");
                for (let item of perms) {
                    if (item === value) {
                        return true;
                    }
                }
                return false;
            }
        }
    });
}
