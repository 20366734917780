const indexModule = {
  namespaced: true,
  state() {
    return {
      updateTime: '2022-01-28 11:23:01',
      
    }
  },
  getters: {},
  mutations: {},
  actions: {}
}

export default indexModule
