const commissionSettingsModule = {
  namespaced: true,
  state() {
    return {
      planSiteList: [
        {
          name: '测试方案',
          use_shop_num: 10,
          use_grade: '中级理疗师，高级理疗师'
        },
        {
          name: '测试方案2',
          use_shop_num: 2,
          use_grade: '高级理疗师'
        }
      ]
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {},
  actions: {}
}

export default commissionSettingsModule
