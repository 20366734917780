<template>
  <div class="app">
    <el-config-provider :locale="locale">
      <router-view></router-view>
    </el-config-provider>
  </div>
</template>

<script lang="js">
import { defineComponent,ref ,onUnmounted,onMounted,watch} from 'vue'
import { useRouter,onBeforeRouteUpdate } from 'vue-router';
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default defineComponent({
  name: 'App',
  components: {
    ElConfigProvider
  },

    
  setup() {
    
    const router = useRouter()
    watch(() => router.currentRoute.value.path,(toPath) => {
        //要执行的方法
        // console.log('=====',toPath)
     },{immediate: true,deep: true})
    return {
      locale: zhCn,

    }
  }
})
</script>

<style lang="less">
.app {
  height: 100%;
}
.el-menu--popup {
  max-height: 280px !important;
  overflow-y: auto;
}
.el-menu--popup::-webkit-scrollbar {
  width: 8px;
  /*对垂直流动条有效*/
  height: 8px;
  /*对水平流动条有效*/
}

// /*定义滚动条的轨道颜色、内阴影及圆角*/
.el-menu--popup::-webkit-scrollbar-track {
  background-color: #e9e8e8;
  border-radius: 6px;
}

// /*定义滑块颜色、内阴影及圆角*/
.el-menu--popup::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: rgba(179, 178, 178, 0.7);
}

// html {
//   -webkit-filter: grayscale(100%);
//   -moz-filter: grayscale(100%);
//   -o-filter: grayscale(100%);
//   filter: grayscale(100%);
//   filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
// }
.eloption .el-select-dropdown__wrap {
  max-height: 140px !important;
}
.tooltip-width{font-size: 14px; max-width:50% }
</style>
