import {
  saveStaffRole,
  addSchedule,
  getStaffRankList,
  addStaffAction,
  getStaffList,
  getRoleList,
  getStaffChildRankList,
  getStaffDepartmentList,
  setStaffRoleBind,
  setStaffPassword,
  getStaffDetail,
  getStaffBelongShop,
  getStaffServeBindList,
  setStaffServeBind,
  removeStaffServeBind,
  getStaffPositionList,
  getRoleCategoryList,
  getRoleIdUserList,
  removeRoleUserBind,
  updateStaffInfo,
  getPageModelList,
  getModelAllPage,
  getModelAllPageBtn,
  getScheduleList,
  updateScheduleState,
  shopScheduleBind,
  addScheduleWeek,
  addScheduleWeekSaveShift,
  getScheduleWeekList,
  getScheduleWeekShiftList,
  setScheduleWeekShopBind,
  getScheduleBindList,
  removeShopScheduleBind,
  addStaffRank,
  getStaffAllRankList,
  addPositionRank,
  saveRolePermission,
  addDepartment,
  getDepartmentList,
  saveStaffDeparentBind,
  getStaffDeparentBindList,
  setStaffSchedule,
  getStaffScheduleWeekBindList,
  removeStaffScheduleWeekBind,
  updateScheduleInfo,
  updateScheduleWeekInfo,
  updateWeekStatus,
  batchRemoveDepartmentStaff,
  getStaffSchedDetailList,
  getStaffRankBindList,
  setStaffRankBind,
  setStaffWeekSchedule,
  setStaffState,
  getBelongShop,
  batchSetState,
  updateStaffScheduleInfo,
  getShopTeacherList,
  removePeriod,
  removeShift,
  getStaffScoreList,
  getStaffTichengList,
  getStaffkaidanyeji,
  getStaffzongheyeji,
  getShopList,
  getStaffzongheticheng,
  getStaffdanciyeji,
  getStaffdanciticheng,
  addZhiwei,
  getDepList,
  updateBanci,
  getPaibanShopList,
  getAllRoleList
} from '@/service/main/staff'
import { tips } from '@/utils/tips'
import emitter from '@/utils/eventbus'
import dayjs from 'dayjs'
import { COMPANY_ID } from '@/common/constant'
import { getDateData } from '@/utils/time'
import { deepClone } from '@/utils/clone'
import cache from '@/utils/cache'
const shopModule = {
  namespaced: true,
  state() {
    return {
      test: '123',
      staffList: [],
      staffCount: 5,
      rolestaffList: [],
      shiftsettingstaffList: [ ],
      weekShiftSettingStaffList: [],
      roleInfoList: [],
      departmentInfoList: [],
      staffRankList: [],
      allRoleList: [],
      departmentList: [],
      shopList: [],
      staffDetail: {},
      staffServeBindList: [],
      staffPositionList: [],
      staffChildRankList: [],
      roleCategoryList: [],
      roleIdStaffList: [],
      rolePermissionList: [],
      scheduleWeekList: [],
      scheduleBindShopIdList: [],
      staffAllRankList: [],
      staffDepartmentList: [],
      staffDeparentBindList: [],
      scheduleStaffList: [],
      schedulestaffCount: 0,
      staffScheduleWeekBindList: [],
      positionRankList: [],
      staffRankBindList: [],
      belongShopList: [],
      roleList: [],
      staffyejiList: [],
      stafftichengList: [],
      staffyejiTotal: 0,
      zongheyejiList: [],
      zongheyejiTotal: 0,
      zongheyejiTotalInfo: {},
      zonghetichengTotal: 0,
      zonghetichengTotalInfo: {},
      zonghetichengList: [],
      stafftichengTotalInfo: {},
      staffyejiTotalInfo: {},
      allRoleLists:[]
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        // pageName = pageName.toLowerCase()
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        pageName = pageName.toLowerCase()
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changezonghetichengList(state, list) {
      state.zonghetichengList = list
    },
    changezonghetichengTotalInfo(state, info) {
      state.zonghetichengTotalInfo = info
    },
    changezonghetichengTotal(state, total) {
      state.zonghetichengTotal = total
    },
    changezongheyejiTotalInfo(state, info) {
      state.zongheyejiTotalInfo = info
    },
    changezongheyejiList(state, list) {
      state.zongheyejiList = list
    },
    changezongheyejiTotal(state, total) {
      state.zongheyejiTotal = total
    },
    changeStaffRankList(state, list) {
      state.staffRankList = list
    },
    changeStaffList(state, list) {
      state.staffList = list
    },
    changeStateListCount(state, count) {
      state.staffCount = count
    },
    changeAllRoleList(state, list) {
      state.allRoleList = [...state.allRoleList, ...list]
    },
    changeDeparentList(state, list) {
      state.departmentList = list
    },
    changeShopList(state, list) {
      state.shopList = list
    },
    changeStaffDetail(state, list) {
      state.staffDetail = list
    },
    changeStaffServeBind(state, list) {
      state.staffServeBindList = list
    },
    changeStaffPositionList(state, list) {
      state.staffPositionList = list
    },
    changeAllRoleLists(state, list) {
      state.allRoleLists = list
    },
    changeStaffChildRankList(state, list) {
      state.staffChildRankList = list
    },
    changeRoleCategoryList(state, list) {
      state.roleCategoryList = list
    },
    changeSetRoleIdUserList(state, list) {
      state.roleIdStaffList = list
    },
    changeSystemPermission(state, list) {
      state.rolePermissionList = list
    },
    changeShiftsettingstaffList(state, list) {
      state.shiftsettingstaffList = list
    },
    changeScheduleWeekList(state, list) {
      state.scheduleWeekList = list
    },
    changeScheduleBindShopIdList(state, list) {
      state.scheduleBindShopIdList = list
    },
    changeStaffAllRankList(state, list) {
      state.staffAllRankList = list
    },
    changeDepartmentInfoList(state, list) {
      state.staffDepartmentList = list
    },
    changeStaffDeparentBindList(state, list) {
      state.staffDeparentBindList = list
    },
    changeScheduleStaffList(state, list) {
      state.scheduleStaffList = list
    },
    changeScheduleStateListCount(state, count) {
      state.schedulestaffCount = count
    },
    changeStaffScheduleWeekBindList(state, list) {
      state.staffScheduleWeekBindList = list
    },
    changeStaffPositionRankList(state, list) {
      state.positionRankList = list
    },
    changeStaffRankBindList(state, list) {
      state.staffRankBindList = list
    },
    changeBelongShopList(state, list) {
      state.belongShopList = list
    },
    changeRoleList(state, list) {
      state.roleList = list
    },
    changeStaffyejiList(state, list) {
      state.staffyejiList = list
    },
    changeStafftichehngList(state, list) {
      state.stafftichengList = list
    },
    changeStaffyejiTotal(state, count) {
      state.staffyejiTotal = count
    },
    changeStafftichengTotalInfo(state, info) {
      state.stafftichengTotalInfo = info
    },
    changeStaffyejiTotalInfo(state, info) {
      state.staffyejiTotalInfo = info
    }
  },
  actions: {
    async saveStaffRoleAction({ commit }, payload) {
      const res = await saveStaffRole(payload)
      tips(res)
      emitter.emit('changeStaffAddROle')
    },
    async addScheduleAction({ commit }, pauload) {
      const res = await addSchedule(pauload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshSddScheduleList')
      }
    },
    async getStaffRankAction({ commit }, payload) {
      const res = await getStaffRankList(payload)
      let list = res.data.list
      Promise.all(
        list.map(async (item, index) => {
          const cres = await getStaffChildRankList(item.lvId)
          list[index].children = cres.data.list
        })
      ).then((pres) => {
        commit('changeStaffRankList', list)
      })
    },
    async addStaffAction({ commit }, payload) {
      let obj = { ...payload }
      obj.joinTime = dayjs(obj.joinTime).format('YYYY-MM-DD')
      const res = await addStaffAction(obj)
      tips(res)
      if (res.code == 0) {
        emitter.emit('changeStaffManagementTopcontentSearch')
      }
    },
    async getstaffListAction({ commit }, payload) {
      const obj = { ...payload }
      delete obj.pageName
      const res = await getStaffList(obj)
      let list = res.data.allEmp
      const sortId = (a, b) => {
        return a.status - b.status
      }
      list.sort(sortId)
      commit('changeStaffList', list)
      commit('changeStateListCount', res.data.total)
    },
    async getAllRole({ commit }, payload) {
      const res = await getRoleList(payload.kindId, payload.companyId)
      let arr = [
        {
          roleName: '总部角色',
          roleId: 0,
          children: []
        }
      ]
      let list = res.data.list
      list.forEach((item) => {
        arr[0].children.push(item)
      })
      commit('changeAllRoleList', arr)
    },
    async getStaffDeparentAction({ commit }, payload) {
      const res = await getDepList(payload.companyId, 2)
      commit('changeDeparentList', res.data.list)
    },
    async getShopRole({ commit }, payload) {
      const res = await getRoleList(payload.kindId, payload.companyId)
      let arr = [
        {
          roleName: '门店角色',
          roleId: 0,
          children: []
        }
      ]
      let list = res.data.list
      list.forEach((item) => {
        arr[0].children.push(item)
      })
      commit('changeAllRoleList', arr)
    },
    async saveStaffRoleBind({ commit }, payload) {
      const obj = { ...payload }
      let userList = obj.userList
      let roleId = obj.roleId
      let companyId = obj.companyId

      Promise.all(
        userList.map(async (item, index) => {
          let obj = {
            companyId,
            userId: item,
            roleId: roleId
          }
          return await setStaffRoleBind(obj)
        })
      ).then((pres) => {
        let flag = pres.every((item) => item.code == 0)
        if (flag) {
          tips({ code: 0 })
          emitter.emit('refreshRoleStaffPageContent')
          emitter.emit('refreshCurrentRoleStaffPageContent')
        } else {
          tips({ code: 1 })
        }
      })
    },
    async setStaffPasswordAction({ commit }, payload) {
      const res = await setStaffPassword(payload)
      tips(res)
    },
    async getStaffDetailAction({ commit }, payload) {
      if (payload[0] == undefined) return
      const res = await getStaffDetail(payload[0])
      let info = res.data
      commit('changeStaffDetail', info)
    },
    async getStaffServeBindListAction({ commit }, payload) {
      const res = await getStaffServeBindList(payload)
      let list = res.data.servelist
      commit('changeStaffServeBind', list)
    },
    async setStaffServeBindAction({ commit }, payload) {
      const res = await setStaffServeBind(...payload)

      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshStaffAssociationList')
      }
    },
    async removeStaffServeBindAction({ commit }, payload) {
      const res = await removeStaffServeBind(...payload)
      tips(res)

      if (res.code == 0) {
        emitter.emit('refreshStaffAssociationList')
      }
    },
    async getStaffPositionAction({ commit }, payload) {
      const res = await getStaffPositionList(payload)
      // console.log(res)
      commit('changeStaffPositionList', res.data.list)
    },
    async getAllRoleLists({ commit }, payload) {
      const res = await getAllRoleList(payload)
      // console.log(res)
      commit('changeAllRoleLists', res.data)
    },
    async getStaffChildRankAction({ commit }, payload) {
      const res = await getStaffChildRankList(payload)

      commit('changeStaffChildRankList', res.data.list)
    },
    async getRoleCategoryListAction({ commit }, payload) {
      const list = (await getRoleCategoryList(payload)).data.list
      let newList = list.slice(0, 2)
      const mainRes = (await getRoleList(1, payload)).data.list
      const areaRes = (await getRoleList(2, payload)).data.list
      const shopRes = (await getRoleList(3, payload)).data.list
      newList[0].roleName = '总部'
      newList[0].children = mainRes
      if (mainRes.length > 0) {
        emitter.emit('changeStaffRoleManageFirstRoId', mainRes[0].roleId)
      }
      newList[1].roleName = '门店'
      newList[1].children = shopRes
      commit('changeRoleCategoryList', newList)
    },
    async getRoleListAction({ commit }, payload) {
      const list = (await getRoleCategoryList(payload)).data.list
      const mainRes = (await getRoleList(1, payload)).data.list
      const areaRes = (await getRoleList(2, payload)).data.list
      const shopRes = (await getRoleList(3, payload)).data.list
      commit('changeRoleList', [...mainRes, ...areaRes, ...shopRes])
    },
    async getRoleIdUserListAction({ commit }, payload) {
      const res = await getRoleIdUserList(...payload)
      let list = res.data.list
      list.forEach((item, index) => {
        list[index].staffId = item.id
      })
      commit('changeSetRoleIdUserList', list)
    },
    async removeRoleUserBindAction({ commit }, payload) {
      const res = await removeRoleUserBind(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshRoleStaffPageContent')
      }
    },
    async updateStaffAction({ commit }, payload) {
      let obj = { ...payload }
      obj.joinTime = dayjs(obj.joinTime).format('YYYY-MM-DD')
      const res = await updateStaffInfo(obj)
      tips(res)

      if (res.code == 0) {
        emitter.emit('refreshStaffAssociationList')
        emitter.emit('refreshRoleStaffPageContent')
        emitter.emit('refreshStaffListPage')
        emitter.emit('refreshStaffListPageContent')
        emitter.emit('refreshStaffListDeparmentPageContent')
      }
    },
    async staffBatchRemoveAction({ commit }, payload) {
      let obj = { ...payload }
      let roleId = obj.roleId
      let staffList = obj.staffList
      let companyId = obj.companyId

      Promise.all(
        staffList.map(async (item) => {
          return await removeRoleUserBind(roleId, item.userId, companyId)
        })
      ).then((resList) => {
        let flag = resList.every((item) => item.code == 0)
        if (flag) {
          tips({ code: 0 })
          emitter.emit('refreshRoleStaffPageContent')
        } else {
          tips({ code: 1 })
        }
      })
    },
    async getSystemPermissionAction({ commit }, payload) {
      let modelList = (await getPageModelList()).data.list
      Promise.all(
        modelList.map(async (item) => {
          return await getModelAllPage(item.mid)
        })
      ).then((pageList) => {
        pageList.forEach(async (pageV, index) => {
          let pageInfoList = pageV.data.list
          modelList[index].pageInfo = pageInfoList
          Promise.all(
            pageInfoList.map(async (pageV, indey) => {
              return await getModelAllPageBtn(pageV.pid)
            })
          ).then((btnRes) => {
            btnRes.forEach((ele, indez) => {
              modelList[index].pageInfo[indez].buttonInfo = ele.data.list
            })
          })
        })
        // 此处不合理后期会修改
        setTimeout(() => {
          // console.log(modelList);
          commit('changeSystemPermission', modelList)
        }, 1000)
      })
    },
    async getshiftsettingstaffListAction({ commit }, payload) {
      const res = await getScheduleList(payload.companyId, payload.shopId)
      // console.log(res)
      commit('changeShiftsettingstaffList', res.data.list)
    },
    async updateScheduleStatusAction({ commit }, payload) {
      const res = await removeShift(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshSddScheduleList')
      }
    },
    async shopScheduleBindAction({ commit }, payload) {
      const res = await shopScheduleBind(...payload)
      tips(res)
    },
    async removeShopScheduleBindAction({ commit }, payload) {
      const res = await removeShopScheduleBind(...payload)
      tips(res)
    },
    async addScheduleWeekAction({ commit }, payload) {
      let obj = { ...payload }
      let weekParams = obj.weekParams
      let weekDayList = obj.weekDayList
      let companyId = obj.companyId

      const res = await addScheduleWeek({ ...weekParams, companyId })
      if (res.code == 0) {
        let perId = res.data
        Promise.all(
          weekDayList.map(async (item, index) => {
            return await addScheduleWeekSaveShift(index, perId, item.id)
          })
        ).then((resList) => {
          let flag = resList.every((item) => item.code == 0)
          if (flag) {
            tips({ code: 0 })
            emitter.emit('refreshScheduleWeekListPage')
          } else {
            tips({ code: 1 })
          }
        })
      }
    },
    async getScheduleWeekList({ commit }, payload) {
      if (Array.isArray(payload) == false) return
      const res = await getScheduleWeekList(payload[0], payload[1])
      let list = res.data.list
      // console.log(list);
      Promise.all(
        list.map(async (item) => {
          return await getScheduleWeekShiftList(item.id)
        })
      ).then((resList) => {
        resList.forEach((ele, index) => {
          list[index].shiftList = ele.data.list
        })
        commit('changeScheduleWeekList', res.data.list)
      })
    },
    async setScheduleWeekShopBindAction({ commit }, payload) {
      const res = await setScheduleWeekShopBind(...payload)
      tips(res)
    },
    async getScheduleBindListAction({ commit }, payload) {
      const res = await getScheduleBindList(payload)
      let list = res.data.list
      let shopIdList = []
      list.forEach((item) => {
        shopIdList.push(item.shopId)
      })
      commit('changeScheduleBindShopIdList', shopIdList)
    },
    async addStaffRankAction({ commit }, payload) {
      const res = await addStaffRank(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshPositionRankPage')
        emitter.emit('refreshAddRankDialogPage')
      }
    },
    async getStaffAllRankListAction({ commit }, payload) {
      const res = await getStaffAllRankList(payload)
      commit('changeStaffAllRankList', res.data.list)
    },
    async addPositionRankAction({ commit }, payload) {
      const res = await addZhiwei(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshPositionRankPage')
      }
    },
    async saveRolePermissionAction({ commit }, payload) {
      const res = await saveRolePermission(...payload)
      tips(res)
    },
    async addDepartmentAction({ commit }, payload) {
      const res = await addDepartment(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshStaffDepartmentList')
      }
    },
    async getDepartmentListAction({ commit }, payload) {
      const res = await getDepList(payload)
      const shopRes = await getDepList(payload, 1)
      let mainList = res.data.list
      let shopList = shopRes.data.list
      let list = [
        {
          dpId: '',
          dpName: '总部',
          children: []
        },
        {
          dpId: '',
          dpName: '门店',
          children: []
        }
      ]
      mainList.forEach((item) => {
        item['category'] = 'main'
      })
      list[0].children = mainList
      list[1].children = shopList
      list = list.filter((ele) => ele.children.length);

      if (list.length > 0) {
        emitter.emit('changeStaffDepartmentFirstDpId', '')
      }

      commit('changeDepartmentInfoList', list)
    },
    async saveStaffDeparentBindAcrion({ commit }, payload) {
      const obj = { ...payload }
      let userList = obj.userList
      let dpId = obj.dpId

      Promise.all(
        userList.map(async (item, index) => {
          return await saveStaffDeparentBind(dpId, item.userId)
        })
      ).then((pres) => {
        let flag = pres.every((item) => item.code == 0)
        if (flag) {
          tips({ code: 0 })
          emitter.emit('changeStaffManagementTopcontentSearch')
        } else {
          tips({ code: 1 })
        }
      })
    },
    async getStaffDeparentBindListAction({ commit }, payload) {
      const res = await getStaffDeparentBindList(...payload)
      commit('changeStaffDeparentBindList', res.data.list)
    },
    async getscheduleStaffListAction({ commit }, payload) {
      const obj = { ...payload }
      delete obj.pageName
      const res = await getPaibanShopList(obj.shopid)
      let shopList = res.data.list
      let list = shopList.filter((item) => item.status == 1)
      // console.log(list)
      const totalDay = dayjs(new Date()).daysInMonth()
      const month = obj.date
      let currentMonth = {
        start: month + '-' + '01',
        end: month + '-' + totalDay
      }
      Promise.all(
        list.map(async (item) => {
          let params = {
            companyId: obj.companyId,
            userId: item.userId,
            startDate: currentMonth.start,
            endDate: '',
            shopId:obj.shopid
          }
          return await getStaffSchedDetailList(params)
        })
      ).then((resList) => {
        resList.forEach((ele, indey) => {
          let scheduleList = ele.data.list
          list[indey].scheduleArr = scheduleList
          scheduleList.forEach((val, indez) => {
            // // console.log(val);
            val.startTime = val.startTime.slice(0, 5)
            val.endTime = val.endTime.slice(0, 5)
            let str = val.shiftDate
            let date = str.split('-')
            let day = date[2].split('')
            if (day[0] == '0') {
              day = day[1]
            } else {
              day = date[2]
            }

            if (list[indey][day]) {
              list[indey][day].push(val)
            } else {
              list[indey][day] = [val]
            }
          })
        })
        commit('changeScheduleStaffList', list)
        commit('changeScheduleStateListCount', list.length)
      })
    },
    async setStaffScheduleAction({ commit }, payload) {
      const res = await setStaffSchedule(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshStaffSchedulePageContent')
      }
    },
    async getStaffScheduleWeekBindListAction({ commit }, payload) {
      const res = await getStaffScheduleWeekBindList(payload)
      commit('changeStaffScheduleWeekBindList', res.data.list)
    },
    async removeStaffScheduleWeekBindAction({ commit }, payload) {
      const res = await removeStaffScheduleWeekBind(payload)
      tips(res)
    },
    async updateScheduleInfoAction({ commit }, payload) {
      let obj = { ...payload }
      const res = await updateScheduleState(obj)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshSddScheduleList')
      }
    },
    async updateScheduleWeekInfo({ commit }, payload) {
      let res = await updateScheduleWeekInfo(payload)
      tips(res)
    },
    async updateScheduleWeekInfoAction({ commit }, payload) {
      const res = await updateScheduleWeekInfo(payload)
      tips(res)
    },
    async updateWeekStatusAction({ commit }, payload) {
      const res = await removePeriod(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshScheduleWeekListPage')
      }
    },
    async updateStaffScheduleWeekInfoAction({ commit }, payload) {
      const res = await updateScheduleWeekInfo(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshScheduleWeekListPage')
      }
    },
    async removeStaffDeparentBindAction({ commit }, payload) {
      const res = await saveStaffDeparentBind(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshDeparentStaffPageContent')
      }
    },
    async batchRemoveDepartmentStaffAction({ commit }, payload) {
      const res = await batchRemoveDepartmentStaff(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshDeparentStaffPageContent')
      }
    },
    async getStaffPositionRankListAction({ commit }, payload) {
      const res = await getStaffPositionList(payload)
      let list = res.data.list
      Promise.all(
        list.map(async (item) => {
          return await getStaffChildRankList(item.lvId)
        })
      ).then((resList) => {
        resList.forEach((ele, index) => {
          list[index].children = ele.data.list
          // 获取第一个职级
          if (index == 0 && ele.data.list.length > 0) {
            emitter.emit('changeStaffFirstRank', ele.data.list[0].lvId)
          }
          list[index].children.forEach((val, indez) => {
            list[index].children[indez].pid = list[index].lvId
          })
        })
        commit('changeStaffPositionRankList', list)
      })
    },
    async getStaffSchedDetailList({ commit }, payload) {},
    async getStaffRankBindListAction({ commit }, payload) {
      const res = await getStaffRankBindList(...payload)
      commit('changeStaffRankBindList', res.data.list)
    },
    async saveStaffRankBindAcrion({ commit }, payload) {
      const obj = { ...payload }
      let userList = obj.userList
      let currentRnid = obj.currentRnid
      let jobLevel = obj.jobLevel
      Promise.all(
        userList.map(async (item, index) => {
          return await setStaffRankBind(item, jobLevel, currentRnid)
        })
      ).then((pres) => {
        let flag = pres.every((item) => item.code == 0)
        if (flag) {
          tips({ code: 0 })
          emitter.emit('refreshRankStaffPageContent')
          emitter.emit('refreshCurrentRankStaffPageContent')
        } else {
          tips({ code: 1 })
        }
      })
    },
    async setStaffWeekScheduleAction({ commit }, payload) {
      let obj = { ...payload }
      const startDate = obj.date
      let totalSetDay = obj.weekInfo.perDays
      let dateStr = []

      for (let i = 0; i < totalSetDay; i++) {
        let dateObj = dayjs(startDate).add(Number(i), 'day')
        let date = dayjs(dateObj).format('YYYY-MM-DD')
        dateStr.push(String(date))
      }

      let params = {
        companyId: obj.companyId,
        perId: obj.weekInfo.id,
        userId: obj.userId,
        dateStr: dateStr.join(),
        shopId: obj.shopId,
        perName:obj.perName
      }

      const res = await setStaffWeekSchedule(params)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshStaffSchedulePageContent')
      }
    },
    async setStaffStateAction({ commit }, payload) {
      const res = await setStaffState(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('setStaffStateRefreshStaffListPage')
      }
    },
    async getBelongShopAction({ commit }, payload) {
      const res = await getBelongShop(payload)
      commit('changeBelongShopList', res.data.list)
    },
    async batchSetStafffStateAction({ commit }, payload) {
      const res = await batchSetState(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('changeStaffManagementTopcontentSearch')
      }
    },
    async updateStaffScheduleInfoAction({ commit }, payload) {
      const res = await updateStaffScheduleInfo(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshStaffSchedulePageContent')
      }
    },
    async piliangupDataStaffScheduleInfoAction({ commit }, payload) {
      let scheduleList = payload.scheduleList
      let userId = payload.userId
      Promise.all(
        scheduleList.map(async (item) => {
          let params = {
            shiftId: item.shiftId,
            shiftDate: item.shiftDate,
            userId: userId,
            companyId: COMPANY_ID,
            status: item.status,
            startTime: item.startTime,
            endTime: item.endTime + ':00'
          }
          return await updateStaffScheduleInfo(params)
        })
      ).then((resList) => {
        tips(resList[0])
        emitter.emit('refreshStaffSchedulePageContent')
      })
    },
    async getStaffScoreListAction({ commit }, payload) {
      let obj = { ...payload }
      let date = dayjs(obj.date).format('YYYY-MM-DD')
      let type = obj.type
      //type 0 业绩  1提成

      const res = await getStaffList({
        companyId: 10000,
        dpid: '',
        joblv: '',
        keyword: '',
        page: obj.page,
        pageszie: obj.pagesize,
        shopid: obj.shopId == 0 ? '' : obj.shopId,
        status: 1,
        subjoblv: ''
      })

      let list = res.data.allEmp
      let total = res.data.total
      commit('changeStaffyejiTotal', total)
      Promise.all(
        list.map(async (item) => {
          if (type == 0) {
            return await getStaffdanciyeji({
              empId: item.userId,
              starttime: obj.starttime,
              endtime: obj.endtime,
              shopId: obj.shopId
            })
          } else if (type == 1) {
            return await getStaffdanciticheng({
              empId: item.userId,
              starttime: obj.starttime,
              endtime: obj.endtime,
              payway: ''
            })
          }
        })
      ).then(async (resList) => {
        let staffList = []
        resList.forEach((item, index) => {
          let source = item.data
          let newSource = deepClone(source)

          if (type == 0) {
            let {
              xianjin,
              yueB,
              yueZ,
              czkZ,
              czkB,
              cikB,
              cikZ,
              coupon,
              goods,

              yueBSale,
              yueZSale,
              czkSale,
              cikSale,

              cashtoYue,
              cashtoCzk,
              cashtoCik,
              cashtoServe,
              cashtoGood
            } = source
            source['xhTotal'] =
              xianjin + yueB + yueZ + czkZ + czkB + cikB + cikZ + coupon + goods
            source['xsTotal'] = yueBSale + yueZSale + czkSale + cikSale
            source['xjlsTotal'] =
              cashtoYue + cashtoCzk + cashtoCik + cashtoServe + cashtoGood
          }

          for (let key in source) {
            source[key] = (Number(source[key]) / 100).toFixed(2)
          }
          // console.log(source);
          let newObj = Object.assign(list[index], source)
          staffList.push(newObj)
        })

        // 计算总业绩
        let shopId = obj.shopId
        if (
          shopId == undefined ||
          shopId == null ||
          String(shopId).length == 0
        ) {
          shopId = 0
        }

        const shopRes = await getShopList(shopId)
        let shopStaffList = shopRes.data.list
        let xjxh = 0
        let yebj = 0
        let yezj = 0
        let czkbj = 0
        let czkzj = 0
        let ckbj = 0
        let ckzj = 0
        let dhq = 0
        let cp = 0

        let ye = 0
        let czk = 0
        let ck = 0

        let xjlsye = 0
        let xjlsczk = 0
        let xjlsck = 0
        let xjlsxjxh = 0
        let xjlscp = 0

        Promise.all(
          shopStaffList.map(async (item, index) => {
            if (type == 0) {
              return await getStaffdanciyeji({
                empId: item.userId,
                starttime: obj.starttime,
                endtime: obj.endtime,
                shopId: obj.shopId
              })
            } else if (type == 1) {
              return await getStaffdanciticheng({
                empId: item.userId,
                starttime: obj.starttime,
                endtime: obj.endtime,
                payway: ''
              })
            }

          })
        ).then((resList) => {
          resList.forEach((ele, indey) => {
            let source = ele.data
            if (type == 0) {
              xjxh += Number(source.xianjin)
              yebj += Number(source.yueB)
              yezj += Number(source.yueZ)
              czkbj += Number(source.czkB)
              czkzj += Number(source.czkZ)
              ckbj += Number(source.cikB)
              ckzj += Number(source.cikZ)
              dhq += Number(source.coupon)
              cp += Number(source.goods)

              ye += Number(source.yueBSale + source.yueZSale)
              czk += Number(source.czkSale)
              ck += Number(source.cikSale)

              xjlsye += Number(source.cashtoYue)
              xjlsczk += Number(source.cashtoCzk)
              xjlsck += Number(source.cashtoCik)
              xjlsxjxh += Number(source.cashtoServe)
              xjlscp += Number(source.cashtoGood)
            } else if (type == 1) {
              xjxh += Number(source.cashTicheng)
              yebj += Number(source.yueBTicheng)
              yezj += Number(source.yueZTicheng)
              czkbj += Number(source.czkBTicheng)
              czkzj += Number(source.czkZTicheng)
              ckbj += Number(source.cikBTicheng)
              ckzj += Number(source.cikZTiheng)
              dhq += Number(source.couponTicheng)
              cp += Number(source.goodTicheng)
              ye += Number(source.goodYueBTicheng + source.goodYueZTicheng)
              czk += Number(source.goodCzkBTicheng + source.goodCzkZticheng)
              ck += Number(source.youxianBTicheng + source.youxianZTicheng)
            }
          })

          let totlaInfo = {
            xiaohaoTotal:
              xjxh + yebj + yezj + czkbj + czkzj + ckbj + ckzj + dhq + cp,
            xiaoshouTotal: ye + czk + ck,
            xjxh,
            yebj,
            yezj,
            czkbj,
            czkzj,
            ckbj,
            ckzj,
            dhq,
            cp,
            ye,
            czk,
            ck
          }

          if (type == 1) {
            totlaInfo['total'] =
              totlaInfo.xiaohaoTotal + totlaInfo.xiaoshouTotal
          } else if (type == 0) {
            totlaInfo['xjlsye'] = xjlsye
            totlaInfo['xjlsczk'] = xjlsczk
            totlaInfo['xjlsck'] = xjlsck
            totlaInfo['xjlsxjxh'] = xjlsxjxh
            totlaInfo['xjlscp'] = xjlscp
            totlaInfo['xianjinTotal'] =
              xjlsye + xjlsczk + xjlsck + xjlsxjxh + xjlscp
            totlaInfo['total'] =
              totlaInfo['xiaohaoTotal'] +
              totlaInfo['xiaoshouTotal'] +
              totlaInfo['xianjinTotal']
          }

          for (let key in totlaInfo) {
            totlaInfo[key] = (Number(totlaInfo[key]) / 100).toFixed(2)
          }

          if (type == 0) {
            commit('changeStaffyejiList', staffList)
            commit('changeStaffyejiTotalInfo', totlaInfo)
          } else if (type == 1) {
            commit('changeStafftichehngList', staffList)
            commit('changeStafftichengTotalInfo', totlaInfo)
          }
        })
      })
    },
    async getStaffzongheyejiAction({ commit }, payload) {
      let obj = { ...payload }
      let type = obj.type

      let month = dayjs(obj.starttime).format('MM')
      const res = await getStaffList({
        companyId: 10000,
        dpid: '',
        joblv: '',
        keyword: '',
        page: obj.page,
        pageszie: obj.pagesize,
        shopid: obj.shopId == 0 ? '' : obj.shopId,
        status: 1,
        subjoblv: ''
      })
      let list = res.data.allEmp
      let total = res.data.total
      if (type == 0) {
        commit('changezongheyejiTotal', total)
      } else {
        commit('changezonghetichengTotal', total)
      }

      Promise.all(
        list.map(async (item, index) => {
          if (type == 0) {
            return await getStaffzongheyeji({
              empId: item.userId,
              starttime: obj.starttime,
              endtime: obj.endtime,
              shopId: obj.shopId
            })
          } else if (type == 1) {
            return await getStaffzongheticheng({
              empId: item.userId,
              starttime: obj.starttime,
              endtime: obj.endtime,
              shopId: obj.shopId
            })
          }
        })
      ).then(async (resList) => {
        let staffList = []
        resList.forEach((ele, indey) => {
          let source = ele.data
          let newSource = deepClone(source)
          for (let key in source) {
            if (key != 'lastUpdate') {
              source[key] = (Number(source[key]) / 100).toFixed(2)
            } else {
              if (source[key] == '/') {
                source[key] = '-'
              }
            }
          }
          let newObj = Object.assign(list[indey], source)
          newObj['month'] = month
          staffList.push(newObj)
        })

        // 计算总业绩
        let shopId = obj.shopId
        if (
          shopId == undefined ||
          shopId == null ||
          String(shopId).length == 0
        ) {
          shopId = 0
        }

        const shopRes = await getShopList(shopId)
        let shopStaffList = shopRes.data.list
        let xiaohao = 0
        let xiaoshou = 0
        let total = 0
        let lastUpdate = '-'
        Promise.all(
          shopStaffList.map(async (item, index) => {
            if (type == 0) {
              return await getStaffzongheyeji({
                empId: item.userId,
                starttime: obj.starttime,
                endtime: obj.endtime,
                shopId: obj.shopId
              })
            } else if (type == 1) {
              return await getStaffzongheticheng({
                empId: item.userId,
                starttime: obj.starttime,
                endtime: obj.endtime,
                shopId: obj.shopId
              })
            }
          })
        ).then((resList) => {
          resList.forEach((ele, indey) => {
            let source = ele.data
            if (type == 0) {
              xiaohao += Number(source.laodongYeji)
              xiaoshou += Number(source.saleYeji)
              total += Number(source.laodongYeji + source.saleYeji)
            } else if (type == 1) {
              xiaohao += Number(source.laodongTicheng)
              xiaoshou += Number(source.saleTicheng)
              total += Number(source.laodongTicheng + source.saleTicheng)
            }
            if (source.lastUpdate != '/') {
              lastUpdate = source.lastUpdate
            }
          })
        })

        let totalInfo = {
          xiaohao: (xiaohao / 100).toFixed(2),
          xiaoshou: (xiaoshou / 100).toFixed(2),
          total: (total / 100).toFixed(2),
          lastUpdate,
          month
        }

        if (type == 0) {
          commit('changezongheyejiTotalInfo', totalInfo)
          commit('changezongheyejiList', staffList)
        } else if (type == 1) {
          commit('changezonghetichengTotalInfo', totalInfo)
          commit('changezonghetichengList', staffList)
        }
      })
    }
  }
}

export default shopModule
